import React, { useState } from 'react';

import { isEmpty } from 'lodash';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Input, Slider } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import { RootState, getWalletSliderMaxValuePosition } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import { GetCapitalWalletLog } from '@utils';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  display: boolean;

  selectedClientCash: number | null | undefined;

  titleLabel?: string;

  onSelectClientCash: (selectedClientCash: number) => void;
}

function WalletSearchSliderComponent(props: IProps) {
  const [collapse, setCollapse] = useState(false);

  const { display, selectedClientCash, titleLabel, loginStore, onSelectClientCash } = props;
  const { account, userWalletData, lifeStyle } = loginStore;

  if (!display) {
    return null;
  }

  const walletNoDecimals = account?.walletNoDecimals;
  const background: any = account?.buttonColor ? account?.buttonColor : '#0081FE';

  const maxWalletAmount = userWalletData?.balance;
  const maxSlider = maxWalletAmount ? 
                      walletNoDecimals ? 
                        Math.floor(maxWalletAmount) : 
                        maxWalletAmount ? Math.floor(maxWalletAmount * 100) / 100 : 0 : 
                      0;

  const marks: SliderMarks = {
    0: {
      style: {
        transform: 'translate(-4px, -40px)'
      },
      label: '0',
    }
  };

  if (maxSlider) {
    marks[maxSlider] = {
      style: {
        transform: `translate(${walletNoDecimals ? getWalletSliderMaxValuePosition(maxSlider) :'-27'}px, -40px)`
      },
      label: `${maxSlider?.toLocaleString('en-US')}`,
    };
  }

  const handleCollapse = () => {
    setCollapse(!collapse);
  }

  const logo = GetCapitalWalletLog(lifeStyle);
  const isCapital = account?.isCapitalVacations;

  return (
    <div className="wallet-search-slider container-fluid" style={{ position: 'relative' }}>
      <div className="wallet-search-slider__collapse" onClick={handleCollapse}>
        <FontAwesomeIcon icon={collapse ? faAngleDown : faAngleUp} />
      </div>
      
      <div className="row">
        <div className={`wallet-search-slider__note col-md-12 ${isCapital ? 'capital-header-slider' : ''}`} style={{ justifyContent: 'space-between', marginBottom: '0px' }}>
          <p className={`wallet-search-slider__price-info-text-total ${collapse ? 'collapsed' : ''} ${isCapital ? 'capital' : ''}`}>
            <FormattedMessage id={titleLabel ? titleLabel : 'wallet.client.cash.want_use'} values={{ clientCashName: account?.walletClientCashName }} />
          </p>
          {isCapital ? (<img className="capital-logo" width="165" height="35" src={logo} />) : null}
        </div>

        {!collapse ? (
          <>
            <div className={`wallet-search-slider__note col-md-6 message ${isCapital ? 'capital' : ''}`} style={{ marginBottom: '10px', whiteSpace: 'unset' }}>
              {maxWalletAmount ? (
                <>
                  <FormattedMessage id="wallet.client.cash.search_slider.message" values={{ amount: userWalletData?.maxClientCashPercentageAllow * 100, amountActive: userWalletData?.balance?.toLocaleString('en-US') }} /><br />
                </>) : (
                <FormattedMessage id="wallet.client.cash.no_client_cash.message" values={{ amountActive: userWalletData?.balance?.toLocaleString('en-US'), clientCash: account?.walletClientCashName }} />)}
            </div>

            <div className={`wallet-search-slider__note col-md-6 ${isCapital ? 'capital' : ''}`}>
              <Slider
                min={0}
                max={maxSlider}
                marks={marks}
                defaultValue={0}
                step={walletNoDecimals ? 1 : 0.01}
                tooltip={{ open: false }}
                value={selectedClientCash >= 0 ? selectedClientCash : 0}
                onChange={(value: any) => onSelectClientCash(value)}
                trackStyle={{ backgroundColor: background }}
                handleStyle={{ borderColor: background, backgroundColor: background }}
                disabled={maxWalletAmount <= 0}
                style={{ marginTop: '30px' }}
              />
              <Input value={(selectedClientCash && selectedClientCash >= 0) ? selectedClientCash.toLocaleString('en-US') : '' }
                className={`room-info__client-cash-input ${selectedClientCash > maxWalletAmount ? 'invalid' : ''}`}
                onChange={e => {
                  const value = !isEmpty(e.target.value) ? Number(e.target.value.replaceAll(',', '').replaceAll('.', '')) : null;

                  onSelectClientCash(value);
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={maxWalletAmount <= 0}
              />
            </div>

            {selectedClientCash > maxWalletAmount && (
              <div className="wallet-search-slider__note invalid" style={{ marginBottom: '10px' }}>
                <FormattedMessage id="wallet.client.cash.invalid" values={{ amount: maxSlider?.toLocaleString('en-US'), clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName }} />
              </div>)}

            {(!!selectedClientCash && walletNoDecimals && selectedClientCash <= maxWalletAmount && Math.floor(selectedClientCash) != selectedClientCash) && (
              <div className="wallet-input__note invalid" style={{ marginBottom: '10px' }}>
                <FormattedMessage id="wallet.client.cash.only_decimals" values={{ clientCash: isEmpty(account?.walletClientCashName) ? 'Client Cash' : account?.walletClientCashName }} />
              </div>)}

          </>) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const WalletSearchSlider = connect(mapStateToProps)(injectIntl(WalletSearchSliderComponent));

