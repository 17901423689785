import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import ReactGA from 'react-ga4';
import moment from 'moment';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { get } from 'lodash';

import { getWeekDetails, weekDetailsActions } from '@store/slices';
import { getTrustYouReview, ResetWeeksFull } from '@share/store/slices';
import {
  unitsSearchActions,
  IWeekDetailsState
} from '@store/slices';
import { ILoginState} from '@share/store/slices';
import { GetHomeParams, RootState } from '@share/utils';
import { Routes, C_D_VIEW_MAP_TEXT, DEALID_LABEL, DEAL_HOME_LABEL, WEEKS_DATES_LABEL, WEEKS_SESSION_KEY_LABEL, WEEKS_UNITS_SESSION_KEY_LABEL } from '@share/constants';
import { ISessionKey, BookingErrorsEnum, IAccount, IMedia } from '@share/common-types';
import { Map } from '@utils';
import { UrlUtils, Responsive } from '@share/utils';
import { TrustYouReview, MobileSlider, ReviewsBase } from '@components';
import { CloseBlueSvg } from '@assets';
import { CustomErrorPage } from '@share/components';
import { GuestReviews } from '@common-types';
import { NULL_VALUE } from '@constants';

import { SkeletonComponent } from '../../../hotel-details/skeleton';
import { WeekInfo } from '../week-info';
import { WeeksUnitsSearch } from '../weeks-units';
import { MapWrapper } from '../../../map';
import { CondoImagesDescription } from '../../../condo/condo-details/condo-images-description';
import { CondoAmenities } from '../../../condo/condo-details/condo-amenities';
import { CondoActivities } from '../../../condo/condo-details/condo-activities';
import { CondoLocation } from '../../../condo/condo-details/condo-location';
import { CondoPolicies } from '../../../condo/condo-details/condo-policies';
import { CondoDetailsServerError } from '../../../condo/condo-details/condo-details-server-error';
import { WeeksAvailabilityStatusModal } from '../weeks-availability-status-modal';

import MapMarker from '@assets/images/map-bed-marker.svg';

import './style.scss';

interface IMapStateToProps {
  weeksDetailsStore: IWeekDetailsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getWeekDetails: (
    numberId: number,
    sessionKey: ISessionKey,
    isMobile?: boolean,
    condoRequest?: any,
    dealId?: number,
    quote?: string
  ) => void;
  getTrustYouReview: (trustYouId: string) => void;
  resetWeekDetails: () => void;
  resetCondoUnits: () => void;
  resetWeeksFull: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  matches?: boolean;
}

interface IAnchor {
  id: string;
  ref: React.RefObject<HTMLDivElement>;
}

const ZERO = 0;
const FOUR = 4;
const DEFAULT_ANCHOR = 0;
const TAB_POLICIES = 'tab.policies';
const TAB_AMENITIES = 'tab.amenities.facilities';
const TAB_ACTIVITIES = 'tab.activities';
const TAB_REVIEWS = 'tab.reviews';
const BIG_MAP_ZOOM = 15;
const DEBOUNCE_TIME = 100;

function WeeksDetailsWrapperComponent(props: IProps) {

  const [active, setActive] = useState(DEFAULT_ANCHOR);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isTrustYouLoading, setIsTrustYouLoading] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);
  //const [quote, setQuote] = useState(null);
  const [dealId, setDealId] = useState<number>(NULL_VALUE);
  const [backHomeType, setBackHomeType] = useState<string>(NULL_VALUE);

  const { weeksDetailsStore, loginStore } = props;
  const { week, loading, isServerError, error } = weeksDetailsStore;
  const { account, user } = loginStore;

  const overviewRef = useRef();
  const unitsRef = useRef();
  const amenitiesRef = useRef();
  const activitiesRef = useRef();
  const locationRef = useRef();
  const policiesRef = useRef();
  const reviewsRef = useRef();

  const activeRef = useRef(active);
  const disableScrollRef = useRef(disableScroll);
  const weekRef = useRef(week);
  const loadingRef = useRef(loading);
  const userRef = useRef(user);

  const ITEMS_ANCHOR: IAnchor[] = [
    { id: 'tab.overview', ref: overviewRef as RefObject<any> },
    { id: 'tab.units.availability', ref: unitsRef as RefObject<any> },
    { id: 'tab.amenities.facilities', ref: amenitiesRef as RefObject<any> },
    { id: 'tab.activities', ref: activitiesRef as RefObject<any> },
    { id: 'location', ref: locationRef as RefObject<any> },
    { id: 'tab.policies', ref: policiesRef as RefObject<any> },
    { id: 'tab.reviews', ref: reviewsRef as RefObject<any> },
  ];

  const setActiveFull = (active: number) => {
    setActive(active);
    activeRef.current = active;
  }

  const setDisableScrollFull = (disableScroll: boolean) => {
    setDisableScroll(disableScroll);
    disableScrollRef.current = disableScroll;
  }

  const scrollView = (e: React.MouseEvent<HTMLParagraphElement>, ref: React.RefObject<HTMLElement>) => {
    const value =e.currentTarget.dataset.index ? e.currentTarget.dataset.index : 0;
    setActiveFull(+value);
    setDisableScrollFull(true);
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const getAnchors = useCallback((): IAnchor[] => {
    const weekObj = weekRef.current;
    const propertyDetails = weekObj?.propertyDetails;

    const allDataPolicies = [
      ...(propertyDetails?.checkInInformation || []),
      ...(propertyDetails?.checkOutInformation || []),
      ...(propertyDetails?.pets || []),
    ];

    return ITEMS_ANCHOR.filter((item) => {
      if (
        (item.id === TAB_POLICIES && isEmpty(allDataPolicies)) ||
        (item.id === TAB_AMENITIES && isEmpty(propertyDetails?.amenities) && isEmpty(propertyDetails?.facilities)) ||
        (item.id === TAB_ACTIVITIES && isEmpty(propertyDetails?.activities)) ||
        (item.id === TAB_REVIEWS && isEmpty(propertyDetails?.rating?.trustYouId) && !propertyDetails?.guestRatings)
      ) {
        return;
      }
      return item;
    });
  }, [weekRef]);

  const filtersAnchor = () => {
    return getAnchors().map((itemAnchor, i) => (
      <p
        key={i}
        className={`condo-details-wrapper__tab-anchors ${i === active ? 'active' : ''}`}
        onClick={(e) => scrollView(e, itemAnchor.ref)}
        data-index={i}
      >
        <FormattedMessage id={itemAnchor.id} />
      </p>
    ));
  };

  const onClickScrollToUnits = (e: React.MouseEvent<HTMLDivElement>) => {
    scrollView(e, unitsRef as RefObject<any>);
  };

  const onClickScrollToReviews = (e: React.MouseEvent<HTMLDivElement>) => {
    scrollView(e, reviewsRef as RefObject<any>);
  };

  const onScroll = debounce(() => {
    if (weekRef.current && !loadingRef.current && !disableScrollRef.current) {
      const anchor = getActiveAnchor();

      if (anchor && anchor.id) {
        const index = getAnchors().findIndex(({ id }) => id === anchor.id);

        if (index >= ZERO && activeRef.current !== index) {
          setActiveFull(index);
        }
      }
    }

    if (disableScrollRef.current) {
      setDisableScrollFull(false);
    }
  }, DEBOUNCE_TIME);

  const handleWeekDetails = () => {
    const { matches, getWeekDetails } = props;
    const propertyId = Number(props.history.location.pathname.split('/')[FOUR]);

    const values = UrlUtils.getValues();
    const sessionKey = values[WEEKS_UNITS_SESSION_KEY_LABEL]
      ? values[WEEKS_UNITS_SESSION_KEY_LABEL]
      : values[WEEKS_SESSION_KEY_LABEL];

    let dealId;
    if (values[DEALID_LABEL]) {
      dealId = parseInt(values[DEALID_LABEL] as string);
      setDealId(dealId as number);
    }

    if (values[DEAL_HOME_LABEL]) {
      setBackHomeType(values[DEAL_HOME_LABEL] as string);
    }

    /*
    let quote;
    if (values[QUOTE_LABEL]) {
      quote = (values[QUOTE_LABEL] as string);
      setQuote(quote as);
    }
    */

    getWeekDetails(propertyId, sessionKey as ISessionKey, !matches, getWeekRequestDetail(), Number(dealId), NULL_VALUE);
  }

  useEffect(() => {
    const footer: HTMLDivElement = document.querySelector('.footer') as HTMLDivElement;

    if (!!props.loginStore.user) {
      handleWeekDetails();
    }

    document.body.style.overflow = 'auto';

    if (footer) {
      footer.style.display = 'block';
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      props.resetWeekDetails();
      props.resetCondoUnits();
      }
  },[]);

  useEffect(() => {
    loadingRef.current = loading;
  },[loading]);

  useEffect(() => {
    weekRef.current = week;
  },[week]);

  useEffect(() => {
    if (!userRef.current && !!user) {
      handleWeekDetails();
    }

    userRef.current = user;
  },[user]);

  useEffect(() => {
    if (week && !loading && week.propertyDetails.rating.trustYouId && !isTrustYouLoading) {
      props.getTrustYouReview(week?.propertyDetails.rating.trustYouId);
      setIsTrustYouLoading(!isTrustYouLoading);
    }
  },[week, loading, week?.propertyDetails?.rating?.trustYouId, isTrustYouLoading]);

  const getWeekRequestDetail = () => {
    const values = UrlUtils.getValues();
    
    const weeksDatesValues = values[WEEKS_DATES_LABEL];
    const checkIn = get(weeksDatesValues, 'date');
    const dateRange = get(weeksDatesValues, 'period');
    const residency = 'US';
    
    return {
      residency,
      checkIn: !isEmpty(checkIn) ? moment(checkIn, 'MM/DD/yyyy').format('yyyy-MM-DD') : checkIn,
      dateRange,
    }
  }

  const getActiveAnchor = (): IAnchor => {
    const anchors: IAnchor[] = getAnchors();
    const { pageYOffset } = window;
    const additionalHeight = 300;
    let activeRef: IAnchor = anchors[ZERO];

    anchors.forEach((anchor: IAnchor) => {
      const { ref } = anchor;

      if (ref && ref.current) {
        const { current } = ref;
        const height = current.getBoundingClientRect()?.height;
        const offsetTop = current.offsetTop - additionalHeight;
        const offsetBottom = offsetTop + height;

        if (height && offsetTop && pageYOffset >= offsetTop && pageYOffset <= offsetBottom) {
          activeRef = anchor;
        }
      }
    });

    return activeRef;
  };

  const showMap = () => {
    setIsMapModalOpen(true);
    document.body.style.overflow = 'hidden';

    const { loginStore } = props;
    const { account } = loginStore;

    ReactGA.event({
      category: account?.name as string,
      action: `${C_D_VIEW_MAP_TEXT}_${account?.name.toUpperCase()}`,
      label: `User clicked view map on Condo details`,
      nonInteraction: false,
    });
  };

  const closeMap = () => {
    setIsMapModalOpen(false);
    document.body.style.overflow = 'initial';
  };

  return (
    <div className="condo-details-wrapper">
      {isServerError ? (
        <CondoDetailsServerError />) : (
        <>
          <div className="condo-details-wrapper__back-link desktop">
            {!dealId ? (
              <Link to={`/${account?.name}${Routes.WeeksSearch}${location.search}`}><FormattedMessage id="back.to.list" /></Link>) :
              !isEmpty(backHomeType) ? (
                <Link to={NULL_VALUE} onClick={() => {
                  const homeParams = GetHomeParams(account as IAccount);
                  props.resetWeeksFull();
                  props.history.push(`/${account?.name}${Routes.WeeksSearch}${homeParams}`);
                }}>
                  <FormattedMessage id="confirmation.go.back_home" />
                </Link>) : null}
          </div>

          {(loading || BookingErrorsEnum.RoomsUnavailable === error) ? <SkeletonComponent /> : null}

          {week && !loading && isEmpty(error) ? (
            <div className="condo-details-wrapper__wrapper">
              <div ref={overviewRef as RefObject<any>} />
              <div className="condo-details-wrapper__back-link mobile">
                {week.propertyDetails.isAllInclusive ? (
                  <div className="condo-details-wrapper__label-all-inclusive">
                    <p className="condo-details-wrapper__label-title">
                      <FormattedMessage id="all.inclusive" />
                    </p>
                  </div>
                ) : null}
                {!dealId ? (
                  <Link to={`/${account?.name}${Routes.WeeksSearch}${location.search}`}><FormattedMessage id="back.to.list" /></Link>) :
                  !isEmpty(backHomeType) ? (
                    <Link to={NULL_VALUE} onClick={() => {
                      const homeParams = GetHomeParams(account as IAccount);
                      props.resetWeeksFull();
                      props.history.push(`/${account?.name}${Routes.WeeksSearch}${homeParams}`);
                    }}>
                      <FormattedMessage id="confirmation.go.back_home" />
                    </Link>) : null}
              </div>
              <div className={`condo-details-wrapper__desktop-hotel-info ${week.propertyDetails.isAllInclusive ? 'all-inclusive-wrapper' : ''}`}>
                {week.propertyDetails.isAllInclusive ? (
                  <div className="condo-details-wrapper__label-all-inclusive">
                    <p className="condo-details-wrapper__label-title">
                      <FormattedMessage id="all.inclusive" />
                    </p>
                  </div>) : null}

                <WeekInfo week={week} onClickScrollToReviews={onClickScrollToReviews} />
              </div>

              <MobileSlider images={week.propertyDetails.media || []} />

              <div className="condo-details-wrapper__details-anchor">
                {week && filtersAnchor()}
              </div>

              <div className="condo-details-wrapper__mobile-hotel-info">
                <WeekInfo week={week} onClickScrollToReviews={onClickScrollToReviews} />
              </div>

              <CondoImagesDescription
                account={account as IAccount}
                media={(week.propertyDetails.media || []) as IMedia[]}
                condoName={week.propertyDetails.title}
                condoDescription={(week.propertyDetails.descriptions || []) as string[]}
                onClickScrollToUnits={onClickScrollToUnits}
                highlights={week.propertyDetails.highlights}
                isAllInclusive={week.propertyDetails.isAllInclusive as boolean}
              />

              <Responsive>
                <WeeksUnitsSearch refAnchor={unitsRef as RefObject<any>} />
              </Responsive>

              {(!isEmpty(week.propertyDetails.amenities) || !isEmpty(week.propertyDetails.facilities)) && (
                <CondoAmenities condoDetails={week.propertyDetails} refAnchor={amenitiesRef as RefObject<any>} />)}

              {!isEmpty(week.propertyDetails.accessibilities) && (
                <div className="condo-details-wrapper__accessibilities-items">
                  {week.propertyDetails.accessibilities.map((item, index) => (
                    <div className="condo-details-wrapper__accessibilities-item" key={index}>
                      <span className="condo-details-wrapper__accessibilities-text">{item.name}</span>
                      <p className="condo-details-wrapper__accessibilities-category">
                        {item.category}
                      </p>
                    </div>
                  ))}
                </div>
              )}
              {!isEmpty(week.propertyDetails.activities) && (
                <CondoActivities condoDetails={week.propertyDetails} refAnchor={activitiesRef as RefObject<any>} />)}

              <CondoLocation
                condoDetails={week.propertyDetails}
                refAnchor={locationRef as RefObject<any>}
                showBigMap={showMap}
              />

              <CondoPolicies condoDetails={week.propertyDetails} refAnchor={policiesRef as RefObject<any>} />

              <TrustYouReview refAnchor={reviewsRef as RefObject<any>} />

              <ReviewsBase guestRatings={week?.propertyDetails?.guestRatings as GuestReviews} refAnchor={reviewsRef as RefObject<any>} />

              <div
                className="condo-details-wrapper__big-map-wrapper"
                style={{ display: isMapModalOpen ? 'block' : 'none' }}
              >
                <div className="condo-details-wrapper__big-map-top-section secondary-font">
                  <span className="condo-details-wrapper__big-map-close" onClick={closeMap}>
                    <CloseBlueSvg />
                  </span>
                  {week.propertyDetails.title}
                </div>
                <MapWrapper
                  locations={[{ location: week.propertyDetails.location, id: week.propertyDetails.id }]}
                  center={Map.getGoogleLocation(week.propertyDetails.location)}
                  icon={MapMarker}
                  mapOptions={{ zoom: BIG_MAP_ZOOM, disableDefaultUI: true }}
                />
              </div>
            </div>) : null}

          {(!loading && !isEmpty(error)) ? 
            ([BookingErrorsEnum.RoomsUnavailable.toString(), BookingErrorsEnum.SoldOut.toString()].includes(error) ? 
              <WeeksAvailabilityStatusModal /> : 
              <CustomErrorPage message={error} />) : 
            null}
        </>)}
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksDetailsStore: state.weeksDetailsStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getWeekDetails,
  resetWeekDetails: weekDetailsActions.resetState,
  resetWeeksFull: ResetWeeksFull,

  getTrustYouReview,
  resetCondoUnits: unitsSearchActions.resetState,
};

export const WeeksDetailsWrapper = connect(mapStateToProps, mapDispatchToProps)(withRouter(WeeksDetailsWrapperComponent));
