import React from 'react';
import ReactGA from 'react-ga4';

import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { format, getMonth } from 'date-fns';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { ClockSvg, InfoSvg } from '@assets';
import AllInclusive from '@assets/images/all-inclusive.png';
import { BlueButton } from '@share/components';
import {
  GetawaysLocationEnum,
  IGetaway,
  ILocation,
  IRoom,
  ICondoLocation,
  SupplierType,
  IAccount,
} from '@share/common-types';
import { renderPriceBasedOn, renderGetawaysCardDate } from '@utils';
import {
  formatMoneyCurrency,
  getDateCheckInOut,
  getHotelRoutePath,
  getNormalizedRoomsResponse,
  getSelectedCurrency,
  isGetawaysFromPath,
  isHomeFromPath,
  RootState,
  UrlUtils,
} from '@share/utils';
import {
  ALL_INCLUSIVE_KEY,
  DEAL_HOME_LABEL,
  DEALID_LABEL,
  FILTERS_LABEL,
  GETAWAYS_KEY,
  Routes,
  STAYC_BOOKN,
  STAYC_VIEWD,
} from '@share/constants';
import {
  DATE_FORMAT_MONTH,
  DATE_FORMAT_MONTH_END,
  DATE_FORMAT_MONTH_END_DIFFERENT,
  DATE_FORMAT_MONTH_START,
  DATE_FORMAT_MONTH_START_DIFFERENT,
  DATES_LABEL,
  LOCATION_LABEL,
  LOCATION_OBJECT_LABEL,
  ROOMS_LABEL,
  UNDEFINED_VALUE,
} from '@constants';
import {
  ILoginState,
  datesActions,
  filtersActions,
  locationActions,
  roomsActions,
} from '@share/store/slices';
import { Currency } from '@components';

import {
  condoFlexibleDatePickerActions,
  condosLocationPickerActions,
  condoStrictDatesActions,
  ICondoFlexibleDatePickerState,
} from '@share/store/slices';

import GetawayBackground from '@assets/images/getaways/getaways-list-bg.png';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setRooms: (rooms: IRoom[]) => void;
  setDatesSelected: (dates: { startDate: string; endDate: string }) => void;
  resetFilters: () => void;
  clearDatesError: (msg: string) => void;
  clearLocationError: (msg: string) => void;
  setSelectLocation: (location: ILocation) => void;
  setSelectLocationLabel: (label: string) => void;

  setCondoSelectLocation: (location: ICondoLocation) => void;
  setCondoSelectLocationLabel: (label: string) => void;
  setCondoDatesSelected: (dates: { startDate: string; endDate: string }) => void;
  setCondoFlexibleState: (state: ICondoFlexibleDatePickerState) => void;

  applyDates: () => void;
  applyRooms: () => void;
  applyLocation: () => void;
}

interface IProps extends RouteComponentProps, IMapStateToProps, IMapDispatchToProps {
  data: IGetaway;
  condoSubcategory: string;
  activeGetaway: string;

  isSlider?: boolean;
  isAllGetaway?: boolean;
}

interface IState {
  isError: boolean;
}

export class GetawayListCardComponent extends React.Component<IProps, IState> {
  state: IState = {
    isError: false,
  };

  componentDidMount(): void {
    const { isAllGetaway } = this.props;
    localStorage.setItem('isAllGetaway', (isAllGetaway ? isAllGetaway : false) + '');
  }

  onError = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    if (event) {
      this.setState({ isError: true });
    }
  };

  redirectToDetails = (bookNow?: boolean): void => {
    const { data, isSlider, condoSubcategory, history, activeGetaway } = this.props;
    const specialType = data.specialType.specialTypeName.toLowerCase();
    const { loginStore } = this.props;
    const { account } = loginStore;

    const toLowercaseSpecialTypeName = specialType;
    const isTypeNameStaycation = toLowercaseSpecialTypeName === GetawaysLocationEnum.Staycation;

    const isHome = isHomeFromPath(history);
    const isGetaways = isGetawaysFromPath(history);
    
    if (!isTypeNameStaycation && activeGetaway != GetawaysLocationEnum.AllInclusive) {
      if ((activeGetaway == GetawaysLocationEnum.Condos || activeGetaway == GetawaysLocationEnum.NewCondos) && !data.rsiresortId)
        history.push(
          `/${account?.name}/${data?.dealUrl}${
            isSlider ? `&${DEAL_HOME_LABEL}=${isGetaways ? GETAWAYS_KEY : isHome ? 'HOME' : 'SEARCH'}` : isGetaways ? `&${DEAL_HOME_LABEL}=${GETAWAYS_KEY}` : ''
          }`,
        );
      else {
        history.push(
          `/${account?.name}${Routes.GetawayDetails}/${data.promoId}/${specialType}/${
            condoSubcategory ? condoSubcategory : ''
          }`,
        );
      }
    } else {
      const {
        setDatesSelected,
        setRooms,
        resetFilters,
        clearDatesError,
        clearLocationError,
        setSelectLocation,
        setSelectLocationLabel,
        applyDates,
        applyRooms,
        applyLocation,
        history,
      } = this.props;

      const selectedLocation = data?.location;
      const selectedDeailId = data?.dealId;
      const hotelCode = selectedLocation?.code;
      const startDate = data?.checkinDate;
      const endDate = data?.checkoutDate;
      const rooms: any[] = [{ adultsCount: 2, kids: [] }];

      clearDatesError(UNDEFINED_VALUE);
      clearLocationError(UNDEFINED_VALUE);
      setSelectLocationLabel(selectedLocation.name);
      setSelectLocation(selectedLocation);

      resetFilters();
      UrlUtils.setUrl(FILTERS_LABEL, null);

      setRooms(getNormalizedRoomsResponse(rooms));

      setDatesSelected({ startDate, endDate });

      applyDates();
      applyRooms();
      applyLocation();

      const isAllInclusive = toLowercaseSpecialTypeName == GetawaysLocationEnum.AllInclusive;
      const isDealHome = isSlider && !isAllInclusive;
      const path = `${getHotelRoutePath(hotelCode, history)}&${DEALID_LABEL}=${selectedDeailId}${
        isDealHome || isAllInclusive
          ? `&${DEAL_HOME_LABEL}=${isGetaways ? GETAWAYS_KEY : isAllInclusive ? ALL_INCLUSIVE_KEY : isHome ? 'HOME' : 'SEARCH'}`
          : ''
      }`;

      if (isAllInclusive) {
        UrlUtils.removeFromUrl(LOCATION_OBJECT_LABEL);
        UrlUtils.removeFromUrl(LOCATION_LABEL);
        UrlUtils.removeFromUrl(DATES_LABEL);
        UrlUtils.removeFromUrl(ROOMS_LABEL);
        window.open(path, '_blank');
      } else {
        history.push(path);
      }

      ReactGA.event({
        category: account?.name as string,
        action: `${bookNow ? STAYC_BOOKN : STAYC_VIEWD}_${account?.name.toUpperCase()}`,
        label: `User clicked ${
          bookNow ? 'Book Now' : 'View Details'
        } button on getaway staycation offer`,
        nonInteraction: false,
      });
    }
  };

  render(): React.ReactNode {
    const { data, isSlider, loginStore } = this.props;
    const { isError } = this.state;
    const {
      city,
      name,
      title,
      state,
      country,
      promoId,
      unitSize,
      imageUrl,
      checkinDate,
      checkoutDate,
      youPayPrice,
      specialType,
      portsOfCall,
      priceBasedOn,
      lengthOfStay,
      numberOfNights,
      publicPrice,
      saving,
      savingPercentage,
      supplierType,
    } = data;
    const { specialTypeName } = specialType;
    const toLowercaseSpecialTypeName = specialTypeName.toLowerCase();
    const isTypeNameCondo = toLowercaseSpecialTypeName === GetawaysLocationEnum.Condo;
    const isTypeNameCondos = toLowercaseSpecialTypeName === GetawaysLocationEnum.Condos;
    const isTypeNameCruise = toLowercaseSpecialTypeName === GetawaysLocationEnum.Cruise;
    const isTypeNameFantasy = toLowercaseSpecialTypeName === GetawaysLocationEnum.Fantasy;
    const isTypeNameStaycation = toLowercaseSpecialTypeName === GetawaysLocationEnum.Staycation;
    const isTypeNameAllInclusive = toLowercaseSpecialTypeName === GetawaysLocationEnum.AllInclusive;

    const { account } = loginStore;
    const locale = account?.locale;
    const currency = getSelectedCurrency(account as IAccount);

    const checkInDate = getDateCheckInOut(checkinDate);
    const checkOutDate = getDateCheckInOut(checkoutDate);

    const formattedDate = checkinDate && format(checkInDate, DATE_FORMAT_MONTH, { locale });
    const formattedStartDate =
      checkinDate && format(checkInDate, DATE_FORMAT_MONTH_START, { locale });
    const formattedStartDateDifferent =
      checkinDate && format(checkInDate, DATE_FORMAT_MONTH_START_DIFFERENT, { locale });

    const formattedEndDate =
      checkoutDate && format(checkOutDate, DATE_FORMAT_MONTH_END, { locale });
    const formattedEndDateDifferent =
      checkoutDate && format(checkOutDate, DATE_FORMAT_MONTH_END_DIFFERENT, { locale });

    const startMonth = getMonth(checkInDate);
    const endMonth = getMonth(checkOutDate);
    const isDifferentMonths = startMonth !== endMonth;
    const staycationDate =
      checkInDate &&
      (isDifferentMonths
        ? `${formattedStartDate}-${formattedEndDate}`
        : `${formattedStartDateDifferent} - ${formattedEndDateDifferent}`);

    const displayPrices = publicPrice && saving;

    const isRecommended = supplierType === SupplierType.Guesty;

    const windowWidth = window.innerWidth;
    const styles: any = {};
    if (isSlider) {
      if (windowWidth >= 430) {
        styles.minWidth = '380px';
      } else {
        styles.minWidth = `${windowWidth - 40}px`;
      }
    }

    return (
      <div
        className={`getaway__list-item ${isRecommended ? 'recommended-condo' : ''} ${isSlider ? 'is-slider' : ''}`}
        style={styles}
      >
        <div className="getaway__image-background"></div>
        <div className="getaway__image">
          <img
            className="getaway__list-item_img"
            onError={(event) => this.onError(event)}
            src={isError || !imageUrl ? GetawayBackground : imageUrl}
            alt="img"
            loading="lazy"
          />
          {displayPrices ? (
            <div className={`getaway__prices ${isSlider ? 'is-slider' : ''}`}>
              <p>
                <label className="getaway__prices-title">
                  <FormattedMessage id="public.price" />:
                </label>{' '}
                <label className="getaway__prices-value public">
                  <Currency currency={currency} />
                  {publicPrice.toFixed(2)}
                </label>
              </p>
              <p style={{ display: 'flex', flexDirection: 'row' }}>
                <label className="getaway__prices-title savings">
                  <FormattedMessage id="savings" />:
                </label>{' '}
                <label className="getaway__prices-value savings">
                  <Currency currency={currency} />
                  {saving.toFixed(2)}{' '}
                  {savingPercentage ? (
                    <span className="condo-hotel-card__savings">
                      {savingPercentage.toFixed(0)}%{' '}
                      <FormattedMessage id="popular.destination.off" />
                    </span>
                  ) : null}
                </label>
              </p>
            </div>
          ) : null}
        </div>

        {isRecommended ? (
          <div className="hotel-card-wrapper__label-container">
            <div className="hotel-card-wrapper__label label-recommended">
              <p className="hotel-card-wrapper__label-title">
                <FormattedMessage id="recommended.condo" />
              </p>
            </div>
          </div>
        ) : null}

        {!isTypeNameAllInclusive && savingPercentage && savingPercentage > 0 ? (
          <div
            className={`hotel-card-wrapper__label-container ${isRecommended ? 'two-label' : ''}`}
          >
            <div className="hotel-card-wrapper__label">
              <p className="hotel-card-wrapper__label-title">
                <FormattedMessage id={account?.forceMemberWording ? 'member.save' : 'save'} />{' '}
                {savingPercentage.toFixed(0)}%
              </p>
            </div>
            <div className="hotel-card-wrapper__label-corner"></div>
          </div>) : 
          (isTypeNameCondo || isTypeNameCondos) ? (
            <div
              className={`hotel-card-wrapper__label-container ${isRecommended ? 'two-label' : ''}`}
            >
              <div className="hotel-card-wrapper__label">
                <p className="hotel-card-wrapper__label-title">
                  <FormattedMessage id="over.50.percentage" />
                </p>
              </div>
              <div className="hotel-card-wrapper__label-corner"></div>
            </div>) : null}

        {isTypeNameAllInclusive ? (
          <div className={`hotel-card-wrapper__label-container ${isSlider ? 'is-slider' : ''}`}>
            <div className="hotel-card-wrapper__label label-recommended">
              <p className="hotel-card-wrapper__label-title">
                <img src={AllInclusive} style={{ width: 16, marginRight: 5 }} />
                <FormattedMessage id="result.all-inclusive" />
              </p>
            </div>
          </div>
        ) : null}

        <div className="getaway__list-item_info">
          <div>
            <div className="getaway__list-item_info_header">
              <p className="getaway__list-item_info_header_title" style={{ paddingRight: '10px' }}>
                {title}
              </p>
              <div
                className="getaway__list-item_info_content_per"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <p className="getaway__list-item_info_header_value">
                  {formatMoneyCurrency(currency).format(youPayPrice)}
                </p>

                <div
                  className="getaway__list-item_info_content"
                  style={{ flexDirection: 'column', alignItems: 'center' }}
                >
                  <div className="getaway__list-item_info_content_per">
                    <p className="getaway__list-item_info_content_per_title">
                      {renderPriceBasedOn(toLowercaseSpecialTypeName)}
                    </p>
                    {isTypeNameStaycation || isTypeNameAllInclusive ? (
                      <Tooltip title={<FormattedMessage id="price.includes.taxes.fees" />}>
                        <span className="hotel-card-wrapper__price-info-icon">
                          <InfoSvg />
                        </span>
                      </Tooltip>
                    ) : null}
                    {priceBasedOn && !isTypeNameCondo ? (
                      <Tooltip
                        title={priceBasedOn}
                        overlayClassName="getaway__list-item_info_tooltip"
                        placement="bottom"
                      >
                        <div className="bookings-title-wrapper">
                          <InfoSvg />
                        </div>
                      </Tooltip>
                    ) : null}
                  </div>
                  <p className="getaway__list-item_info_content_text">
                    {!!promoId ? (
                      <span>
                        <FormattedMessage id="promo.id" /> {promoId}
                      </span>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>

            {isTypeNameCruise && (
              <>
                {portsOfCall.length ? (
                  portsOfCall.map((port: string, index: number) => (
                    <p className="getaway__list-item_info_location_cruise" key={`${port}-${index}`}>
                      {port}
                    </p>
                  ))
                ) : (
                  <p className="getaway__list-item_info_location_cruise">
                    <FormattedMessage id="no.data.available" />
                  </p>
                )}

                <p className="getaway__list-item_info_location_cruise">
                  <FormattedMessage id="ship" /> {name}
                </p>
              </>
            )}
            {isTypeNameCondo && (
              <p className="getaway__list-item_info_location">
                {city} {state} {country} | {unitSize}
              </p>
            )}
          </div>
          <div
            className={`getaway__list-item_info_date ${
              isTypeNameFantasy ? 'getaway__list-item_info_date_fantasy' : ''
            }`}
          >
            <p
              className={`getaway__list-item_info_date_text ${
                !!isTypeNameCruise ? 'getaway__list-item_info_date_text_cruise' : ''
              }`}
              style={{ paddingRight: '10px', width: 'calc(100% - 130px)' }}
            >
              {renderGetawaysCardDate(
                toLowercaseSpecialTypeName,
                formattedDate,
                staycationDate,
                lengthOfStay,
                city,
                numberOfNights,
                state,
              )}
            </p>

            {!isSlider ? (
              <BlueButton
                className="view-details"
                onClick={() => this.redirectToDetails()}
                reverse
                style={{
                  position: 'absolute',
                  bottom: '9px',
                  right: '15px',
                  background: 'transparent',
                  color: '#0081FE',
                  textDecoration: 'underline',
                }}
              >
                <FormattedMessage id="view.details" />
              </BlueButton>
            ) : null}

            <BlueButton onClick={() => this.redirectToDetails(true)}>
              <FormattedMessage id="book.now" />
            </BlueButton>
          </div>

          <div className="room-info__label">
            <ClockSvg />
            <p className="room-info__label-text">
              <FormattedMessage id="act.fast" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setRooms: roomsActions.setRooms,
  setDatesSelected: datesActions.setDatesSelected,
  resetFilters: filtersActions.resetFilters,
  clearDatesError: datesActions.setError,
  clearLocationError: locationActions.setError,

  setSelectLocationLabel: locationActions.setSelectLocationLabel,
  setSelectLocation: locationActions.setSelectLocation,

  applyDates: datesActions.applyDates,
  applyRooms: roomsActions.applyRooms,
  applyLocation: locationActions.applyLocation,

  setCondoSelectLocation: condosLocationPickerActions.selectLocation,
  setCondoSelectLocationLabel: condosLocationPickerActions.selectLocationLabel,
  setCondoDatesSelected: condoStrictDatesActions.setDates,
  setCondoFlexibleState: condoFlexibleDatePickerActions.setState,
};

export const GetawayListCard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(GetawayListCardComponent));
