
import React, { MouseEventHandler } from 'react';
import Table from 'antd/es/table';
import isEmpty from 'lodash/isEmpty';

import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
  FilterValue,
  Key,
  SorterResult,
  TablePaginationConfig
} from 'antd/lib/table/interface';
import type { ColumnsType } from 'antd/es/table';
import type { ResizeCallbackData } from 'react-resizable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faMinusCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { ResizableHeader } from '@components';
import { Loading } from '@share/components';
import { IColumnMenu, NULL_VALUE, orderSort } from '@constants';
import { getColumns } from '@utils';
import { Filters, ITextSearch } from '@share/common-types';

import { RootState } from '@share/utils';
import { connect } from 'react-redux';
import { ILoginState } from '@share/store/slices';

import './style.scss';

const zero = 0;
const one = 1;
const pageSizeOptions = ['10', '20', '50'];

export type RowKeyFunction = (row: any) => string;
export type RenderFunction = () => any;
export type EmptyFunction = () => void;
export type RefreshFunction = (isClick?: boolean) => void;
export type ApplyFunction = (filters: Filters) => void;
export type OnSelectedColumnFunction = (selectedColumns: IColumnMenu[]) => void;
export type onTableChangeFunction = (
                                      pagination: TablePaginationConfig,
                                      filters: Record<string, (Key | boolean)[] | null>,
                                      sorter: SorterResult<any>,
                                    ) => void;
export type onRowClickFunction = (row: any) => void;

export interface Action {
  onClickEvent?: MouseEventHandler;
  renderAction: RenderFunction;
};

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  title: string;
  loading: boolean;
  allColumns: IColumnMenu[];
  dataSource: any[];
  pagination: TablePaginationConfig;
  totalOrderCount: number;
  isFilterApplied: boolean;
  actions?: Action[];
  filters: Filters;
  textSearch?: ITextSearch;
  disableUpdateStorage?: boolean;
  hideRefresh?: boolean;

  rowKey: RowKeyFunction;
  onTableChange: onTableChangeFunction;
  onRefresh: RefreshFunction;
  onFiltersChange: ApplyFunction;
  onApplyFilters: ApplyFunction;
  onResetFilters: EmptyFunction;
  onRowClick?: onRowClickFunction;
}

interface IState {
  displayfilters: boolean;
  selectedColumns: IColumnMenu[];
  allColumns: IColumnMenu[];
}

class SearchTableComponent extends React.Component<IProps, IState> {
  state: IState = {
    displayfilters: false,
    selectedColumns: NULL_VALUE,
    allColumns: NULL_VALUE
  };

  componentDidMount(): void {
    const savedSelectedColumns = [...this.props.allColumns.filter(({ display }) => display === true).sort(orderSort)];
    const savedAllColumns = this.props.allColumns;

    this.setState({ selectedColumns: savedSelectedColumns, allColumns: savedAllColumns });

    this.props.onRefresh();
  }

  handleClearSearch = () => {
    this.props.onResetFilters();
    this.props.onRefresh();
  }

  handleApplyFilters = (filters: Filters) => {
    const filterConverted: any = {};
    Object.keys(filters).forEach(f => {
      const filter: any = filters[f as keyof Filters]
      if (filter.value) {
        filterConverted[f] = [`${filter.value}~equal`];
      }
    });

    this.props.onApplyFilters(filterConverted);
    this.props.onRefresh();
  }

  handleOnTableChange = (
    pagination: TablePaginationConfig, 
    filters: Record<string, FilterValue | null>, 
    sorter: any,
  ) => {
    if (this.props.onTableChange) {
      this.props.onTableChange(pagination, filters, sorter);
      this.props.onRefresh();
    }
  }

  getTotalTitle = (total: number, range: [number, number]): React.ReactNode => {
    return (
      <span>
        {range[zero]}-{range[one]} <FormattedMessage id="of" />{' '}
        <FormattedMessage id="items.count" values={{ count: total }} />
      </span>
    );
  };

  handleOnRowClick = (row: any) => {
    if (this.props.onRowClick) {
      this.props.onRowClick(row);
    }
  }

  handleResize = (index: number, childIndex?: number) =>
  (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
    const newColumns = [...this.state.selectedColumns];
    if (childIndex === null || childIndex === undefined) {
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
    } else {
      const childrens = newColumns[index].children;
      if (childrens) {
        childrens[childIndex] = {
          ...childrens[childIndex],
          width: size.width,
        };
      }
      newColumns[index] = {
        ...newColumns[index],
        children: childrens
      };
    }
    
    if (childIndex === null || childIndex === undefined) {
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
    } else {
      const childrens = newColumns[index].children;
      if (childrens) {
        childrens[childIndex] = {
          ...childrens[childIndex],
          width: size.width,
        };
      }
      newColumns[index] = {
        ...newColumns[index],
        children: childrens
      };
    }
    
    this.setState({ selectedColumns: newColumns });
  };

  getColumns = (): ColumnsType<any> => {
    const columns = getColumns(this.state.selectedColumns, this.props.filters);
    const list = [...columns.map((c, index) => {
      const resp = {
        ...c
      };

      if (c.children) {
        resp.children = c.children.map((child: any, i: number) => ({
          ...child,
          onHeaderCell: (column: any) => ({
            width: column.widthOriginal,
            onResize: this.handleResize(index, i),
          })
        }));
      } else {
        resp.onHeaderCell = (column: any) => ({
          width: column.widthOriginal,
          onResize: this.handleResize(index),
        });
      }

      return resp;  
    })];
    return list;
  };

  renderFilter = () => {
    const { isFilterApplied, textSearch, filters, loginStore } = this.props;
    const { account } = loginStore;

    const buttonTextColor = account?.buttonTextColor;
    const buttonBorder = account?.buttonBorder;
    const buttonBorderRadius = account?.buttonBorderRadius;
    const buttonColor = account?.buttonColor;
  
    const styleButtonBorderRadius = !isEmpty(buttonBorderRadius) ? { borderRadius: `${buttonBorderRadius}px` } : {};  
    const styleButtonBorder = !isEmpty(buttonBorder)? { ...styleButtonBorderRadius, border: buttonBorder } : { ...styleButtonBorderRadius };  
    const styleButtonColor = !isEmpty(buttonTextColor)? { ...styleButtonBorder, color: buttonTextColor } : { ...styleButtonBorder };  
    const styleButton = !isEmpty(buttonColor)? { ...styleButtonColor, background: buttonColor } : { ...styleButtonColor };  

    if (filters) {
      const isFilterTextSearchApplied = isFilterApplied || !!textSearch;
      const className = `search-table__refresh filters ${isFilterTextSearchApplied ? 'clear-fliters' : ''}`;
      return (
        <div className={className} onClick={() => this.setState({ displayfilters: true })}>
          <div className="action-button" style={styleButton}>
            {!isFilterTextSearchApplied && (<FontAwesomeIcon icon={faFilter} size="1x"/>)}
            {isFilterTextSearchApplied && (<FontAwesomeIcon icon={faMinusCircle} size="1x"/>)}
            <span>Filters</span>
          </div>
        </div>
      );
    }
  }

  render(): React.ReactNode {
    const {
      title,
      loading,
      dataSource,
      pagination,
      totalOrderCount,
      actions,
      hideRefresh,
      loginStore,
      rowKey,
      onRefresh,
    } = this.props;
    const { account } = loginStore;

    if (!this.state.selectedColumns) {
      return null;
    }

    const displayHeader = (!isEmpty(title) || (!hideRefresh || actions?.length));

    const buttonTextColor = account?.buttonTextColor;
    const buttonBorder = account?.buttonBorder;
    const buttonBorderRadius = account?.buttonBorderRadius;
    const buttonColor = account?.buttonColor;
  
    const styleButtonBorderRadius = !isEmpty(buttonBorderRadius) ? { borderRadius: `${buttonBorderRadius}px` } : {};  
    const styleButtonBorder = !isEmpty(buttonBorder)? { ...styleButtonBorderRadius, border: buttonBorder } : { ...styleButtonBorderRadius };  
    const styleButtonColor = !isEmpty(buttonTextColor)? { ...styleButtonBorder, color: buttonTextColor } : { ...styleButtonBorder };  
    const styleButton = !isEmpty(buttonColor)? { ...styleButtonColor, background: buttonColor } : { ...styleButtonColor };  
  
    return (
      <div className="search-table">
        <div className="search-table__wrapper">
          {displayHeader ? (
            <div className="search-table__header">
              <div className="search-table__actions">
                <div className="container-fluid">
                  <div className="row">
                    {!isEmpty(title) ? (
                      <div className="col-md-1 buttons" style={{ paddingRight: '0', position: 'relative' }}>
                        <div className="search-table__title">
                          <h1>{title}</h1>
                        </div>
                      </div>) : null}

                    {(!hideRefresh || actions?.length) ? (
                      <div className={`col-md-12 buttons`} style={{ paddingRight: '0', position: 'relative' }}>
                        {actions && (actions.map(({ onClickEvent, renderAction }, index) => {
                          const render = renderAction();
                          if (!render) {
                            return null;
                          }

                          return (
                            <div key={index} className="search-table__refresh actions" onClick={onClickEvent? onClickEvent : undefined}>
                              {render}
                            </div>
                          );
                        }))}

                        {!hideRefresh ? (
                          <div className="search-table__refresh" onClick={() => onRefresh(true)}>
                            <div className="action-button" style={styleButton}>
                              <FontAwesomeIcon icon={faSyncAlt} size="1x"/>
                              <span>Refresh</span>
                            </div>
                          </div>) : null}
                      </div>) : null}
                  </div>
                </div>
              </div>
            </div>) : null}

          <div className={`search-table__table ${(pagination?.pageSize && dataSource?.length)? `page${pagination?.pageSize}` : ''} ${displayHeader ? '' : 'no-header'}`}>
            <Table
              loading={{
                spinning: loading,
                indicator: <Loading />,
              }}
              components={{
                header: {
                  cell: ResizableHeader,
                },
              }}
              onRow={(record) => { return { onClick: () => { this.handleOnRowClick(record); } }; }}
              onChange={this.handleOnTableChange}
              rowKey={rowKey}
              columns={this.getColumns()}
              dataSource={dataSource}
              scroll={{ x: true }}
              pagination={{
                ...pagination,
                showSizeChanger: (pagination?.showSizeChanger === false || pagination?.showSizeChanger === true) ? pagination.showSizeChanger : true,
                hideOnSinglePage: (pagination?.hideOnSinglePage === false || pagination?.hideOnSinglePage === true) ? pagination.hideOnSinglePage : false,
                pageSizeOptions,
                total: totalOrderCount,
                showTotal: this.getTotalTitle,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const SearchTable = connect(mapStateToProps)(injectIntl(SearchTableComponent));

