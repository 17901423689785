
import React, { useState } from 'react';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { List, Tooltip } from 'antd';

import { getMyProfile, IMyProfileState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';
import { ModalConfirmation } from '@components';
import { MyProfileAPI } from '@share/api';

import { ModalAddAuthorizedUser } from '../modal-add-authorize-user';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import { isEmpty } from 'lodash';

interface IMapStateToProps {
  loginStore: ILoginState;
  myProfileStore: IMyProfileState;
}

interface IMapDispatchToProps {
  get: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  onLoadingDelete: (loading: boolean) => void;
}

function AuthorizedUsersComponent(props: IProps) {

  const [ showConfirmation, setShowConfirmation ] = useState(false);
  const [ showAddModal, setShowAddModal ] = useState(false);
  const [ selectedUser, setSelectedUser ] = useState<number | null>(null);
  const [ loadingDelete, setLoadingDelete ] = useState(false);

  const { myProfileStore, intl, loginStore, get, onLoadingDelete } = props;
  const { result } = myProfileStore;
  const { account, user, loading } = loginStore;
  
  const handleConfirmDelete = () => {
    if (!loadingDelete) {
      const rsiId = user?.keyid;

      setLoadingDelete(true);
      onLoadingDelete(true);

      MyProfileAPI.deleteAuthorizedUser(rsiId as number, selectedUser as number).then(() => {
          setShowConfirmation(false);
          setSelectedUser(null);
          get();
        })
        .finally(() => {
          setLoadingDelete(false);
          onLoadingDelete(false);
        });
    }
  }

  const buttonTextColorReverse = account?.buttonTextColorReverse;
  const styleButtonColor = !isEmpty(buttonTextColorReverse)? { color: buttonTextColorReverse } : {};  

  return (
    <div className="my-profile-wrapper__authorized-users-container row" style={{ justifyContent: 'center', position: 'relative' }}>
      <div className="my-profile-wrapper__add-authorized-users-button" onClick={() => setShowAddModal(true)} style={styleButtonColor}>
        <Tooltip title={intl.formatMessage({ id: 'add.authorized.users' })} placement="top">
          <FontAwesomeIcon icon={faPlusCircle} size="2x" />
        </Tooltip>
      </div>
      <List style={{ width: '310px', position: 'relative' }}
        itemLayout="horizontal"
        dataSource={result.authorizedUsers}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={item.firstName + " " + item.middleName + " " + item.lastName}
              description={item.personType}
            />
            {(item?.referenceType === 'AuthId' && /^\d+$/.test(item.referenceId)) ? (
              <div className="my-profile-wrapper__delete-authorized-users-button" onClick={() => {
                try {
                  setShowConfirmation(true);
                  setSelectedUser(parseInt(item.referenceId));
                } catch(e) {
                  console.error(e);
                }
              }}>
                <Tooltip title={intl.formatMessage({ id: 'delete.authorized.users' })} placement="top">
                  <FontAwesomeIcon icon={faTrash} />
                </Tooltip>
              </div>) : null}
          </List.Item>
        )}
      />

      <ModalConfirmation
        message="confirm.delete.authorized.users"
        title="delete.authorized.users"
        yesMessage="confirm"
        noMessage="cancel"
        visible={showConfirmation}
        disabled={loadingDelete}
        onCancel={() => {
          if (!loadingDelete) {
            setShowConfirmation(false);
          }
        }}
        onConfirm={handleConfirmDelete}
      />

      <ModalAddAuthorizedUser
        visible={showAddModal}
        rsiId={user?.keyid as number}
        disabled={loading || loadingDelete}
        onCancel={() => setShowAddModal(false)}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    myProfileStore: state.myProfileStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  get: () => {
    dispatch(getMyProfile());
  }
});

const ComponentIntl = injectIntl(AuthorizedUsersComponent);

export const AuthorizedUsers = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
