
// @ts-ignore
import JotformEmbed from 'react-jotform-embed';

import { useSelector } from 'react-redux';

import { FunctionalRootState } from '@share/utils';
import { ILoginState, IMenuState } from '@share/store/slices';
import { Loading } from '@share/components';

import './style.scss';

const MLM_CONTACT_US_FORM = 'https://form.jotform.com/250377166791263';
const MLM_CONTACT_US_LEVARTI_FORM = 'https://form.jotform.com/250755932698270';

export function ContactUsForm() {

  const menuStore: IMenuState = useSelector((state: FunctionalRootState) => state.navigationMenuStore);
  const { account }: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);

  const isVacationInspiration = menuStore?.items?.organizationId && account?.vacationInspirationsOrgIds?.includes(menuStore?.items?.organizationId);
  const isVPT = menuStore?.items?.organizationId && account?.vptOrgIds?.includes(menuStore?.items?.organizationId);

  const fomrId = isVacationInspiration ? 
                    '250065136933252' : 
                    isVPT ? 
                          '250134098212246' :
                          '220953995609167';

  if (menuStore?.loading || !menuStore?.items) {
    return (
      <div className="contact-us-form loading-form">
        <Loading />
      </div>);
  }

  const isLevarti = account?.levartiOrgIds?.includes(menuStore?.items?.organizationId as number);
  const mlmURL = isLevarti ? MLM_CONTACT_US_LEVARTI_FORM : MLM_CONTACT_US_FORM;

  return (
    <div className="contact-us-form">
      {!menuStore?.items?.isMLM ? (
        <JotformEmbed {...{ src: `https://form.jotform.com/${fomrId}?Look=${menuStore?.items?.rsiId}&fullName[first]=${menuStore?.items?.firstName}&fullName[last]=${menuStore?.items?.lastName}&email=${menuStore?.items?.email}`, frameBorder: '0' }} />) : (
        <JotformEmbed {...{ src: `${mlmURL}?Look=${menuStore?.items?.rsiId}&fullName[first]=${menuStore?.items?.firstName}&fullName[last]=${menuStore?.items?.lastName}&email=${menuStore?.items?.email}`, frameBorder: '0' }} />
      )}
    </div>
  );
}
