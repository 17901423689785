import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IHotelAutocompleteItem, IHotelAutocompleteResponse } from '@share/common-types';
import {
  SESSION_EXPIRED_MESSAGE,
  SESSION_EXPIRED_STATUS,
  SortTypes,
  Urls,
  SESSION_SEARCH_EXPIRED_MESSAGE,
  HomesTypes,
  ALL_INCLUSIVE_VALUE
} from '@share/constants';
import { getHeaders, axiosInstance, AppThunk, getFilters } from '@share/utils';

export enum HotelsFilterType {
  Normal,
  Sundance,
  B2C,
  B2cHotelOnly,
  NormalVacations,
  B2CVacations,
  NormalVacationsFiltered,
  B2CVacationsFiltered,
  Recommended,
  RecommendedCustomerAgent ,
  AllInclusive
}

export interface IFilterType {
  type: HotelsFilterType;
  list?: string[];
}

export interface IFiltersState {
  propertyName: string;
  distance: number;
  starRatings: number[];
  amenities: string[];
  accommodationType: string[];
  accessibility: string[];
  popularLocations: string[];
  refundable: string[];
  budget: string[];
  neighbourhoods: string[];
  places: string[];
  flexibleDates: string[];
  sortBy: SortTypes;
  autocompleteItems: IHotelAutocompleteItem[];
  autocompleteLoading: boolean;
  selectedAutocompleteItem: IHotelAutocompleteItem | null;
  selectedAutocompleteLabel: string;
  boardNames: string[];
  searchHomes: HomesTypes;
}

export const filtersInitialStateBase: IFiltersState = {
  sortBy: SortTypes.BiggestSavingsPercentage,
  propertyName: '',
  distance: 10,
  budget: [],
  neighbourhoods: [],
  places: [],
  starRatings: [],
  amenities: [],
  popularLocations: [],
  accommodationType: [],
  accessibility: [],
  refundable: [],
  autocompleteItems: [],
  flexibleDates: [],
  autocompleteLoading: false,
  selectedAutocompleteItem: null,
  selectedAutocompleteLabel: '',
  boardNames: [],
  searchHomes: HomesTypes.All
};

export const filtersInitialState: IFiltersState = {
  ...filtersInitialStateBase,
  sortBy: SortTypes.BiggestSavingsPercentage,
  searchHomes: HomesTypes.All
};

export const filtersB2CInitialState: IFiltersState = {
  ...filtersInitialState,
  sortBy: SortTypes.GuestRating,
  searchHomes: HomesTypes.All
};

export const filtersB2CHotelsOnlyInitialState: IFiltersState = {
  ...filtersInitialState,
  sortBy: SortTypes.GuestRating,
  searchHomes: HomesTypes.HotelOnly
};

export const filtersInitialVacationsState: IFiltersState = {
  ...filtersInitialStateBase,
  sortBy: SortTypes.BiggestSavingsPercentage,
  searchHomes: HomesTypes.VacationRentalOnly
};

export const filtersB2CVacationsInitialState: IFiltersState = {
  ...filtersInitialState,
  sortBy: SortTypes.GuestRating,
  searchHomes: HomesTypes.VacationRentalOnly
};

export const filtersSundanceInitialState: IFiltersState = {
  ...filtersInitialState,
  searchHomes: HomesTypes.HotelOnly
};

export const filtersRecommnededInitialState: IFiltersState = {
  ...filtersInitialState,
  sortBy: SortTypes.Recommended,
};

export const filtersRecommnededCustomerAgentInitialState: IFiltersState = {
  ...filtersInitialState,
  sortBy: SortTypes.RecommendedCustomerAgent,
};

export const VACATIONS_FILTERED = ['Apartment', 'Cottage', 'Villa', 'Private vacation home' ];

export const filtersInitialVacationsFilteredState: IFiltersState = {
  ...filtersInitialVacationsState,
  accommodationType: [...VACATIONS_FILTERED]
};

export const filtersB2CVacationsInitialFilteredState: IFiltersState = {
  ...filtersB2CVacationsInitialState,
  accommodationType: [...VACATIONS_FILTERED]
};

export const filtersAllInclusiveInitialState: IFiltersState = {
  ...filtersInitialState,
  sortBy: SortTypes.PriceAsc,
  boardNames: [ALL_INCLUSIVE_VALUE]
};

const AUTO_COMPLETE_MAX_ITEMS = 5;
const zeroItem = 0;

const filtersSlice = createSlice({
  name: 'filters',
  initialState: filtersInitialState,
  reducers: {
    setSortBy: (state: IFiltersState, { payload }: PayloadAction<SortTypes>) => {
      state.sortBy = payload;
    },
    setPropertyName: (state: IFiltersState, { payload }: PayloadAction<string>) => {
      state.propertyName = payload;
    },
    setBudget: (state: IFiltersState, { payload }: PayloadAction<string[]>) => {
      state.budget = payload;
    },
    setNeighbourhoods: (state: IFiltersState, { payload }: PayloadAction<string[]>) => {
      state.neighbourhoods = payload;
    },
    setPopularLocations: (state: IFiltersState, { payload }: PayloadAction<string[]>) => {
      state.popularLocations = payload;
    },
    setPlaces: (state: IFiltersState, { payload }: PayloadAction<string[]>) => {
      state.places = payload;
    },
    setStarRating: (state: IFiltersState, { payload }: PayloadAction<number[]>) => {
      state.starRatings = payload;
    },
    setAmenities: (state: IFiltersState, { payload }: PayloadAction<string[]>) => {
      state.amenities = payload;
    },
    setAccommodationType: (state: IFiltersState, { payload }: PayloadAction<string[]>) => {
      state.accommodationType = payload;
    },
    setAccessibility: (state: IFiltersState, { payload }: PayloadAction<string[]>) => {
      state.accessibility = payload;
    },
    setBoardName: (state: IFiltersState, { payload }: PayloadAction<string[]>) => {
      state.boardNames = payload;
    },
    setRefundable: (state: IFiltersState, { payload }: PayloadAction<string[]>) => {
      state.refundable = payload;
    },
    setFlexibleDate: (state: IFiltersState, { payload }: PayloadAction<string[]>) => {
      state.flexibleDates = payload;
    },
    setDistance: (state: IFiltersState, { payload }: PayloadAction<number>) => {
      state.distance = payload;
    },
    resetFilters: () => {
      return filtersInitialState;
    },
    resetFiltersByType: (_state: IFiltersState, { payload }: PayloadAction<IFilterType>) => {    
      if (payload.type === HotelsFilterType.B2C) {
        return filtersB2CInitialState;
      } else if (payload.type === HotelsFilterType.Recommended) {
        return filtersRecommnededInitialState;
      } else if (payload.type === HotelsFilterType.RecommendedCustomerAgent) {
        return filtersRecommnededCustomerAgentInitialState;
      } else if (payload.type === HotelsFilterType.Sundance) {
        return filtersSundanceInitialState; 
      } else if (payload.type === HotelsFilterType.B2cHotelOnly) {
        return filtersB2CHotelsOnlyInitialState;
      } else if (payload.type === HotelsFilterType.B2CVacations) {
        return filtersB2CVacationsInitialState;
      } else if (payload.type === HotelsFilterType.NormalVacations) {
        return filtersInitialVacationsState;
      } else if (payload.type === HotelsFilterType.NormalVacationsFiltered) {
        return payload.list?.length ? { ...filtersInitialVacationsFilteredState, accommodationType: [...payload.list] } : filtersInitialVacationsFilteredState;
      } else if (payload.type === HotelsFilterType.B2CVacationsFiltered) {
        return payload.list?.length ? { ...filtersB2CVacationsInitialFilteredState, accommodationType: [...payload.list] } : filtersB2CVacationsInitialFilteredState;
      } else if (payload.type === HotelsFilterType.AllInclusive) {
        return filtersAllInclusiveInitialState;
      } else {
        return filtersInitialState;
      }
    },
    setFilters: (_state: IFiltersState, { payload }: PayloadAction<IFiltersState>) => {
      return payload;
    },
    setAutocompleteItems: (
      state: IFiltersState,
      { payload }: PayloadAction<IHotelAutocompleteItem[]>,
    ) => {
      state.autocompleteItems = payload;
    },
    setAutocompleteLoading: (state: IFiltersState, { payload }: PayloadAction<boolean>) => {
      state.autocompleteLoading = payload;
    },
    selectAutocompleteItem: (
      state: IFiltersState,
      { payload }: PayloadAction<IHotelAutocompleteItem>,
    ) => {
      state.selectedAutocompleteItem = payload;
    },
    selectAutocompleteLabel: (state: IFiltersState, { payload }: PayloadAction<string>) => {
      state.selectedAutocompleteLabel = payload;
    },
    setSearchHomes: (state: IFiltersState, { payload }: PayloadAction<HomesTypes>) => {
      state.searchHomes = payload;
    }
  },
});

export const filtersActions = filtersSlice.actions;

export const filtersReducer = filtersSlice.reducer;

export const getHotelsAutocomplete = (search: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { filtersStore, hotelsStore, locationsStore } = getState();
      const { searchHomes } = filtersStore;

      dispatch(filtersActions.setAutocompleteLoading(true));

      const res: { data: IHotelAutocompleteResponse, status: number } = await axiosInstance.post(
        Urls.HotelAutocomplete,
        {
          count: AUTO_COMPLETE_MAX_ITEMS,
          sessionKey: hotelsStore.sessionKey,
          location: hotelsStore.lastSearchedLocation
            ? hotelsStore.lastSearchedLocation.geoLocation
            : locationsStore.selectedLocation.geoLocation,
          filter: {
            ...getFilters(filtersStore),
            propertyName: search,
          },
          searchHomes,
        },
        {
          ...getHeaders(),
        },
      );

      dispatch(filtersActions.setAutocompleteItems(res?.status === 200 ? res.data.hotelDetails : []));
      dispatch(filtersActions.setAutocompleteLoading(false));
    } catch (error: any) {
      dispatch(filtersActions.setAutocompleteLoading(false));
      console.error(error);

      // error when user session ended
      if (
        error?.response?.status === SESSION_EXPIRED_STATUS &&
        error?.response?.data &&
        (error?.response?.data[zeroItem] === SESSION_EXPIRED_MESSAGE ||
          error?.response?.data[zeroItem]?.errorMessage === SESSION_SEARCH_EXPIRED_MESSAGE)
      ) {
        window.location.reload();
      }
    }
  };
};
