
import purify from 'dompurify';

import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import { FunctionalRootState } from '@share/utils';
import { IReservationCancellationState } from '@share/store/slices';

import './style.scss';

export function ReservationCancellationPolicies() {

  const { reservationCancellationDetails }: IReservationCancellationState = useSelector((state: FunctionalRootState) => state.reservationCancellationStore);  
  const { reservationDetails } = reservationCancellationDetails;

  const bookingRemarks = get(reservationDetails, 'bookingRemarks');
  const cancellationPoliciesText = get(reservationDetails, 'cancellationPoliciesText');
  const refundabilityText = get(reservationDetails, 'refundabilityText');

  return (
    <div className="reservation-cancellation-policies">
      <div className="reservation-cancellation-policies__header">
        <h4 className="reservation-cancellation-policies__title secondary-font">
          <FormattedMessage id="cancellation.policy" />:
        </h4>
      </div>
      <div className="reservation-cancellation-policies__info">
        <p className="reservation-cancellation-policies__info-description">
          {cancellationPoliciesText}
        </p>
      </div>
      {refundabilityText && (
        <div className="reservation-cancellation-policies__info">
          <p className="reservation-cancellation-policies__info-title">
            <FormattedMessage id="refundability" />:
          </p>
          <p className="reservation-cancellation-policies__info-description">
            {refundabilityText}
          </p>
        </div>
      )}
      {bookingRemarks && (
        <div className="reservation-cancellation-policies__info">
          <div className="reservation-cancellation-policies__header">
            <h4 className="reservation-cancellation-policies__title secondary-font">
              <FormattedMessage id="booking.remarks" />:
            </h4>
          </div>
          <p
            className="reservation-cancellation-policies__info-description"
            dangerouslySetInnerHTML={{ __html: purify.sanitize(bookingRemarks) }}
          />
        </div>
      )}
    </div>
  );
}
