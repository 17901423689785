import { SortTypes } from '@share/constants';
import {
  IImage,
  IRating,
  IRange,
  IGeoLocation,
  ISessionKey,
  ICounterItem,
  IBudgetRange,
  ICondoDateRange,
  CondoMatchTypeEnum,
  ICondoGuests,
  ICondoLocation,
  IBounds,
  DateSearchTypeEnum,
} from '@share/common-types';

const ZERO = 0;
const TWO = 2;
const THREE = 3;
const THREE_HALF = 3.5;
const FOUR = 4;
const FOUR_HALF = 4.5;

export enum BookingErrorsEnum {
  SoldOut = 'SoldOut',
  UnexpectedResponse = 'UnexpectedResponse',
  GuestInformationFailed = 'GuestInformationFailed',
  PaymentFailed = 'PaymentFailed',
  RoomsUnavailable = 'rooms_unavailable',
  CarUnavailable = 'car_unavailable',
  General = 'general',
  BookingSummeryInformationFailed = 'Error: Request failed with status code 404',
  InvalidPayload = 'invalid_payload',
  CheckoutError = 'checkout_error',
}

export interface ICondo {
  condoId: number;
  displayName: string;
  rating: IRating;
  fullAddress: string;
  address: string;
  city: string;
  refundability: any;
  location: IGeoLocation;
  images: IImage[];
  condoType: string;
  sleepsCount: number;
  price?: number;
  pricePerNight?: number;
  savings: number;
  publicPrice?: number;
  availableDates?: ICondoDateRange[];
  matchType: CondoMatchTypeEnum;
  totalNights: number;
  apartmentType: string;
  bathroomsCount: number;
  daysCount: number;
  monthsCount: number;
  maxOccupancy: number;
  privacyOccupancy: number;
  supplierIdentifier?: string;
  isAllInclusive?: boolean;
  supplierType?: string;
  maxWalletClientCash?: number;
  savedProperty: boolean;
}

export interface CondoDestination {
  country: string;
  state: string;
  name: string;
  destination: string;
  area: boolean;
  totalAmount: number;
  placeType: string;
  location: ICondoLocation;
}

export enum RatingEnum {
  Excellent = FOUR_HALF,
  Great = FOUR,
  Good = THREE_HALF,
  Average = THREE,
  Poor = TWO,
  Bad = ZERO,
}

export enum StayForEnum {
  Week = 'Week',
  TwoWeeks = 'TwoWeeks',
  Month = 'Month',
}

export interface ICondoRequestFilters {
  propertyName: string;
  budgetRanges: IRange[];
  guestRatings: IRange[];
  distanceInMiles: number;
  unitAmenities: string[];
  facilities: string[];
  activities: string[];
  condoTypes: string[];
  policies: string[];
  accessibilities: string[];
  suppliers: string[];
  neighbourhoods: string[];
  places: IPlace[];
  kitchenTypes: string[];
  bathrooms: number[];
  days: number[];
  months: number[];
  isAllInclusiveOnly: boolean;
  cities: number[];
}

export interface ICondoSearchRequest {
  bounds?: IBounds;
  pageNumber?: number;
  sessionKey?: ISessionKey | null | undefined;
  condosRequest?: {
    location: ICondoLocation;
    checkIn?: string | null | undefined;
    checkOut?: string | null | undefined;
    adultsCount: number;
    childrenCount: number;
    bedroomsCount: number;
    stayDuration?: string;
    months?: string[];
    cities?: string[];
    residency?: string;
  };
  filter: ICondoRequestFilters;
  sortBy: SortTypes;
  pageSize: number;

  residency?: string;
  currency?: string;
}

export interface IGustRating {
  key: {
    title: string;
    from: number;
    to: number;
  };
  count: number;
}

export interface ICounter<T> {
  key: T;
  count: number;
}

export interface IPlace {
  city: string;
  state?: string;
  country: string;
  title: string;
}

export interface ICondoCountersParentLocation {
  name: string;
  totalCondos: number;
  totalOptions: number;
}

export interface ICondoCounters {
  unitAmenities: ICounterItem[];
  budgetRanges: IBudgetRange[];
  neighbourhoods: ICounterItem[];
  places: ICounter<IPlace>[];
  facilities: ICounterItem[];
  activities: ICounterItem[];
  accessibilities: ICounterItem[];
  bathrooms: ICounterItem[];
  days: ICounterItem[];
  months: ICounterItem[];
  condoTypes: ICounterItem[];
  kitchenTypes: ICounterItem[];
  totalCondos: number;
  totalFilteredExactCondos: number;
  totalFilteredCondos: number;
  totalAlternativeCondos: number;
  totalFilteredAlternativeCondos: number;
  totalOptions: number;
  totalFilteredOptions: number;
  totalAlternativeOptions: number;
  totalFilteredExactOptions: number;
  totalFilteredAlternativeOptions: number;
  guestRatings: IGustRating[];
  allInclusiveCount: number;
  parentLocation: ICondoCountersParentLocation;
  cities: ICounterItem[];
}

export interface ICondoSearchResponse {
  searchCondos: ICondo[];
  sessionKey: ISessionKey;
  bounds: IBounds;
  counters: ICondoCounters;
}

export enum SearchTypeEnum {
  NewSearch = 'NewSearch',
  Pagination = 'Pagination',
  MapSearch = 'MapSearch',
  SortsFilters = 'SortsFilters',
}

export interface ICondoRequest {
  residency?: string; // hardcode 'US' for now
  condoIds?: (number | string)[]; // if LocationsTypes.Hotel
  location?: IGeoLocation; // if LocationsTypes.Airport or LocationsTypes.Destination
  checkIn: string; // date in ISO format
  checkOut: string; // date in ISO format
  radiusInMeters?: number;
  guests: ICondoGuests;
}

export interface ICondoRecentSearches {
  months?: string[];
  checkIn?: string;
  checkOut?: string;
  residency: string;
  adultsCount: number;
  childrenCount: number;
  bedroomsCount: number;
  includeStudio: boolean;
  location: ICondoLocation;
  stayDuration?: StayForEnum;
  searchType: DateSearchTypeEnum;
}

export enum CondoRequestTypeEnum {
  Strict = 'Strict',
  Flexible = 'Flexible',
  Anytime = 'Anytime',
}

export interface ICondoUnitsRequest {
  checkIn?: string;
  checkOut?: string;
  months?: string[];
  adultsCount: number;
  childrenCount: number;
  bedroomsCount: number;
}

export enum ZipRuleTypeEnum {
  startsWith = 'startsWith',
  range = 'range',
  none = 'none',
}

export enum SurroundingType {
  whatsNearby = 'whatsNearby',
  topAttractions = 'topAttractions',
  closestAirports = 'closestAirports',
}

export enum ProximityType {
  onsite = 'onsite',
  nearby = 'nearby',
  offsite = 'offsite',
}

export enum SupplierType {
  Rci = '20',
  Intervals = '35',
  CondoWarehouse = '45',
  CapitalVactions = '50',
  Guesty = '01',
  HolidaySystems = '80',

  RESTEL = '63',
  DID = '64',
  GAR = '66',
  HDO = '68',
  HBD = '67',
  TBO = '70',
  // NTP	= '76',
  PRLN = '69',
  EXPP = '65',
  // PRLN	= '69',
  CIR = '13',
  TST = '90',
}

export interface ICountriesCode {
  rciCountries?: IRciCountriesCodes[];
  intervalCountries?: IIntervalCountriesCodes[];
}

export interface ICondoStates {
  code: string;
  name: string;
  zipRule?: string;
}

export interface IRciCountriesCodes {
  isZipRequired: boolean;
  name: string;
  isoCode: string;
  states: ICondoStates[];
  zipRuleType: string;
}

export interface IIntervalCountriesCodes {
  isStateRequired: boolean;
  code: string;
  isoCode: string;
  name: string;
  states: ICondoStates[];
}
