
import { connect } from 'react-redux';

import { RootState } from '@share/utils';

import { IDashboardReportState } from '@store/slices';

import { ColumnType } from '../dashboard-report-table';
import { DashboardReportTopBase } from '../dashboard-report-top-base';

import './style.scss';

interface IMapStateToProps {
  dashboardReportsStore: IDashboardReportState;
}

interface IProps extends IMapStateToProps {}

function DashboardReportTopDestinationsComponent(props: IProps) {
  const { dashboardReportsStore } = props;
  const { loading } = dashboardReportsStore;
  
  const topDestinationsHomes = dashboardReportsStore.dashBoardData?.topDestinationsHomes;

  return (
    <DashboardReportTopBase title="top.destinations" topList={topDestinationsHomes} loading={loading} header={[
      { text: 'location', width: 24, type: ColumnType.TEXT },
      { text: 'numberOfRooms', width: 8, type: ColumnType.NUMBER },
      { text: 'numberOfBookings', width: 10, type: ColumnType.NUMBER },
      { text: 'averageSaving', width: 13, type: ColumnType.CURRENCY },
      { text: 'saving', title: 'savings', width: 13, type: ColumnType.CURRENCY },
      { text: 'marketShare', width: 14, type: ColumnType.NUMBER },
      { text: 'commissions', width: 12, type: ColumnType.CURRENCY },
      { text: 'grossAmount', width: 13, type: ColumnType.CURRENCY }]} />
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    dashboardReportsStore: state.dashboardReportsStore
  };
};

export const DashboardReportTopDestinations = connect(mapStateToProps)(DashboardReportTopDestinationsComponent);
