import { AxiosPromise } from 'axios';
import { axiosInstance, getHeaders } from '@share/utils';
import { Urls } from '@share/constants';
import { IMyProfile } from '@share/common-types';
import { TablePaginationConfig } from 'antd';
import qs from 'qs';
import { IBookingManualSort, IFiltersManualBooking } from '../../components/components/dashboard/dashboard-manual-booking';
import { isEmpty } from 'lodash';

export interface IAuthorizedUser {

}

export const TravelHistoryAPI = {
  get(id: number): AxiosPromise<any> {
    return axiosInstance.get(`${Urls.getTravelHistory}?memberId=${id}`, { ...getHeaders() });
  }
};

export const MyProfileAPI = {
  get(id: number): AxiosPromise<any> {
    return axiosInstance.get(`${Urls.ssoMember}?memberId=${id}`, { ...getHeaders() });
  },

  update(id: number, profile: IMyProfile): AxiosPromise<any> {
    return axiosInstance.put(Urls.ssoMember, { memberId: id, data: profile }, { ...getHeaders() });
  },

  addAuthorizedUser(data: IAuthorizedUser): AxiosPromise<any> {
    return axiosInstance.post(`${Urls.ssoMember}/authuser`, data, { ...getHeaders() });
  },

  deleteAuthorizedUser(rsiId: number, authId: number): AxiosPromise<any> {
    return axiosInstance.delete(`${Urls.ssoMember}/authuser`, { ...getHeaders(), data: { rsiId, authId } });
  },
};

const buildQueryParams = (filters?: IFiltersManualBooking) => {
  const queryParams: any = {};


  if (filters) {
    if (filters.agentName) {
      queryParams.agentName = filters.agentName;
    }

    if (filters.paymentStatus) {
      queryParams.paymentStatus = filters.paymentStatus;
    }

    if (filters.bookingId) {
      queryParams.bookingId = filters.bookingId;
    }
  }

  return queryParams;
};

export const BookingManualAPI = {
  get(
    pagination: TablePaginationConfig,
    agentID: number,
    sort: IBookingManualSort,
    filters?: IFiltersManualBooking
  ): AxiosPromise<any> {
    const queryParams = {
      ...buildQueryParams(filters),
      agentID,
      page: pagination.current,
      results: pagination.pageSize,
    };
    
    if(!isEmpty(sort.orderBy) && !isEmpty(sort.sortOrder)) {
      queryParams.sortOrder = sort.orderBy;
      queryParams.orderBy = sort.sortOrder;
    }

    const url = `${Urls.bookingManual}?${qs.stringify(queryParams)}`;

    return axiosInstance.get(url, { ...getHeaders() });
  },
  post(data: any): AxiosPromise<any> {
    return axiosInstance.post(`${Urls.bookingManual}`, data, { ...getHeaders() });
  },
};
