import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel } from 'antd';
import { ITravelLeaderState, getTravelLeader } from '@store/slices';
import { ILoginState, IMenuState } from '@share/store/slices';
import { FunctionalRootState } from '@share/utils';
import { TRAVEL_LEADERS_DOMAIN } from '@constants';
import { ITravelLeader } from '@common-types';
import { Routes } from '@share/constants';
import { BlueButton } from '@share/components';

import Chevrons from '@assets/icons/chevrons.png';

import './style.scss';

const SPEED = 1000;
const SLIDES_TO_SHOW = 1;
const SLIDES_TO_SCROLL = 1;

export function TravelLeader() {

  const propsCarousel = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: SPEED,
    slidesToShow: SLIDES_TO_SHOW,
    slidesToScroll: SLIDES_TO_SCROLL,
    autoplay: true,
  };

  const menuStore: IMenuState = useSelector((state: FunctionalRootState) => state.navigationMenuStore);
  const { account }: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);
  const { travelLeader, loading }: ITravelLeaderState = useSelector((state: FunctionalRootState) => state.travelLeaderStore);
  
  const { items } = menuStore;

  const isDataAvailable = travelLeader && travelLeader && travelLeader.length > 0;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTravelLeader());
  }, []);

  const siteId = items?.siteId;
  const organizationId = items?.organizationId;

  const displayTravelLeaderBackgroundRsi = account?.displayTravelLeaderBackgroundRsi;
  const displayTravelLeaderBackgroundRsiOrgidList = account?.displayTravelLeaderBackgroundRsiOrgidList;
  const displayTravelLeaderBackgroundRsiSiteidList = account?.displayTravelLeaderBackgroundRsiSiteidList;

  const displayTravelLeadersBackground = useMemo(() => displayTravelLeaderBackgroundRsi && ((!displayTravelLeaderBackgroundRsiOrgidList?.length && !displayTravelLeaderBackgroundRsiSiteidList?.length) || 
                                                                                              displayTravelLeaderBackgroundRsiOrgidList?.includes(organizationId?.toString() as string) || 
                                                                                              displayTravelLeaderBackgroundRsiSiteidList?.includes(siteId?.toString() as string)),[account, items]);

  return isDataAvailable && !loading && !menuStore?.loading ? (
    <div className="travel-leader-rsi-upgrade">
      <Carousel {...propsCarousel} lazyLoad="ondemand">
        {travelLeader.map((item: ITravelLeader, i: number) => {
          const image = `${TRAVEL_LEADERS_DOMAIN}${item.fileName}`;
          const backgroundImage = displayTravelLeadersBackground ? `url(${image})` : `linear-gradient(rgba(32, 39, 77, 0.3), rgba(32, 39, 77, 0.3)),url(${image})`;

          return (
            <div className="travel-leader-rsi-upgrade__carousel-wrapper" key={i + item.promotionId}>
              <div className="travel-leader-rsi-upgrade__info-wrapper">
                <div
                  className="travel-leader-rsi-upgrade__info-title"
                  dangerouslySetInnerHTML={{ __html: item.headline }}
                />
                <Link
                  to={`/${account?.name}${Routes.PromotionDetails}/${item?.promotionId}`}
                  className="travel-leader-rsi-upgrade__info-link"
                >
                  <BlueButton>
                    <FormattedMessage id="view.details" />
                    <div style={{ backgroundImage: `url(${Chevrons})` }} className="image-chevrons" />
                  </BlueButton>
                </Link>
              </div>

              <div className="travel-leader-rsi-upgrade__image-wrapper" style={{ backgroundImage }}></div>
            </div>
          );
        })}
      </Carousel>
    </div>
  ) : null;
}
