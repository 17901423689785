import React from 'react';

import { connect } from 'react-redux';

import { CondoDetailsWrapper } from '@components';
import { Responsive } from '@share/utils';
import { scrollTop } from '@share/utils';

import './style.scss';

interface IProps {}

class CondoDetailPageComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    scrollTop();
  }

  render(): React.ReactNode {
    return (
      <div className="condo-details-page">
        <Responsive>
          <CondoDetailsWrapper />
        </Responsive>
      </div>
    );
  }
}

export const CondoDetailPage = connect()(CondoDetailPageComponent);
