import React, { Dispatch, SetStateAction, useState } from 'react';
import './style.scss';
import { Button, Checkbox, Dropdown, Form, Input, Menu, Select } from 'antd';
import { IFiltersManualBooking, ManualBookingData } from '../component';
import { ColumnsType } from 'antd/lib/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns } from '@fortawesome/free-solid-svg-icons';
import { useIntl } from 'react-intl';

interface IProps {
  filters: IFiltersManualBooking;
  setFilters: Dispatch<SetStateAction<IFiltersManualBooking>>;
  onSubmitSearch: () => void;
  onSubmitReset: () => void;
  columns: ColumnsType<ManualBookingData>;
  allColumns: ColumnsType<ManualBookingData>;
  setColumnsCheck: Dispatch<SetStateAction<any>>;
}

const ManualBookingFilters: React.FC<IProps> = (props: IProps) => {
  const { filters, setFilters, onSubmitSearch, onSubmitReset, columns, allColumns, setColumnsCheck } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters((prevFilters: IFiltersManualBooking) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSelectChange = (value: string) => {
    setFilters((prevFilters: IFiltersManualBooking) => ({
      ...prevFilters,
      paymentStatus: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmitSearch();
  };

  const handleReset = () => {
    setFilters((prevState: IFiltersManualBooking) => ({
      ...prevState,
      agentName: '',
      paymentStatus: '',
      bookingId: '',
    }));
    onSubmitReset();
  };

 const [selectedKeys, setSelectedKeys] = useState(columns.map((col) => col.key));

  const handleCheckboxChange = (key: string, checked: boolean) => {
    const updatedKeys = checked
      ? [...selectedKeys, key]
      : selectedKeys.filter((selectedKey) => selectedKey !== key);

    setSelectedKeys(updatedKeys);
    setColumnsCheck(allColumns.filter((col) => updatedKeys.includes(col.key)));
  };

  const handleResetColumns = (e: React.MouseEvent<HTMLButtonElement>) => {
    const defaultColumns = columns.map((col) => col.key);
    setSelectedKeys(defaultColumns);
    setColumnsCheck(columns);
    e.stopPropagation();
  };

  const handleSelectAllColumns = (e: React.MouseEvent<HTMLButtonElement>) => {
    const allColumnKeys = allColumns.map((col) => col.key);
    setSelectedKeys(allColumnKeys);
    setColumnsCheck(allColumns);
    e.stopPropagation();
  };

  
  const menu = (
    <Menu
      className="filters-form__booking-manual-menu-scroll"
      onClick={(e) => e.domEvent.stopPropagation()}
    >
      <Menu.Item key="actions" style={{ padding: 0 }}>
        <div style={{ display: 'flex', width: '100%' }}>
          <Button onClick={handleResetColumns} style={{ flex: 1, textAlign: 'center', margin: '5px 5px 10px 5px' }}>
            Reset
          </Button>
          <Button
            onClick={handleSelectAllColumns}
            style={{ flex: 1, textAlign: 'center', margin: '5px 0 10px 0' }}
          >
            Select All
          </Button>
        </div>
      </Menu.Item>

      {allColumns.map((col) => (
        <Menu.Item key={col.key}>
          <Checkbox
            checked={selectedKeys.includes(col.key)}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => handleCheckboxChange(col.key as string, e.target.checked)}
          >
            <span onClick={(e) => e.stopPropagation()}>{col.title}</span>
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );
  const intl = useIntl();

  return (
    <Form className="filters-form__booking-manual mt-4" onSubmitCapture={handleSubmit}>
      <Input
        type="text"
        name="agentName"
        value={filters.agentName}
        placeholder={intl.formatMessage({ id: 'bookingmanual.filters.agentName' })}
        onChange={handleChange}
        allowClear
        className="input"
      />
      <Select
        value={filters.paymentStatus || undefined}
        onChange={handleSelectChange}
        placeholder={intl.formatMessage({ id: 'bookingmanual.filters.paymentStatus' })}
        allowClear
      >
        <Select.Option value="Received">
          {intl.formatMessage({ id: 'bookingmanual.filters.paymentStatus.received' })}
        </Select.Option>
        <Select.Option value="Pending">
          {intl.formatMessage({ id: 'bookingmanual.filters.paymentStatus.pending' })}
        </Select.Option>
      </Select>
      <Input
        type="text"
        name="bookingId"
        value={filters.bookingId}
        placeholder={intl.formatMessage({ id: 'bookingmanual.filters.bookingId' })}
        onChange={handleChange}
        allowClear
        className="input"
      />
      <Button type="primary" htmlType="submit">
        {intl.formatMessage({ id: 'search' })}
      </Button>
      <Button htmlType="button" onClick={handleReset}>
        {intl.formatMessage({ id: 'reset' })}
      </Button>

      <div>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button icon={<FontAwesomeIcon icon={faColumns} size="1x" />} />
        </Dropdown>
      </div>
    </Form>
  );
};

export default ManualBookingFilters;
