import React from 'react';
import isNumber from 'lodash/isNumber';

import { FormattedMessage } from 'react-intl';

import { IBenefits, IBenefitsItems, IRange, ISessionKey } from '@share/common-types';
import { MAX_AMOUNT_PERCENT, MAX_PRICE, MIN_AMOUNT, PERCENT_TYPE } from '@share/constants';
import { isEmpty } from 'lodash';

const defaultLocale = 'en-US';
const ZERO = 0;
const ONE = 1;
const TWO = 2;
const THREE = 3;
const FOUR = 4;
const FIVE = 5;
const SIX = 6;
const TEN = 10;
const FIFTEEN = 15;
const CURRENCY_STYLE = 'currency';
const CURRENCY = 'USD';
const ONE_HUNDRED = 100;
const PROTOCOL = 'http';
const DOUBLE_SLASH = '//';
const DOT_COM = '.com';

export const renderNumber = (num: number | null | undefined, fraction = 0): string => {
  if (!isNumber(num)) {
    return '';
  }

  return num.toLocaleString(defaultLocale, { maximumFractionDigits: fraction, minimumFractionDigits: fraction });
};

export const getRankingClass = (ranking: number): string => {
  if (!ranking) {
    return '';
  }
  
  if (ranking > 4) {
    return 'good-review';
  } else if (ranking <= 4 && ranking > 2.5) {
    return 'average-review';
  }
  return 'bad-review';
}

export const renderPhone = (phone: string): string => {
  if (!phone) {
    return '';
  }

  return phone.slice(ZERO, THREE) + '-' + phone.slice(THREE, SIX) + '-' + phone.slice(SIX, FIFTEEN);
};

export const renderPhoneWithCode = (phone: string): string => {
  if (!phone) {
    return '';
  }

  const getNumber = phone.replace(/\D/g, '');
  const phoneNumber = getNumber.substring(getNumber.length - TEN);
  const phoneCode = getNumber.slice(ZERO, -TEN);

  if (!phoneCode) {
    return renderPhone(phoneNumber);
  }
  return `${phoneCode}-${renderPhone(phoneNumber)}`;
};

export const hideNumbers = (str: string, matches: number[]): string => {
  let count = 0;
  return str ? str.toString().replace(/\d/g, (d) => (matches.includes(++count) ? '*' : d)) : '';
};

export const insertSpacesInNumber = (value: string) => {
  return value?.match(/\d{4}(?=\d{1,4})|\d+/g);
};

export const insertSpacesInPhone = (value: string) => {
  return value?.match(/\d{3}(?=\d{2,3})|\d+/g);
};

export const insertSpacesInDate = (date: string): string[] => {
  // @ts-ignore
  const value = date?.replaceAll('/', '');

  if (value?.length > 2) {
    if (value?.length <= 4) {
      return [value.substring(0, 2), value.substring(2)];
    } else if (value?.length > 4) {
      return [value.substring(0, 2), value.substring(2, 4), value.substring(4, 8)];
    }
  }

  return [value];
};

export const checkForEmptyCharacter = (value: string): string => {
  return value?.replace(/ /gm, '');
};

export const creatingArrayWithNumber = (arrayLength: number, minNumber: number): number[] => {
  return [...new Array(arrayLength)].map((_, index) => minNumber + index);
};

export const checkInLatinLetters = new RegExp(/^[\p{L}\p{M}\p{Z}\-']*$/u);

export const checkInLatinLettersMinimun2Characters = new RegExp(/^[a-zA-Z ]{2,}$/i);

export const checkSpecialCharacters = new RegExp(/^[\p{L}\p{M}\p{Z}\-']{2,}$/u);

export const formatMoney = new Intl.NumberFormat(defaultLocale, {
  style: CURRENCY_STYLE,
  currency: CURRENCY,
});

export const formatMoneyCurrency = (currency?: string) => {
  return new Intl.NumberFormat(defaultLocale, {
    style: CURRENCY_STYLE,
    currency: !isEmpty(currency) ? currency : CURRENCY,
  })
};

export const formatRate = (score: number): string => {
  return renderNumber((FIVE * score) / MAX_AMOUNT_PERCENT, ONE);
};

export const mailFormat =
  /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
export const onlyNumsRegExp = /^\d+$/;
export const onlyLettersNumsRegExp = /^[a-zA-Z0-9]+$/;

export const getSessionObj = (
  roomsSession: ISessionKey,
  searchSession: ISessionKey,
): ISessionKey => {
  return roomsSession && roomsSession.value ? roomsSession : searchSession;
};

export const splitText = /\.(?=$|\s+)/;

export const capitalLettersOfEachWord = (value: string): string => {
  return value
    .split('-')
    .map((word) => word[ZERO].toUpperCase() + word.substring(ONE))
    .join(' ');
};

export const receiptOfAmount = (value: number, viewMarkup: string) => {
  const isPercent = viewMarkup === PERCENT_TYPE;

  if (value < MIN_AMOUNT) {
    return MIN_AMOUNT;
  }
  if (isPercent && value > MAX_AMOUNT_PERCENT) {
    return MAX_AMOUNT_PERCENT;
  }
  if (!isPercent && value > MAX_PRICE) {
    return MAX_PRICE;
  }
  if (isPercent && value) {
    return Math.floor(value * ONE_HUNDRED) / ONE_HUNDRED;
  }
  if (value) {
    return value;
  }
};

export const compare = (a: number, b: number): number => {
  return a - b;
};

export const getBudgetRangeLabel = (range: IRange): React.ReactNode => {
  const { from, to } = range;

  if (from && to) {
    return (
      <span>
        ${from} <FormattedMessage id="to" /> ${to}
      </span>
    );
  }

  if (!from && to) {
    return (
      <span>
        <FormattedMessage id="less.than" /> ${to}
      </span>
    );
  }

  if (from && !to) {
    return (
      <span>
        <FormattedMessage id="greater.than" /> ${from}
      </span>
    );
  }
};

export const getTextWithBrakes = (text: string): React.ReactElement => {
  const rows = text.split('\n');

  return (
    <>
      {rows.map((row: string, i: number) => {
        if (i === ZERO) {
          return <span key={i}>{row}</span>;
        }

        return (
          <span key={i}>
            <br />
            {row}
          </span>
        );
      })}
    </>
  );
};

export const scrollTop = (): void => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.scrollTo(0, 0);
};

export const getFullUnitNameWithOutId = (name: string): string => {
  const fullName = name.replace(/[^ ]+ /, '');
  const fullNameSplit = fullName.split(',');
  return fullNameSplit.length > 3 ? fullNameSplit.slice(0, 3).join(', ') : fullNameSplit.join(', ');
};

export const getStringrWithoutTags = (string: string): string => {
  return string.replace(/<\/?[^>]+>/g, '');
};

export const removeVariables = (string: string): string => {
  return string.replace(/[\/?[a-zA-Z]+]/gi, '').replace('\n', '');
};

export const getFullUrl = (baseUrl: string, path: string): string => {
  const pathWithoutSlash = path.replace(/^\//, '');
  const getCorrectDomain =
    baseUrl.substr(ZERO, TWO) === DOUBLE_SLASH ? baseUrl : `${DOUBLE_SLASH}${baseUrl}`;

  if (path.substr(ZERO, FOUR) === PROTOCOL || path.includes(DOT_COM)) {
    return path;
  } else if (getCorrectDomain.substr(-ONE) === '/') {
    return `${getCorrectDomain}${pathWithoutSlash}`;
  } else {
    return `${getCorrectDomain}/${pathWithoutSlash}`;
  }
};

export const isExternalUrl = (url: string): boolean => !isLocalUrl(url);

export const isLocalUrl = (url: string): boolean => {
  let urlObject;
  try {
    urlObject = new URL(url);
  } catch(e) {}
  return !!url && (url.indexOf('//') === -1 || (url.indexOf(location.host) >= 0 && urlObject?.hostname === location.host));
};

export const getLocalURI = (url: string): string => {
  const host = location.host;
  return isLocalUrl(url)? 
         url.indexOf(location.host) >= 0 ? 
          url.slice(url.indexOf(host) + host.length) : url : url;
};

export const getZendeskChat = (rsiId: number): React.ReactNode => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `window.zESettings = {
          webWidget: {
            authenticate: {
              chat: {
                jwtFn: function(callback) {
                  fetch('https://myvcaccount.com/JWT/home/getJWTtokenHours/${rsiId}').then(function(res) {
                    res.text().then(function(jwt) {
                      callback(jwt);
                    });
                  });
                }
              }
            }
         }
        }`;

  return document.body.appendChild(script);
};

export const getBenefitInfo = (benefits: IBenefits, type: string): IBenefitsItems[] => {
  return benefits?.items.filter((item) => item.type === type);
};

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const insertSpacesAndPluralize = (text: string) => {
  if(text) {
    text = text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(':', 's:');
  }
  return text;
}

export const getWalletSliderMaxValuePosition = (value: number) => {
  if (value) {
    const strValue = value.toString();
    const length = strValue.length;
    if (length === 7) {
      return '-50';
    } else if (length === 6) {
      return '-44';
    } else if (length === 5) {
      return '-36';
    } else if (length === 4) {
      return '-27';
    } else if (length === 3) {
      return '-20';
    } else if (length === 2) {
      return '-11';
    } else if (length === 1) {
      return '-4';
    } else {
      return '-60';
    }
  }

  return '-20';
}

export type CallbackFunction = () => void;

export const GetNumber = (numberStr: string) => {
  try {
    if (!isEmpty(numberStr)) {
      return parseInt(numberStr);
    }
  } catch(e) {}
  return 0;
}