import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty } from 'lodash';
import { Tooltip } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Currency, WalletInput, WalletMessage } from '@components';
import { IAccount, ICarDetails, ICarExta, IClientCash } from '@share/common-types';
import { GetWalletNumber, RootState, renderNumber } from '@share/utils';
import { ICarsState, ILoginState, IMarginatorState, IMenuState, carsActions } from '@share/store/slices';
import { GetCarDetails, GetCarDetailsWalletPrice, GetDetailsPickUpPrices, WalletPriceType } from '@utils';
import { BlueButton } from '@share/components';
import { ICarsReviewBookState, resetCreditCard, setSelectedCarsReviewClientCash, setSelectedCarsReviewClientCashStr } from '@store/slices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { CarsExtras } from '../cars-extras';

import './style.scss';
import { NULL_VALUE } from '@constants';

interface IMapStateToProps {
  marginatorStore: IMarginatorState;
  loginStore: ILoginState;
  menuStore: IMenuState;

  carsStore: ICarsState;
  carsReviewBookStore: ICarsReviewBookState;
}

interface IMapDispatchToProps {
  setSelectedCarsReviewClientCash: (clientCash: number) => void;
  setSelectedCarsReviewClientCashStr: (clientCash: string) => void;
  setSelectedCarsSearchClientCash: (clientCash: IClientCash) => void;

  resetCreditCard: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  car: ICarDetails;
  selectedExtras: ICarExta[];

  isMobile?: boolean;
  isCheckout?: boolean;
  hideReserve?: boolean;

  onReserve: () => void;
}

interface IState {
  displayCheckoutExtras: boolean;
}

const zero = 0;
const PURPOSE_TO_CHECK = ['2', '8', '3', '4'];

class CarsPriceDetailsComponent extends React.Component<IProps, IState> {
  state: IState = { displayCheckoutExtras: false };

  handleReserve = (walletPrices: any) => {
    const { loginStore, carsStore } = this.props;
    const { account } = loginStore;

    const { selectedCarsSearchClientCash } = carsStore;
  
    const displayWalletSavings = account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && walletPrices?.maxClientCashAmountAllow;

    const selectedPropertyClientCash = displayWalletSavings ?  walletPrices?.maxClientCashPermited : selectedCarsSearchClientCash?.selectedPropertyClientCash;
    const selectedPropertyClientCashFinal = selectedPropertyClientCash ?  selectedPropertyClientCash : 0;

    this.props.setSelectedCarsReviewClientCash(selectedPropertyClientCashFinal);
    this.props.setSelectedCarsReviewClientCashStr(selectedPropertyClientCashFinal?.toString());
    this.props.setSelectedCarsSearchClientCash({ ...selectedCarsSearchClientCash, selectedPropertyReviewClientCash: selectedPropertyClientCashFinal });
    this.props.resetCreditCard();
    
    this.props.onReserve();
  }

  handleOnSelectClientCash = (clientCash: string) => {
    const { carsStore } = this.props;
    const { selectedCarsSearchClientCash } = carsStore;

    const clientCashNumber = clientCash ? Number(clientCash) : null;
    this.props.setSelectedCarsReviewClientCash(clientCashNumber as number);
    this.props.setSelectedCarsReviewClientCashStr(clientCash?.toString());
    this.props.setSelectedCarsSearchClientCash({ ...selectedCarsSearchClientCash, selectedPropertyReviewClientCash: clientCashNumber as number });
  }

  render(): React.ReactNode {
    const { car, selectedExtras, isMobile, hideReserve, isCheckout, marginatorStore, loginStore, carsStore, menuStore, carsReviewBookStore } = this.props;
    const { displayCheckoutExtras } = this.state;

    const { value } = marginatorStore;
    const { selectedCarsSearchClientCash } = carsStore;
    const { items } = menuStore;
    const { bookingComplete, selectedCarsReviewClientCash, selectedCarsReviewClientCashStr, booking } = carsReviewBookStore;

    const{ account, userWalletData, userWallet } = loginStore;

    const isCapitalVacations = account?.isCapitalVacations;
    const hasClientCash = account?.hasClientCash;
    const isLogged = !!userWallet;

    const isMLM = items?.isMLM;

    const { days, pricePerDay, maxWalletClientCash, charges, notCovered, currency, payNowPrice, price, savings, taxes, pubPrice, totalLeisureCredits } = GetCarDetails(car, isCapitalVacations as boolean);

    const { pickUpPrices, pickUpPrice, hasAllChargesSameCurrency, expraList } = GetDetailsPickUpPrices(selectedExtras, notCovered, currency);

    const marginatorPrice = bookingComplete ? booking?.payNowPrice : payNowPrice / (1 - (value / 100));
    const marginatorPriceCapital = bookingComplete ? (booking?.price - (taxes ? taxes : 0)) : price / (1 - (value / 100));

    const { displayWalletSavings, walletPrices, marginatorPriceFinal, payNowMarginatorPriceFinal, maxWalletClientCashCalculated } = GetCarDetailsWalletPrice(account, userWalletData, marginatorPrice, maxWalletClientCash, marginatorPriceCapital, selectedCarsSearchClientCash, isCheckout as boolean, isMLM as boolean);

    return (
      <>
        <div className={`cars-details-wrapper__price-container`}>
          <div className="hotel-info cars-price">
            <div className={`hotel-info__item ${isMobile ? 'border-top' : ''}`}>
              <div className="hotel-info__item-content">
                <h4 className="hotel-info__title">
                  <FormattedMessage id="price.details" />
                </h4>

                {(pubPrice > zero && !displayWalletSavings && !isCapitalVacations) ? (
                  <div className="cars-price__price-container">
                    <div className="cars-price__price-description">
                        <FormattedMessage id={account?.forceMemberWording ? 'result.hotel.card.public.rate' : 'result.hotel.card.public'} />
                    </div>
                    <div className="cars-price__price">
                      <Currency currency={currency} />{renderNumber(pubPrice, 2)}
                    </div>
                  </div>) : null}

                {(!!savings && ((!displayWalletSavings && !isCapitalVacations) || isMLM)) && (
                  <div className="cars-price__price-container">
                    <div className="cars-price__price-description room-info__price-savings-text">
                      <FormattedMessage id={items?.isPromoSite ? 'points' : items?.promo ? 'savings' : 'member.savings.label'} />
                    </div>
                    <div className="cars-price__price room-info__price-savings-text">
                      -<Currency currency={currency} />{renderNumber(savings, 2)}
                    </div>
                  </div>)}

                {(displayWalletSavings && !bookingComplete) ? (
                  <div className="cars-price__price-container">
                    <div className="cars-price__price-description room-info__price-savings-text">
                      <FormattedMessage id="wallet.save.up.to" values={{ clientCashName: account?.walletClientCashName }} />
                    </div>
                    <div className="cars-price__price room-info__price-savings-text">
                      {GetWalletNumber(walletPrices?.clientCashAmountApplied, account as IAccount)}
                    </div>
                  </div>) : null}

                  {(!!totalLeisureCredits && items?.pointsSettings?.car?.isRemainingBalanceEnabled && !account?.isMLM) ? (
                    <div className="cars-price__price-container">
                      <div className="cars-price__price-description room-info__price-credits-text"><FormattedMessage id="leisure.credits.balance" /></div>
                      <div className="cars-price__price room-info__price-credits-text">{totalLeisureCredits}</div>
                    </div>) : null}

                <label className="cars-price__message-title secondary-font">
                  <FormattedMessage id="cars.price.pay_now" />
                </label>

                <div className="cars-price__price-container">
                  <div className="cars-price__price-description">
                    <FormattedMessage id="car.price" values={{ days: days, count: days }} />
                    <br />
                    <label style={{ fontSize: '12px' }}><FormattedMessage id="car.price.sub_message" values={{ value: <><Currency currency={currency} />{renderNumber(pricePerDay)}</>, count: days }} /></label>
                  </div>
                  <div className="cars-price__price"><Currency currency={currency} />{renderNumber(marginatorPriceFinal, 2)}</div>
                </div>

                <div className="cars-price__price-container">
                  <div className="cars-price__price-description"><FormattedMessage id="cars.price.tax_fees" /></div>
                  <div className="cars-price__price">
                    {taxes ? (
                      <>
                        <Currency currency={currency} />{renderNumber(taxes, 2)}
                      </>) : (
                      <FormattedMessage id="cars.price.tax.included" />)}
                  </div>
                </div>

                {((hasClientCash && isLogged) && isCheckout) ? (
                  <WalletInput
                    display={!bookingComplete}
                    currency={currency}
                    maxWalletClientCash={maxWalletClientCashCalculated}
                    price={marginatorPrice}
                    selectedClientCash={selectedCarsReviewClientCash}
                    selectedClientCashStr={selectedCarsReviewClientCashStr}
                    onSelectClientCash={this.handleOnSelectClientCash}
                  />) : null}

                {(hasClientCash && isLogged && !displayWalletSavings && isCheckout) ? (
                  <WalletMessage
                    price={bookingComplete ? booking?.price : marginatorPrice}
                    maxClientCash={maxWalletClientCashCalculated}
                    currency={currency}
                    type={WalletPriceType.Book}
                    isComplete={bookingComplete}
                    clientCashAppliedComplete={booking?.clientCashConsumed}
                    style={{ paddingLeft: '15px', paddingRight: '15px', marginBottom: '15px' }}
                    isOneLine
                    isCars
                  />) : null}

                {(hasClientCash && isLogged && !isCheckout) ? (
                  <WalletMessage
                    price={marginatorPrice}
                    maxClientCash={maxWalletClientCashCalculated}
                    currency={currency}
                    type={WalletPriceType.Details}
                    isOneLine
                    hideApplied={displayWalletSavings}
                    greenText={displayWalletSavings}
                    style={{ paddingLeft: '15px', paddingRight: '15px', marginBottom: '15px' }}
                    isCars
                  />) : null}

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingTop: '25px', borderTop: '1px solid var(--border-grey)' }}>
                  <label className="cars-price__message-title secondary-font">
                    <FormattedMessage id="cars.price.total" />
                  </label>
                  {(hasAllChargesSameCurrency) ? (
                    <div className="cars-price__message-title secondary-font">
                      <Currency currency={currency} />{renderNumber(payNowMarginatorPriceFinal + pickUpPrice, 2)}
                    </div>) : null}
                </div>

                <div className="cars-price__price-container">
                  <div className="cars-price__price-description"><FormattedMessage id="cars.price.pay_now" /></div>
                  <div className="cars-price__price"><Currency currency={currency} />{renderNumber(payNowMarginatorPriceFinal, 2)}</div>
                </div>

                {pickUpPrices?.length ? (
                  pickUpPrices.map((p, id) => {
                    const pickUpPrice: number = p.reduce((acc: any, curr: any) => acc + (curr.charge ? curr.charge.amount : curr.amount), 0);
                    const currency = get(p, '[0].charge.currencyCode', get(p, '[0].currencyCode', 'USD'));

                    return (
                      <div className="cars-price__price-container" key={`extrars_pp_${id}`}>
                        <div className="cars-price__price-description"><FormattedMessage id="cars.price.pay_pickup" /></div>
                        <div className="cars-price__price"><Currency currency={currency} />{renderNumber(pickUpPrice, 2)}</div>
                      </div>);
                  })) : null}

                {!hideReserve ? (<BlueButton onClick={() => this.handleReserve(walletPrices)}><FormattedMessage id="reserve" /></BlueButton>) : null}

                {pickUpPrices?.length ? (
                  <div className="cars-price__price-container" style={{ textAlign: 'center', padding: '0px 15px', marginBottom: '0px', marginTop: '15px' }}>
                    <FormattedMessage id="cars.pick.local.currency" />
                  </div>) : null}

              </div>
            </div>
          </div>
        </div>

        <div className={`cars-details-wrapper__price-container`} style={{ marginTop: '20px' }}>
          <div className="hotel-info cars-price">
            <div className={`hotel-info__item ${isMobile ? 'border-top' : ''}`}>
              <div className="hotel-info__item-content">

                <label className="cars-price__message-title secondary-font" style={{ width: '100%' }}>
                  <FormattedMessage id="cars.price.pay_pickup.details" />
                </label>

                {notCovered?.length ?
                  notCovered.map((c: any, i: number) => (
                    <div key={`ncvrd_${i}`} className="cars-price__price-container">
                      <div className="cars-price__price-description">{c.description?.replace(/(\().+?(\))/g, '').trim()}</div>
                      <div className="cars-price__price">
                        <Currency currency={currency} />{renderNumber(c.amount, 2)}
                      </div>
                    </div>)) : null}

                {expraList?.length ? (
                  <>
                    {expraList.map((e: any, index: number) => {
                      const expraPrice = e?.length ? e.reduce((acc: any, curr: any) => acc + curr.charge.amount, 0) : 0;
                      const currency = get(e, '[0].charge.currencyCode', 'USD');

                      if (!expraPrice) {
                        return null;
                      }

                      return (
                        <div className="cars-price__price-container" key={`extras_${index}`}>
                          <div className="cars-price__price-description" style={{ flexDirection: 'row' }}>
                            <FormattedMessage id="cars.extras.selected" />
                            {' '}
                            {isCheckout ? (
                              <div style={{ cursor: 'pointer', marginLeft: '7px', color: 'blue' }} onClick={() => this.setState({ displayCheckoutExtras: !displayCheckoutExtras })}>
                                <FontAwesomeIcon icon={displayCheckoutExtras ? faChevronDown : faChevronUp} />
                              </div>): null}
                          </div>
                          <div className="cars-price__price"><Currency currency={currency} />{renderNumber(expraPrice, 2)}</div>
                        </div>);
                    })}

                    <CarsExtras display={isCheckout && displayCheckoutExtras} extrasRef={NULL_VALUE} viewOnly hideMessage hideTitle />
                  </>) : null}

                {charges?.length ?
                  charges.map((c: any, i: number) => (
                    <div key={`charges_${i}`} className="cars-price__price-container">
                      <div className="cars-price__price-description" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {PURPOSE_TO_CHECK.includes(c.purpose) ? c.description?.replace(/(\().+?(\))/g, '').trim() : c.purpose?.replace(/(\().+?(\))/g, '').trim()}{' '}{(!PURPOSE_TO_CHECK.includes(c.purpose)  && !isEmpty(c?.description)) ? (<Tooltip title={c?.description}><FontAwesomeIcon icon={faCircleInfo} style={{ marginLeft: '8px' }} /></Tooltip>) : null}
                      </div>
                      <div className="cars-price__price">
                        <Currency currency={c.currencyCode} />{renderNumber(c.amount, 2)}
                      </div>
                    </div>)) : null}

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    marginatorStore: state.marginatorStore,
    carsStore: state.carsStore,
    carsReviewBookStore: state.carsReviewBookStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedCarsReviewClientCash: setSelectedCarsReviewClientCash,
  setSelectedCarsReviewClientCashStr: setSelectedCarsReviewClientCashStr,
  setSelectedCarsSearchClientCash: carsActions.setSelectedCarsSearchClientCash,
  resetCreditCard,
};

export const CarsPriceDetails = connect(mapStateToProps, mapDispatchToProps)(withRouter(CarsPriceDetailsComponent));

