import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Card, Select, Collapse, TablePaginationConfig, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { bookingManualSliceActions, getBookingManual, IBookingManualState, postBookingManual } from '../../../../../share/store/slices/booking-manual';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { ILoginState, IMenuState } from '@share/store/slices';
import { FunctionalRootState } from '@share/utils';
import { useIntl } from 'react-intl';
import { IFiltersManualBooking } from '../component';

import './style.scss';

const { Option } = Select;

interface IProps {
  bookingManualStore: IBookingManualState;
  pagination: TablePaginationConfig;
  agentID: number;
  filters: IFiltersManualBooking;
  sort: any;
}

const ManualBookingForm: React.FC<IProps> = (props: IProps) => {
  const { bookingManualStore, pagination, agentID, filters, sort } = props;
  const { postBookingManualError, postBookingManualLoading, postBookingManual: postBookingManualRes } = bookingManualStore;
  const [collapsed, setCollapsed] = useState(true);
  const { items }: IMenuState = useSelector(
    (state: FunctionalRootState) => state.navigationMenuStore,
  );
  const { user }: ILoginState = useSelector(
    (state: FunctionalRootState) => state.loginStore,
  );
  const [form] = Form.useForm();
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch();

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files;
    if (selectedFile) {
      setFile(selectedFile[0]);
    }
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleSubmit = (values: any) => {
    const formData = new FormData();

    // Format dates before appending
    const formattedValues = {
      ...values,
      travelDateStart: values.travelDateStart ? values.travelDateStart.format('YYYY-MM-DD') : null,
      travelDateEnd: values.travelDateEnd ? values.travelDateEnd.format('YYYY-MM-DD') : null,
      bookingDate: values.bookingDate ? values.travelDateEnd.format('YYYY-MM-DD') : null,
      dateOfSubmission: values.dateOfSubmission ? values.travelDateEnd.format('YYYY-MM-DD') : null
    };

    Object.entries(formattedValues).forEach(([key, value]) => {
      if (key !== 'file' && value !== null) {
        formData.append(key, value as string);
      }
    });

    if (file) {
      formData.append('file', file);
    }

    dispatch(postBookingManual(formData, () =>
      dispatch(getBookingManual(pagination, agentID, sort, filters))
    ));
  };


  const handleNumberChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;

    if (
      !/^([a-zA-Z0-9.]+)$/.test(key) && // Allow only numbers
      key !== 'Backspace' && // Allows delete numbers
      key !== 'Tab' && // Allows tab
      key !== 'ArrowLeft' && // Allows left arrow
      key !== 'ArrowRight' && // Allows right arrow
      key !== 'Delete' && // Allows delete key
      !(e.ctrlKey && (key === 'c' || key === 'v' || key === 'x' || key === 'a')) // Allows copy (Ctrl+C), paste (Ctrl+V), cut (Ctrl+X), and select all (Ctrl+A)
    ) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = e.clipboardData.getData('text');
    if (!/^\d*\.?\d*$/.test(clipboardData)) {
      // Only allow numeric values with optional decimal point
      e.preventDefault();
    }
  };

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (
      !postBookingManualLoading &&
      postBookingManualRes?.success
    ) {
      toast.success('Booking manual submitted successfully!');
      handleReset(); // Clean the form
      setCollapsed(true);
    } else if (postBookingManualError) {
      toast.error(postBookingManualError);
      dispatch(bookingManualSliceActions.setPostManualBookingError(''));
    }
  }, [postBookingManual, postBookingManualLoading]);

  const { formatMessage } = useIntl();

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      {collapsed && (
        <Button type="primary" onClick={() => setCollapsed(false)} icon={<DownOutlined />}>
          {formatMessage({ id: 'bookingmanual.addBookingManual' })}
        </Button>
      )}

      {!collapsed && (
        <Card
          bordered={true}
          style={{
            marginTop: '16px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
          }}
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{formatMessage({ id: 'bookingmanual.formTitle' })}</span>
              <Button type="primary" onClick={toggleCollapse} icon={<UpOutlined />}>
                {formatMessage({ id: 'bookingmanual.collapse' })}
              </Button>
            </div>
          }
        >
          <Collapse defaultActiveKey={['1']} bordered={false}>
            <Collapse.Panel key="1" header={null} showArrow={false}>
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                  agentName: `${items?.firstName} ${items?.lastName}`,
                  agentID: `${items?.rsiId}`,
                  contactInformation: `${user?.email}`,
                }}
                style={{ padding: '20px' }}
                autoComplete="off"
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.agentName' })}
                      name="agentName"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.agentName.error' }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({ id: 'bookingmanual.agentName.placeholder' })}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.agentId' })}
                      name="agentID"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.agentId.error' }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({ id: 'bookingmanual.agentId.placeholder' })}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.contactInfo' })}
                      name="contactInformation"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.contactInfo.error' }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({ id: 'bookingmanual.contactInfo.placeholder' })}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.bookingId' })}
                      name="bookingId"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.bookingId.error' }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({ id: 'bookingmanual.bookingId.placeholder' })}
                        onKeyDown={handleNumberChange}
                        onPaste={handlePaste}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.bookingDate' })}
                      name="bookingDate"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.bookingDate.error' }),
                        },
                      ]}
                    >
                       <DatePicker
                        format="MM/DD/YYYY"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.bookingType' })}
                      name="bookingType"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.bookingType.error' }),
                        },
                      ]}
                    >
                      <Select
                        placeholder={formatMessage({ id: 'bookingmanual.bookingType.placeholder' })}
                      >
                        <Option value="Hotel">Hotel</Option>
                        <Option value="Condo">Condo</Option>
                        <Option value="Car">Car</Option>
                        <Option value="Flight">Flight</Option>
                        <Option value="Cruise">Cruise</Option>
                        <Option value="Vacation Package">Vacation Package</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.travelDateStart' })}
                      name="travelDateStart"
                      dependencies={['travelDateEnd']}
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.travelDateStart.error' }),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const endDate = getFieldValue('travelDateEnd');
                            if (!value || (endDate && value.isAfter(endDate, 'day'))) {
                              return Promise.reject(
                                new Error(formatMessage({ id: 'bookingmanual.travelStartDate.invalid' }))
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        format="MM/DD/YYYY"
                        style={{ width: '100%' }}
                        onChange={() => form.validateFields(['travelDateStart', 'travelDateEnd'])} // Revalidate both fields
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.travelEndDate' })}
                      name="travelDateEnd"
                      dependencies={['travelDateStart']}
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.travelEndDate.error' }),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const startDate = getFieldValue('travelDateStart');
                            if (!value || value.isSameOrAfter(startDate, 'day')) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(formatMessage({ id: 'bookingmanual.travelEndDate.invalid' }))
                            );
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        format="MM/DD/YYYY"
                        style={{ width: '100%' }}
                        onChange={() => form.validateFields(['travelDateStart', 'travelDateEnd'])} // Revalidate both fields
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.dateOfSubmission' })}
                      name="dateOfSubmission"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.dateOfSubmission.error' }),
                        },
                      ]}
                    >
                      <DatePicker
                        format="MM/DD/YYYY"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.destination' })}
                      name="destination"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.destination.error' }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({ id: 'bookingmanual.destination.placeholder' })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.clientName' })}
                      name="clientName"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.clientName.error' }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({ id: 'bookingmanual.clientName.placeholder' })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.serviceProviderName' })}
                      name="serviceProviderName"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.serviceProviderName.error' }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({
                          id: 'bookingmanual.serviceProviderName.placeholder',
                        })}
                      />
                    </Form.Item>
                  </Col>

                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.serviceConfirmationNumber' })}
                      name="serviceConfirmationNumber"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: 'bookingmanual.serviceConfirmationNumber.error',
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({
                          id: 'bookingmanual.serviceConfirmationNumber.placeholder',
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.numberOfTravelers' })}
                      name="numberOfTravelers"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.numberOfTravelers.error' }),
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder={formatMessage({
                          id: 'bookingmanual.numberOfTravelers.placeholder',
                        })}
                        onKeyDown={handleNumberChange}
                        onPaste={handlePaste}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.agentSignature' })}
                      name="agentSignature"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.agentSignature.error' }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({
                          id: 'bookingmanual.agentSignature.placeholder',
                        })}
                      />
                    </Form.Item>
                  </Col>

                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.totalBookingAmount' })}
                      name="totalBookingAmount"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.totalBookingAmount.error' }),
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder={formatMessage({
                          id: 'bookingmanual.totalBookingAmount.placeholder',
                        })}
                        onKeyDown={handleNumberChange}
                        onPaste={handlePaste}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.totalCommission' })}
                      name="totalCommission"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.totalCommission.error' }),
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder={formatMessage({
                          id: 'bookingmanual.totalCommission.placeholder',
                        })}
                        onKeyDown={handleNumberChange}
                        onPaste={handlePaste}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.depositPaid' })}
                      name="depositPaid"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.depositPaid.error' }),
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder={formatMessage({ id: 'bookingmanual.depositPaid.placeholder' })}
                      />
                    </Form.Item>
                  </Col>

                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.paymentStatus' })}
                      name="paymentStatus"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.paymentStatus.error' }),
                        },
                      ]}
                    >
                      <Select
                        placeholder={formatMessage({
                          id: 'bookingmanual.paymentStatus.placeholder',
                        })}
                      >
                        <Option value="Received">
                          {formatMessage({ id: 'bookingmanual.paymentStatus.received' })}
                        </Option>
                        <Option value="Pending">
                          {formatMessage({ id: 'bookingmanual.paymentStatus.pending' })}
                        </Option>
                        <Option value="Due">
                          {formatMessage({ id: 'bookingmanual.paymentStatus.due' })}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.refundsOrAdjustments' })}
                      name="refundsOrAdjustments"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: 'bookingmanual.refundsOrAdjustments.error',
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({
                          id: 'bookingmanual.refundsOrAdjustments.placeholder',
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.detailsOfServices' })}
                      name="detailsOfServices"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.detailsOfServices.error' }),
                        },
                      ]}
                    >
                       <Input
                        placeholder={formatMessage({
                          id: 'bookingmanual.detailsOfServices.placeholder',
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={formatMessage({ id: 'bookingmanual.uploadFile' })}
                      name="file"
                      valuePropName="fileList"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'bookingmanual.uploadFile.error' }),
                        },
                      ]}
                    >
                      <Input name="file" type='file' onChange={handleFile} />


                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={postBookingManualLoading} style={{ width: '100%' }}>
                    {formatMessage({ id: 'bookingmanual.submit' })}
                  </Button>
                </Form.Item>
              </Form>
            </Collapse.Panel>
          </Collapse>
        </Card>
      )}
    </div>
  );
};

export default ManualBookingForm;
