import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Checkbox, Form, Spin } from 'antd';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { get, isEmpty } from 'lodash';
import { v4 } from 'uuid';

import {
  ICouponState,
  IReviewBookState,
  IInsuranceState,
  InsuranceSelection,
  IWeeksReviewBookState,
  getWeeksBookPackageWithCard,
  weeksReviewBookAction,
} from '@store/slices';
import { GetWalletNumber, RootState, getSelectedCurrency } from '@share/utils';
import { IMenuState, getNavigationMenu, setLoginRedirect } from '@share/store/slices';
import {
  GetPreferenceValue,
  MERCHANT_CHARGE_DESCRIPTION_FIELD_KEY,
  getCondoId,
  getMemberPhone,
  getWeeksAvailabilityId,
  removingDashFromString,
  updateWeeksGuest,
} from '@utils';
import { getSessionObj, renderNumber, getFullUrl, UrlUtils, LoginType } from '@share/utils';
import { BlueButton, Loading } from '@share/components';
import {
  CONDO_SESSION_KEY,
  C_C_COMPLETE_BOOKING,
  C_PP,
  C_TERMS,
  Routes,
  QUOTE_LABEL,
  SESSION_KEY_TOKEN_NAME_PARAM,
  LIFE_STYLE_NAME_PARAM,
  WEEKS_UNITS_SESSION_KEY_LABEL
} from '@share/constants';
import { ICondoGuestBooking, ICondoBookPackageWithCard, IFormErrors, ScrollBehaviorEnum } from '@common-types';
import { countriesCode } from '@share/constants';
import { BookingErrorsEnum, IAccount, INavigation } from '@share/common-types';
import { ISessionKey } from '@share/common-types';
import { ILoginState } from '@share/store/slices';
import { CondoAvailabilityStatusModal, ModalMessage, ModalConfirmation, ThreeDSModal, Insurance, InsuranceRequiredMessage } from '@components';
import { NON_REFUNDABLE_TYPE } from '@constants';

import { WeeksGuestInfo } from '../weeks-guest-info';
import { WeeksPaymentMethod } from '../weeks-payment-method';
import { WeeksConfirmationEmail } from '../weeks-confirmation-email';
import { BookingConfirmed } from '../../booking-confirmed';
import { CondoBookingFailedWarning } from '../../../../condo/condo-review-book/condo-booking-failed-warning';
import { CondoUnitNotAvailableNotification } from '../../../../condo/condo-review-book/condo-review-book/condo-unit-not-available';
import { CondoBookingPendingNotification } from '../../../../condo/condo-review-book/condo-review-book/booking-pending-notification';
import { CondoCancellationPolicies } from '../../../../condo/condo-review-book/condo-review-book/condo-cancellation-policies';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  weeksReviewBookStore: IWeeksReviewBookState;

  redeemCodeStore: ICouponState;
  insuranceStore: IInsuranceState;

  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getWeeksBookPackageWithCard: (bookWithCard: ICondoBookPackageWithCard, onErrorCallback: (errorCode: string) => void) => void;
  setWeeksGuest: (guest: ICondoGuestBooking) => void;
  setWeeksPriceChangedError: (priceChanged: boolean) => void;
  setWeeksBookingComplete: (bookingComplete: boolean) => void;
  setErrorMessageExpirationDate: (value: boolean) => void;
  setErrorMessageCardNumber: (value: boolean) => void;
  setErrorMessageZipCode: (value: boolean) => void;
  getNavigationMenu: () => void;
  setLoginRedirect: (redirect: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {}

const ZERO = 0;
const SECOND_ELEMENT = 2;
const TEN_SEC = 10000;
const PRIVACY = 'privacy1';

function WeeksReviewBookComponent(props: IProps) {

  const { weeksReviewBookStore, loginStore, menuStore, reviewBookStore, insuranceStore, redeemCodeStore, intl, history } = props;
  const {
    card,
    booking,
    weeksBookingSummary,
    loadingBooking,
    bookingComplete,
    bookingErrorCode,
    isBookingPending,
    selectedWeeksReviewClientCash
  } = weeksReviewBookStore;
  const { threeDSLoading } = reviewBookStore;
  const { account, userWallet, userWalletData } = loginStore;
  const isNotification =
    bookingComplete ||
    isBookingPending ||
    bookingErrorCode === BookingErrorsEnum.SoldOut;
  const { balance, bookingPrice } = weeksBookingSummary.bookingCard;
  const { maxWalletClientCash, payNowPrice } = bookingPrice;
  const { selection, insurance } = insuranceStore;
  const { refundability } = weeksBookingSummary;
  const { guest } = weeksReviewBookStore;
  const { pathname, search } = history.location;
  const { items } = menuStore;
  const { memberType } = (items as INavigation);

  const { coupon } = redeemCodeStore;

  const givenName = weeksBookingSummary.mainGuest?.givenName;
  const surname = weeksBookingSummary.mainGuest?.surname;
  const email = weeksBookingSummary.mainGuest?.email;
  const phone = weeksBookingSummary.mainGuest?.phone;
  const namePrefix = weeksBookingSummary.mainGuest?.namePrefix;

  const chargeDescription = GetPreferenceValue(account as IAccount, MERCHANT_CHARGE_DESCRIPTION_FIELD_KEY);
  const walletIsExternal = account?.walletIsExternal;
  const walletNoDecimals = account?.walletNoDecimals;
  const walletNoPassEarnings = account?.walletNoPassEarnings;
  const hasClientCash = account?.hasClientCash;
  const clientCashPercentage = account?.clientCashPercentage;
  const enableInsurance = account?.enableInsurance;
  
  const errorBooking = weeksReviewBookStore?.errorBooking;

  const maxWalletClientCashInt = maxWalletClientCash;

  const usedClientCash = (bookingComplete && booking) ? get(booking, 'clientCash', 0) : 0;

  const isLogged = !!userWallet;

  const selectedWeeksReviewClientCashFinal = selectedWeeksReviewClientCash ? selectedWeeksReviewClientCash : 0;
  const refundabilityFinal = (!walletIsExternal && hasClientCash && isLogged && (selectedWeeksReviewClientCashFinal > 0 || usedClientCash > 0)) ? NON_REFUNDABLE_TYPE : refundability;

  const [isAccept, setIsAccept] = useState(false);
  const [isAcceptKnowBeforeYouGo, setIsAcceptKnowBeforeYouGo] = useState(false);
  const [isAcceptNoChangesAllowed, setIsAcceptNoChangesAllowed] = useState(false);
  const [isAcceptCancellationPolicy, setIsAcceptCancellationPolicy] = useState(false);
  const [isAcceptIsInsurance, setIsAcceptIsInsurance] = useState(false);
  const [isSomeoneElse, setIsSomeoneElse] = useState(false);
  const [isClientCash, setIsClientCash] = useState(false);
  const [isClientCashNotRefundable, setIsClientCashNotRefundable] = useState(false);
  const [showNotRefundableMessage, setshowNotRefundableMessage] = useState(false);
  const [showNotRefundableConfirmModal, setshowNotRefundableConfirmModal] = useState(false);
  
  const formRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);
  const insuranceContainerRef: any = useRef(null);

  const bookingErrorCodeRef = useRef(bookingErrorCode);
  const bookingCompleteRef = useRef(bookingComplete);
  const errorBookingRef = useRef(errorBooking);
  
  useEffect(() => {
    props.setWeeksGuest({
      id: v4(),
      givenName: givenName ? givenName : '',
      namePrefix: namePrefix ? namePrefix : '',
      surname: surname ? surname : '',
      countryCode: '',
      phoneCountryCode: countriesCode[ZERO].dialCode,
      phone:
        items && getMemberPhone(items.memberType, phone)
          ? getMemberPhone(items.memberType, phone)
          : '',
      email: email ? email : '',
      additionalEmail: '',
      isBookingForSomeoneElse: false,
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
      stateCode: '',
      zipCode: '',
    });

    props.setWeeksBookingComplete(false);
  }, []);

  useEffect(() => {
    if (!bookingErrorCodeRef.current || bookingErrorCodeRef.current !== bookingErrorCode) {
      if (props.weeksReviewBookStore.bookingErrorCode === BookingErrorsEnum.PaymentFailed) {
        formRef.current?.setFields([
          { name: 'holderName', errors: [''] },
          { name: 'cardNumber', errors: [''] },
          { name: 'months', errors: [''] },
          { name: 'years', errors: [''] },
          { name: 'code', errors: [''] },
        ]);
      } else if (
        props.weeksReviewBookStore.bookingErrorCode ===
        BookingErrorsEnum.GuestInformationFailed
      ) {
        formRef.current?.setFields([
          { name: 'prefixName', errors: [''] },
          { name: 'givenName', errors: [''] },
          { name: 'surname', errors: [''] },
          { name: 'phone', errors: [''] },
        ]);
      }
    }
    bookingErrorCodeRef.current = bookingErrorCode;
  }, [bookingErrorCode]);

  useEffect(() => { 
    if (bookingComplete && bookingComplete !== bookingCompleteRef.current) {
      setTimeout(() => {
        props.getNavigationMenu();
      }, TEN_SEC);
    }
    bookingCompleteRef.current = bookingComplete;
  }, [bookingComplete]);

  useEffect(() => { 
    if (errorBooking && errorBooking !== errorBookingRef.current) {
      setTimeout(() => {
        props.getNavigationMenu();
      }, TEN_SEC);
    }
    errorBookingRef.current = errorBooking;
  }, [errorBooking]);

  const onChangeAccept = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAccept(e.target.checked);
  };

  const onChangeAcceptKnowBeforeYouGo = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAcceptKnowBeforeYouGo(e.target.checked);
  };
  
  const onChangeAcceptNoChangesAllowed = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAcceptNoChangesAllowed(e.target.checked);
  };

  const onChangeAcceptCancellationPolicy = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAcceptCancellationPolicy(e.target.checked);
  };

  const onChangeAcceptClientCash = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsClientCash(e.target.checked);
  };

  const onChangeAcceptIsInsurance = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAcceptIsInsurance(e.target.checked);
  };

  const onChangeAcceptClientCashNotRefundable = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsClientCashNotRefundable(e.target.checked);
    setshowNotRefundableMessage(e.target.checked);
  };

  const handleOnCompleteBooking = () => {
    if (NON_REFUNDABLE_TYPE === refundabilityFinal) {
      setshowNotRefundableConfirmModal(true);
    } else {
      onCompleteBooking();
    }
  }

  const onCompleteBooking = () => {
    formRef.current?.setFieldsValue({ email: guest.email });
    formRef.current?.setFieldsValue({ givenName: guest.givenName });
    formRef.current?.setFieldsValue({ surname: guest.surname });
    formRef.current?.setFieldsValue({ phone: guest.phone });

    Promise.all([formRef.current?.validateFields()])
      .then(() => {
        completeBooking();
      })
      .catch((error: IFormErrors) => {
        const { errorFields } = error;
        const { name } = errorFields[ZERO];
        const { scrollToField } = formRef.current;

        (() => {
          scrollToField(name[ZERO], { behavior: ScrollBehaviorEnum.Smooth });
        })();
      });
  };

  const completeBooking = () => {
    const values = UrlUtils.getValues();
    const sessionKey = getSessionObj(values[WEEKS_UNITS_SESSION_KEY_LABEL] as ISessionKey, values[CONDO_SESSION_KEY] as ISessionKey);

    let quote;
    if (values[QUOTE_LABEL]) {
      quote = (values[QUOTE_LABEL] as string);
    }

    const creditCard = { ...card };
    const data: any = {
      sessionKey,
      bookingPrice: weeksBookingSummary.bookingCard.bookingPrice.payNowPrice,
      condoId: getCondoId(),
      availabilityId: getWeeksAvailabilityId(),
      couponCode: coupon,
      guests: [{ ...guest, phone: removingDashFromString(guest.phone) }],
      supplierType: weeksBookingSummary.supplierType,
      quote
    };

    const isPrivateWithToken = account?.type === LoginType.PrivateWithToken;
    const privateTokenFromUrl = account?.privateTokenFromUrl;
    if (isPrivateWithToken && privateTokenFromUrl) {
      data.ssoSessionKey = UrlUtils.getSearchParam(SESSION_KEY_TOKEN_NAME_PARAM);
      data.lifeType = UrlUtils.getSearchParam(LIFE_STYLE_NAME_PARAM);
    }

    const paymentMethod = { paymentMethodId: card?.id, CVC: card?.cvv };

    if (hasClientCash && isLogged) {
      data.clientCashAmount = selectedWeeksReviewClientCash ? selectedWeeksReviewClientCash : 0;

      if (finalBalance > 0) {
        if (!isEmpty(card?.id)) {
          data.paymentMethod = paymentMethod;
        } else {
          data.card = creditCard;
        }
      }
    } else {
      if (!isEmpty(card?.id)) {
        data.paymentMethod = paymentMethod;
      } else {
        data.card = creditCard;
      }
    }

    props.setErrorMessageExpirationDate(true);
    props.setErrorMessageCardNumber(true);
    props.setErrorMessageZipCode(true);
    props.getWeeksBookPackageWithCard(data, () => scrollToError());

    if (hasClientCash && isLogged) {
      data.clientCashAmount = selectedWeeksReviewClientCash ? selectedWeeksReviewClientCash : 0;
    }

    ReactGA.event({
      category: account?.name as string,
      action: `${C_C_COMPLETE_BOOKING}_${account?.name.toUpperCase()}`,
      label: `User clicked complete booking on book`,
      nonInteraction: false,
    });

  }

  const scrollToError = () => {
    wrapperRef.current?.scrollIntoView({ behavior: ScrollBehaviorEnum.Smooth });
  }

  const onChangeSomeoneElse = (e: { target: CheckboxChangeEventTarget }) => {
    if (!e.target.checked) {
      formRef.current?.setFieldsValue({ givenName: givenName });
      formRef.current?.setFieldsValue({ surname: surname });
      formRef.current?.setFieldsValue({
        phone: getMemberPhone(memberType, weeksBookingSummary.mainGuest.phone),
      });
    }

    setIsSomeoneElse(e.target.checked);

    props.setWeeksGuest({
      ...(updateWeeksGuest(guest, {
        isBookingForSomeoneElse: e.target.checked,
        givenName: !e.target.checked ? givenName : '',
        surname: !e.target.checked ? surname : '',
        phone: !e.target.checked
          ? getMemberPhone(memberType, weeksBookingSummary.mainGuest.phone)
          : '',
      }) as ICondoGuestBooking),
      isBookingForSomeoneElse: e.target.checked,
    });
  };

  const getBookingErrorWarning = (): React.ReactNode | null => {
    let header = "something.went.wrong";
    let description = bookingErrorCode as string;

    if (bookingErrorCode === BookingErrorsEnum.PaymentFailed) {
      header = "payment.authorize.failed";
      description = "check.card.details.or.use.another.payment.method";
    } else if (bookingErrorCode === BookingErrorsEnum.GuestInformationFailed) {
      header = "issue.with.guest.information";
      description = "check.you.entered.valid.guest.information";
    } else if (bookingErrorCode === BookingErrorsEnum.RoomsUnavailable || bookingErrorCode === BookingErrorsEnum.SoldOut) {
      return <CondoAvailabilityStatusModal />;
    } else if (bookingErrorCode === BookingErrorsEnum.General) {
      header = "something.went.wrong";
      description = "error.code.general";
    } else if (bookingErrorCode === BookingErrorsEnum.UnexpectedResponse) {
      header = "something.went.wrong";
      description = "there.may.be.an.issue";
    }

    return (
      <CondoBookingFailedWarning
        header={header}
        description={description}
      />
    );
  };

  const searchCondo = () => {
    UrlUtils.removeFromUrl(WEEKS_UNITS_SESSION_KEY_LABEL);
    UrlUtils.removeFromUrl(CONDO_SESSION_KEY);

    props.history.push(`/${props.loginStore.account?.name}${Routes.WeeksSearch}${location.search}`);
  };

  const bookAnotherUnit = () => {
    const condoId = Number(props.history.location.pathname.split('/')[SECOND_ELEMENT]);
    props.history.push(`/${props.loginStore.account?.name}${Routes.WeeksDetails}/${condoId}${location.search}`);
  };

  const hangleGoToLogin = () => {
    props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);
    props.history.push(`/${account?.name}${Routes.Login}`);
  }

  const hangleGoToSignUp = () => {
    props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);
    props.history.push(`/${account?.name}${Routes.SignUp}`);
  }

  const handleUpdateAddress = () => {    
    formRef.current?.setFieldsValue({ addressLine1: guest.addressLine1 });
    formRef.current?.setFieldsValue({ addressLine2: guest.addressLine2 });
    formRef.current?.setFieldsValue({ city: guest.city });
    formRef.current?.setFieldsValue({ country: guest.country });
    formRef.current?.setFieldsValue({ state: guest.stateCode });
    formRef.current?.setFieldsValue({ postalCode: guest.zipCode });
  }

  const currency = useMemo(() => getSelectedCurrency(account as IAccount), [account]);

  const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
  const finalBalanceIntermediate = payNowPrice - (selectedWeeksReviewClientCash ? selectedWeeksReviewClientCash * convertionRate : 0);
  const finalBalance = Math.floor(finalBalanceIntermediate * 100) / 100;
  
  const eligibleClientCash = (finalBalance > 0 ? finalBalance : 0) * ((clientCashPercentage && !isEmpty(clientCashPercentage)) ? parseInt(clientCashPercentage) : 0) / 100;
  const isRequired = !hasClientCash || finalBalance > 0;
  const balanceCalculated = balance / convertionRate;

  const maxWalletClientCashCalculated = maxWalletClientCashInt ? walletNoDecimals ? Math.floor(maxWalletClientCashInt / convertionRate) : maxWalletClientCashInt / convertionRate : null;
  const userWalletDataBalanceCalculatedInt = (walletNoDecimals && userWalletData?.balance) ? Math.floor(userWalletData?.balance) : userWalletData?.balance;
  const userWalletDataBalanceCalculated = userWalletDataBalanceCalculatedInt ? userWalletDataBalanceCalculatedInt : 0;
  const maxWalletAmountIntermediated = walletNoPassEarnings ? 
                            maxWalletClientCashCalculated ?
                              (maxWalletClientCashCalculated && userWalletDataBalanceCalculated > maxWalletClientCashCalculated) ? 
                                maxWalletClientCashCalculated : 
                                userWalletDataBalanceCalculated : 
                              0 :
                            userWalletDataBalanceCalculated > balanceCalculated ? 
                              balanceCalculated : 
                              userWalletDataBalanceCalculated;

  const maxWalletAmountInt = (maxWalletClientCashCalculated && maxWalletAmountIntermediated > maxWalletClientCashCalculated) ? maxWalletClientCashCalculated : maxWalletAmountIntermediated;
  const maxWalletAmount = maxWalletAmountInt ? maxWalletAmountInt : 0;

  const clientCash = useMemo(() => selectedWeeksReviewClientCash ? GetWalletNumber(selectedWeeksReviewClientCash, account as IAccount) : Number(0).toFixed(walletNoDecimals ? 0 : 2), [selectedWeeksReviewClientCash, account, walletNoDecimals]);

  const insuranceBalance = (selection === InsuranceSelection.YES && !!insurance?.productDetails?.price) ? insurance?.productDetails?.price : 0;
  const isInsuranceSelected = (selection === InsuranceSelection.YES && !!insurance?.productDetails?.price);

  return (
    <div className="condo-review-book-left-wrapper print" ref={wrapperRef}>
      {bookingErrorCode ? getBookingErrorWarning() : null}
      <div className={`condo-review-book ${isNotification ? 'hide-block' : ''}`}>
        <Form name="condo-review-book-rule" ref={formRef}>
          <WeeksGuestInfo
            formRef={formRef}
            onChangeSomeoneElse={onChangeSomeoneElse}
            isSomeoneElse={isSomeoneElse}
            onUpdateAddress={handleUpdateAddress}
            onChangeCountryState={() => setIsAcceptIsInsurance(false)}
          />

          <WeeksConfirmationEmail formRef={formRef} isSomeoneElse={isSomeoneElse} />
          
          <WeeksPaymentMethod
            formRef={formRef}
            display={finalBalance > 0}
            isRequired={isRequired}
          />

          {!card?.id ? (
            <div className="condo-review-book__wrapper" ref={insuranceContainerRef}>
              <Insurance onChange={() => setIsAcceptIsInsurance(false)} />
            </div>) : null}
        </Form>

        <CondoCancellationPolicies displayCheckbox={!bookingComplete} disable={loadingBooking} onChangeAcceptPolicy={onChangeAcceptCancellationPolicy} condoBookingSummary={weeksBookingSummary} />

        <div className="condo-review-book__wrapper">
          <Checkbox onChange={onChangeAccept} disabled={loadingBooking} />
          <p className="condo-review-book__accept-text">
            
            <FormattedMessage
              id="accept.you.will.be.charged"
              values={{
                price: renderNumber(finalBalance + insuranceBalance, 2),
                type: <FormattedMessage id="Condo" />,
                reservation_name: (!isEmpty(chargeDescription) && weeksBookingSummary?.customProvider) ? chargeDescription : <FormattedMessage id="accept.you.will.be.charged.travcoding" />,
                currency
              }}
            />
          </p>
        </div>

        <div className="review-book__wrapper">
          <Checkbox onChange={onChangeAcceptKnowBeforeYouGo} disabled={loadingBooking} />
          <p className="review-book__accept-text">
            <FormattedMessage id="accetp.no.changes.allow" />
          </p>
        </div>

        <div className="review-book__wrapper">
          <Checkbox onChange={onChangeAcceptNoChangesAllowed} disabled={loadingBooking} />
          <p className="review-book__accept-text">
            <FormattedMessage id="by.checking.box.you.acknowledge.that.you.have.read.know.before.you.go" />
          </p>
        </div>

        {hasClientCash && isLogged &&
          <div className="review-book__wrapper">
            <Checkbox onChange={onChangeAcceptClientCash} disabled={loadingBooking} />
            <p className="review-book__accept-text">
              {!walletIsExternal ? 
                (<FormattedMessage id="wallet.client.cash.by.checking.acknowledge" values={{ clientCash, reward: walletNoDecimals ? Math.floor(eligibleClientCash).toFixed(0) : eligibleClientCash.toFixed(2), clientCashPercentage: clientCashPercentage, clientCashName: account?.walletClientCashName }} />) :
                (<FormattedMessage id="wallet.client.cash.by.checking.acknowledge.external" values={{ clientCash, clientCashName: account?.walletClientCashName }} />)}
            </p>
          </div>}

        {!walletIsExternal && hasClientCash && isLogged && selectedWeeksReviewClientCashFinal > 0 &&
          <div className="review-book__wrapper">
            <Checkbox onChange={onChangeAcceptClientCashNotRefundable} disabled={loadingBooking} />
            <p className="review-book__accept-text">
              <FormattedMessage id="wallet.client.not_refundable.acknowledge" values={{ clientCash, currency, clientCashName: account?.walletClientCashName }} />
            </p>

            <ModalMessage text={intl.formatMessage({ id: 'wallet.client.not_refundable.modal.acknowledge'}, { clientCashName: account?.walletClientCashName })} disableTextLocalization disableTitleLocalization={true} visible={showNotRefundableMessage} onCancel={() => setshowNotRefundableMessage(false)} />
          </div>}

        {isInsuranceSelected ? (
          <div className="review-book__wrapper">
            <Checkbox onChange={onChangeAcceptIsInsurance} disabled={loadingBooking} />
            <p className="review-book__accept-text">
              <FormattedMessage id="insurance.policy.disclaimer" />
            </p>
          </div>) : null}

        {hasClientCash && !isLogged &&
          <div className="review-book__wrapper client-cash-message">
            <p className="review-book__accept-text">
              <FontAwesomeIcon icon={faTriangleExclamation} />{' '}<FormattedMessage id="wallet.client.not_logged.acknowledge" values={{ reward: eligibleClientCash.toFixed(2), currency, clientCashPercentage: clientCashPercentage, clientCashName: account?.walletClientCashName }} />
            </p>
            <p className="booking-confirmed__contact-property" style={{ fontSize: '18px', marginTop: '10px', textAlign: 'right' }}>
              <BlueButton onClick={hangleGoToLogin}><FormattedMessage id="sign.in" /></BlueButton> <FormattedMessage id="or" /> <BlueButton onClick={hangleGoToSignUp}><FormattedMessage id="sign.up" /></BlueButton>
            </p>
          </div>}

        <div className="condo-review-book__wrapper">
          <p className="condo-review-book__agree-navigation-text">
            <FormattedMessage 
              id="agree.booking"
              values={{
                pp: (msg: string) => (
                  <a target="_blank" rel="noreferrer" href="https://www.travcoding.com/privacy-policy/" onClick={() => {
                    ReactGA.event({
                      category: account?.name as string,
                      action: `${C_PP}_${account?.name.toUpperCase()}`,
                      label: `User clicked privacy policy on book`,
                      nonInteraction: false,
                    });
                  }}>
                    {msg}
                  </a>
                ),
                tu: (msg: string) => (
                  <a target="_blank" rel="noreferrer" href="https://www.travcoding.com/terms-of-use/" onClick={() => {
                    ReactGA.event({
                      category: account?.name as string,
                      action: `${C_TERMS}_${account?.name.toUpperCase()}`,
                      label: `User clicked terms of use on book`,
                      nonInteraction: false,
                    });
                  }}>
                    {msg}
                  </a>
                )
              }}
            />
            {menuStore.items?.baseUrl ? (
              <a
                className="condo-review-book__agree-navigation-link"
                href={getFullUrl(menuStore.items?.baseUrl, PRIVACY)}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="privacy.policy" />
              </a>
            ) : null}
          </p>

          <div className="condo-review-book__complete-btn">
            <BlueButton
              onClick={handleOnCompleteBooking}
              disabled={
                !isAccept || 
                !isAcceptKnowBeforeYouGo || 
                !isAcceptNoChangesAllowed ||
                (!isAcceptCancellationPolicy && !isEmpty(weeksBookingSummary.cancellationPolicyText)) ||
                (!card?.id && enableInsurance && insurance && (selection === null || selection === undefined)) ||
                (isInsuranceSelected && !isAcceptIsInsurance) || 
                loadingBooking || 
                (hasClientCash && isLogged && (!isClientCash)) || 
                (hasClientCash && isLogged && !isClientCashNotRefundable && selectedWeeksReviewClientCashFinal > 0 && !walletIsExternal) || 
                (hasClientCash && isLogged && selectedWeeksReviewClientCashFinal > maxWalletAmount) || 
                (hasClientCash && isLogged && !!selectedWeeksReviewClientCashFinal && selectedWeeksReviewClientCashFinal <= maxWalletAmount && walletNoDecimals && Math.floor(selectedWeeksReviewClientCashFinal) != selectedWeeksReviewClientCashFinal)
              }
            >
              <FormattedMessage id="complete.booking" />
              {loadingBooking && <Spin />}
            </BlueButton>
          </div>
        </div>

        <InsuranceRequiredMessage 
          display={(!card?.id && enableInsurance && insurance && (selection === null || selection === undefined)) as boolean}
          insuranceContainerRef={insuranceContainerRef}
          classNameContainer="condo-review-book__wrapper"
        />
      </div>

      <BookingConfirmed isBookingComplete={bookingComplete} />

      {isBookingPending && <CondoBookingPendingNotification />}

      {bookingErrorCode === BookingErrorsEnum.SoldOut && (
        <CondoUnitNotAvailableNotification
          isAlternativeUnitsAvailable={false}
          searchCondos={searchCondo}
          bookAnotherUnit={bookAnotherUnit}
        />)}

      <ThreeDSModal isCondo={true} />

      {threeDSLoading ? (
        <div className="condo-review-book__loading-container">
          <Loading />
        </div>) : null}

      <ModalConfirmation
        message="non.refundable.reservation.message"
        title="non.refundable.reservation"
        yesMessage="yes"
        noMessage="no"
        visible={showNotRefundableConfirmModal}
        onCancel={() => setshowNotRefundableConfirmModal(false)}
        onConfirm={() => {
          setshowNotRefundableConfirmModal(false);
          onCompleteBooking();
        }}
      />

    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    weeksReviewBookStore: state.weeksReviewBookStore,

    redeemCodeStore: state.redeemCodeStore,
    insuranceStore: state.insuranceStore,

    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getWeeksBookPackageWithCard,

  setWeeksGuest: weeksReviewBookAction.setWeeksGuest,
  setWeeksPriceChangedError: weeksReviewBookAction.setWeeksPriceChangedError,
  setWeeksBookingComplete: weeksReviewBookAction.setWeeksBookingComplete,
  setErrorMessageExpirationDate: weeksReviewBookAction.setErrorMessageExpirationDate,
  setErrorMessageCardNumber: weeksReviewBookAction.setErrorMessageCardNumber,
  setErrorMessageZipCode: weeksReviewBookAction.setErrorMessageZipCode,

  getNavigationMenu,
  setLoginRedirect,
};

export const WeeksReviewBookComponentIntl = injectIntl(WeeksReviewBookComponent);

export const WeeksReviewBook = connect(mapStateToProps, mapDispatchToProps)(withRouter(WeeksReviewBookComponentIntl));
