import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { IPopularDestinationsOffersState } from '@store/slices';
import { RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import ConciergeTeamImageLarge from '@assets/images/concierge-team-large.png';

import './style.scss';

interface IMapStateToProps {
  popularDestinationsOffersStore: IPopularDestinationsOffersState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {}

class ContactComponent extends React.Component<IProps> {

  render() {
    const { loginStore } = this.props;
    const { account } = loginStore;

    let title = 'here.to.help';
    let message = 'here.to.help.message';
    let subMessage = 'here.to.help.subMessage';
    let hideImage = false;
    let displayOneRow = false;
    let fontsColor = undefined;
    let backgroundColor = undefined;
    let textSameSize = false;
    let skipBottomMargins = undefined;

    const contactAssistants = account?.contactAssistants;
    let contacts: any[] = [];

    const titleChanged = !isEmpty(contactAssistants?.title);
    const messageChanged = !isEmpty(contactAssistants?.mainMessage);
    const subMessageChanged = !isEmpty(contactAssistants?.subMessage);

    if (contactAssistants) {
      title = '';
      message = '';
      subMessage = '';
  
      if (titleChanged) {
        title = contactAssistants?.title;
      }

      if (messageChanged) {
        message = contactAssistants?.mainMessage;
      }

      if (subMessageChanged) {
        subMessage = contactAssistants?.subMessage;
      }

      hideImage = contactAssistants.hideImage || false;
      displayOneRow = contactAssistants.displayOneRow || false;
      textSameSize = contactAssistants.displayOneRow || false;
      fontsColor = contactAssistants.fontsColor;
      backgroundColor = contactAssistants.backgroundColor;
      skipBottomMargins = contactAssistants.skipBottomMargins;
      
      const contactList = contactAssistants?.contacts;
      if (contactList?.length) {
        contacts = contactList.map(c => ({ ...c, type: c?.type.toLowerCase(), changed: true }));
      }
    }

    const styleFonts = !fontsColor ? {} : { color: fontsColor };
    const styleFontSize = !textSameSize ? {} : { fontSize: 19 };
    const styleFontWeightNormal = !textSameSize ? {} : { fontWeight: 500 };
    const styleFontWeightBold = !textSameSize ? {} : { fontWeight: 600 };
    const styleBackground = !backgroundColor ? {} : { background: backgroundColor };
    const styleMargin = !skipBottomMargins ? {} : { marginBottom: 0 };
    const stylePadding = !skipBottomMargins ? {} : { paddingBottom: 0 };
    
    return (
      <div className="contact__container" style={{ ...styleBackground, ...styleMargin }}>
        <div className={`contact ${displayOneRow ? 'one-row' : ''}`} style={{ ...stylePadding }}>
          <div className="contact__main-title">
            <p className="contact__title secondary-font" style={{ ...styleFonts, ...styleFontSize, ...styleFontWeightBold }}>
              {!contactAssistants && !titleChanged ? (<FormattedMessage id={title} />) : title}
            </p>
            <p className="contact__message secondary-font" style={{ ...styleFonts, ...styleFontSize, ...styleFontWeightNormal }}>
              {!contactAssistants && !messageChanged ? (<FormattedMessage id={message} />) : message}
            </p>
            {!isEmpty(subMessage) && (
              <p className="contact__sub-message secondary-font">
                {!contactAssistants && !subMessageChanged ? (<FormattedMessage id={subMessage} />) : subMessage}
              </p>)}
          </div>

          <div className={`contact__wrapper ${displayOneRow ? 'one-row' : ''}`}>
            {contacts.map((c, i) => (
              <div key={i} className="contact-item">
                <div className="contact-item__messages">
                  <p className="title secondary-font" style={{ ...styleFonts, ...styleFontSize, ...styleFontWeightBold }}>
                    {c.changed ? c.message : (<FormattedMessage id={c.message} />)}
                  </p>
                </div>

                {c.type === 'email' ? <div className="contact-item__arrow">
                  <a className="email" href={`mailto:${c.value}`} style={{ ...styleFonts, ...styleFontSize, ...styleFontWeightNormal }}>
                    {c.value}
                  </a>
                </div> : null}

                {c.type === 'phone' ? 
                  <a className="email" href={`tel:${c.value?.replaceAll('-', '').replaceAll(' ', '')}`} style={{ ...styleFonts, ...styleFontSize, ...styleFontWeightNormal }}>
                    {c.value}
                  </a> : null}
              </div>
            ))}
          </div>

          {!hideImage && (
            <div className="concierge-services__img-wrapper">
              <img src={ConciergeTeamImageLarge} alt="Concierge team" />
            </div>)}

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    popularDestinationsOffersStore: state.popularDestinationsOffersStore,
    loginStore: state.loginStore
  };
};

export const Contact = connect(mapStateToProps)(injectIntl(ContactComponent));
