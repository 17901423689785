import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import parse from 'html-react-parser';

import { get } from 'lodash';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { BookingConfirmedSvg, InfoColorSvg, InfoSvg } from '@assets';
import { DATE_FORMAT_DAY_MONTH_YEAR } from '@constants';
import { Routes } from '@share/constants';
import { ICondoReviewBookState } from '@store/slices';
import { CallbackFunction, formatDateCheckInOut, getSelectedCurrency, RootState } from '@share/utils';
import { BlueButton } from '@share/components';
import { InsuranceConfirmed, VoucherModal } from '@components';
import { voucherBooking, downloadVoucher } from '@share/store/slices';

import { ILoginState, setLoginRedirect } from '@share/store/slices';
import { enUS } from 'date-fns/esm/locale';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  condoReviewBookStore: ICondoReviewBookState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setLoginRedirect: (redirect: string) => void;
  voucherBooking: (bookingId: string, recepientEmail: string, additionalEmail: string, callback: CallbackFunction) => void;
  downloadVoucher: (bookingId: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  isBookingComplete: boolean;
}

interface IState {
  showVoucherModal: boolean;
  emailResend: string;
  emailAdditional: string;
  isAdditionalEmailAdded: boolean;
}

export class BookingConfirmedComponent extends React.Component<IProps, IState> {
  state: IState = { showVoucherModal: false, emailAdditional: '', emailResend: '', isAdditionalEmailAdded: false };

  isPossibleApplicableFees = (): boolean => {
    const { mandatoryFees, fees, housekeepingFees } =
      this.props.condoReviewBookStore.condoBookingSummary.bookingBeforeYouGo;

    return !isEmpty(mandatoryFees) || !isEmpty(fees) || !isEmpty(housekeepingFees);
  };

  isKnowBeforeYouGo = (): boolean => {
    const { urgentInfo, constructionDetails } =
      this.props.condoReviewBookStore.condoBookingSummary.bookingBeforeYouGo;

    return !isEmpty(urgentInfo) || !isEmpty(constructionDetails);
  };

  hangleGoToLogin = () => {
    const { loginStore, condoReviewBookStore, history } = this.props;
    const { account } = loginStore;
    const { booking } = condoReviewBookStore;

    const { pathname, search } = history.location;
    const bookingGuid = get(booking, 'bookingGuid');

    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account.name}${Routes.Login}/${bookingGuid}`);
  }

  hangleGoToSignUp = () => {
    const { loginStore, condoReviewBookStore, history } = this.props;
    const { account } = loginStore;
    const { booking } = condoReviewBookStore;

    const { pathname, search } = history.location;

    const bookingGuid = get(booking, 'bookingGuid');

    this.props.setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);

    this.props.history.push(`/${account.name}${Routes.SignUp}/${bookingGuid}`);
  }

  join = (strings: string[], separator: string): string => {
    return strings.filter((x) => !isUndefined(x) && x?.length > 0).join(separator);
  };

  handleVoucherBooking = (recepientEmail: string, additionalEmail: string) => {
    const { condoReviewBookStore } = this.props;
    const { booking } = condoReviewBookStore;
    const bookingId = get(booking, 'bookingGuid');

    this.props.voucherBooking(bookingId, recepientEmail, additionalEmail, () => this.setState({ showVoucherModal: false }));
  }

  handleDownloadVoucher = () => {
    const { condoReviewBookStore, downloadVoucher } = this.props;
    const { booking } = condoReviewBookStore;

    const bookingId = get(booking, 'bookingGuid');

    downloadVoucher(bookingId);
  }

  render(): React.ReactNode {
    const { isBookingComplete, condoReviewBookStore, loginStore } = this.props;
    const { showVoucherModal, emailResend, emailAdditional, isAdditionalEmailAdded } = this.state;
    const { account, userWallet, user } = loginStore;
    const { booking, condoBookingSummary, selectedCondoReviewClientCash, bookingComplete } = condoReviewBookStore;
    const { bookingCard, bookingPolicies, bookingBeforeYouGo, mainGuest } = condoBookingSummary;
    const {
      givenName,
      surname,
      email,
      phone,
      addressLine1,
      city,
      stateCode,
      country,
      countryCode,
      isBookingForSomeoneElse,
      additionalEmail,
      phoneCountryCode,
    } = condoReviewBookStore.guest;

    const propertyInfoLine1 = this.join([bookingCard.city, bookingCard.state], ', ');
    const propertyInfoLine2 = this.join(
      [bookingCard.postalCode, bookingCard.postalCodeExtension],
      ' ',
    );

    // For now we for english
    const locale = enUS;//getAccountDateFnsLocale(account);

    const hasClientCash = account.hasClientCash;
    const clientCashPercentage = account.clientCashPercentage;
    const disableDownloadVoucherCheckout = account.disableDownloadVoucherCheckout;
    
    const isLogged = !!userWallet;

    const { payNowPrice } = condoBookingSummary.bookingCard.bookingPrice;
    const finalBalanceIntermediate = payNowPrice - (selectedCondoReviewClientCash ? selectedCondoReviewClientCash : 0);
    const finalBalance = Math.floor(finalBalanceIntermediate * 100) / 100;
    const eligibleClientCash = (finalBalance > 0 ? finalBalance : 0) * (!isEmpty(clientCashPercentage) ? parseInt(clientCashPercentage) : 0) / 100;

    const usedClientCash = (bookingComplete && booking) ? get(booking, 'clientCash', 0) : 0;

    const currency = getSelectedCurrency(account);

    return (
      isBookingComplete &&
      booking && (
        <div className="condo-booking-confirmed">
          <div className="success-logo-container">
            <div className='col-md-3'>
              <BookingConfirmedSvg />
            </div>
            <div className='col-md-8' style={{padding: '8px'}}>
              <p className='title-name'><FormattedMessage id="thank.you" />, {surname}!</p>
              <p className='title-confirmed'>
                <FormattedMessage id="your.booking.in" /> {bookingCard.name}{' '}
                <FormattedMessage id="is.confirmed" />.
              </p>
              <p className='title-email'><FormattedMessage id="email.sent" values={{ email: email }} /></p>
            </div>
          </div>

          <p className="condo-booking-confirmed__address">
            {bookingCard.name}
            <span>
              {' '}
              <FormattedMessage id="is.expecting.you.on" />{' '}
            </span>
            {formatDateCheckInOut(bookingCard.checkIn, DATE_FORMAT_DAY_MONTH_YEAR, locale)}
          </p>
          <div className="condo-booking-confirmed__number-info-wrapper">
            <p className="condo-booking-confirmed__number-info">
              <FormattedMessage id="confirmation.number" />:{' '}
              <strong>{booking ? booking.confirmationNumber : ''}</strong>
            </p>
            <p className="condo-booking-confirmed__info-provide">
              <InfoColorSvg />
              <FormattedMessage id="asked.to.provide.this.number" />
            </p>
            <p className="condo-booking-confirmed__info-provide">
              <InfoColorSvg />
              <FormattedMessage id="asked.to.provide.this.member.ID" />
            </p>
            <p className="condo-booking-confirmed__info-provide">
              <InfoColorSvg />
              <label className="condo-guest-info__guest-message" style={{ fontSize: '13px' }}>
                <FormattedMessage id="name.changes" />
              </label>
            </p>
            <p className="condo-booking-confirmed__number-info" style={{ marginTop: '30px' }}>
              <FormattedMessage id="member.ID" />:
              <strong style={{ marginLeft: '5px' }}>{booking ? booking.memberId : ''}</strong>
            </p>
          </div>
          <div className="condo-booking-confirmed__info-wrapper">
            <div className="condo-booking-confirmed__info-block">
              <p className="condo-booking-confirmed__info-title">
                <FormattedMessage id="guest.info" />
              </p>
              <p className="condo-booking-confirmed__info">
                <FormattedMessage id="guest.name" />:{' '}<strong>{givenName} {surname}</strong>
              </p>
              <p className="condo-booking-confirmed__info">
                <FormattedMessage id="email.address" />:{' '}<strong>{isBookingForSomeoneElse ? additionalEmail : email}</strong>
              </p>
              <p className="condo-booking-confirmed__info">
                <FormattedMessage id="phone.number" />:{' '}<strong>{phoneCountryCode} {phone}</strong>
              </p>
            </div>
            <div className="condo-booking-confirmed__info-block">
              <p className="condo-booking-confirmed__info-title">&nbsp;</p>
              <div className="condo-booking-confirmed__info-address">
                <p className="condo-booking-confirmed__info"><FormattedMessage id="address" />:{' '}<strong>{addressLine1}</strong></p>
                <p className="condo-booking-confirmed__info"><FormattedMessage id="city" />:{' '}<strong>{city}</strong></p>
                <p className="condo-booking-confirmed__info">
                  <FormattedMessage id="state.country" />:{' '}<strong>{countryCode} {stateCode} {country}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="condo-booking-confirmed__info-wrapper" style={{ marginTop: '20px' }}>
            <div className="condo-booking-confirmed__info-block">
              <p className="condo-booking-confirmed__info-title">
                <FormattedMessage id="property.info" />
              </p>
              <p className="condo-booking-confirmed__info"><FormattedMessage id="phone.number" />:{' '}<strong>{booking?.resort?.phones?.join(', ')}</strong></p>
              <p className="condo-booking-confirmed__info"><FormattedMessage id="city.state" />:{' '}<strong>{propertyInfoLine1}</strong></p>
              <p className="condo-booking-confirmed__info"><FormattedMessage id="postal-code" />:{' '}<strong>{propertyInfoLine2}</strong></p>
            </div>
          </div>
          {isBookingForSomeoneElse && email ? (
            <div className="condo-booking-confirmed__booked-by-wrapper">
              <div className="condo-booking-confirmed__info-block">
                <p className="condo-booking-confirmed__info-title">
                  <FormattedMessage id="booked.by" />
                </p>
                <p className="condo-booking-confirmed__info">
                  {mainGuest?.givenName ? `${mainGuest?.givenName} ${mainGuest?.surname},` : ''}{' '}
                  {email}
                </p>
              </div>
            </div>
          ) : null}
          <div className="condo-booking-confirmed__cancellation-policy-wrapper">
            {!isUndefined(condoBookingSummary.cancellationPolicyText) &&
              !isEmpty(condoBookingSummary.cancellationPolicyText) ? (
              <div className="condo-booking-confirmed__cancellation-policy-item">
                <p className="condo-booking-confirmed__info-title">
                  <FormattedMessage id="cancellation.policy" />
                </p>
                <p className="condo-booking-confirmed__info">
                  {condoBookingSummary.cancellationPolicyText}
                </p>
              </div>
            ) : null}
            {!isUndefined(bookingPolicies.ageRestriction) &&
              !isEmpty(bookingPolicies.ageRestriction) ? (
              <div className="condo-booking-confirmed__cancellation-policy-item">
                <p className="condo-booking-confirmed__info-title">
                  <FormattedMessage id="age.restriction" />
                </p>
                <p className="condo-booking-confirmed__info">{bookingPolicies.ageRestriction}</p>
              </div>
            ) : null}
            {!isUndefined(bookingPolicies.pets) && !isEmpty(bookingPolicies.pets) ? (
              <div className="condo-booking-confirmed__cancellation-policy-item">
                <p className="condo-booking-confirmed__info-title">
                  <FormattedMessage id="pets" />
                </p>
                <p className="condo-booking-confirmed__info">{bookingPolicies.pets}</p>
              </div>
            ) : null}
            {!isUndefined(bookingPolicies.parking) && !isEmpty(bookingPolicies.parking) ? (
              <div className="condo-booking-confirmed__cancellation-policy-item">
                <p className="condo-booking-confirmed__info-title">
                  <FormattedMessage id="parking" />
                </p>
                {bookingPolicies.parking.map((item, index) => (
                  <p key={index} className="condo-booking-confirmed__info">
                    {item}
                  </p>
                ))}
              </div>
            ) : null}
            {!isUndefined(bookingPolicies.smoking) && !isEmpty(bookingPolicies.smoking) ? (
              <div className="condo-booking-confirmed__cancellation-policy-item">
                <p className="condo-booking-confirmed__info-title">
                  <FormattedMessage id="smoking" />
                </p>
                {bookingPolicies.smoking.map((item, index) => (
                  <p key={index} className="condo-booking-confirmed__info">
                    {item}
                  </p>
                ))}
              </div>
            ) : null}
            <div>
              <p className="condo-booking-confirmed__contact-property">
                <FormattedMessage id="support" />:{' '}
                <span className="condo-booking-confirmed__contact-property-phone">
                  {booking.customerServiceNumber}
                </span>
              </p>
            </div>

            <div>
              <p className="condo-booking-confirmed__contact-property" style={{ display: 'flex' }}>
                <FormattedMessage id="confirmation.resend.email" />{' '}
                <span className="condo-booking-confirmed__contact-property-phone" style={{ marginLeft: '5px' }}>
                  <div style={{ cursor: 'pointer', textDecoration: 'underline', color: '#40a9ff'}} onClick={() => this.setState({ showVoucherModal: true })}>
                    <FormattedMessage id="confirmation.resend.email.resend" />
                  </div>
                </span>
              </p>
            </div>

            <VoucherModal
              visible={showVoucherModal}
              loading={condoReviewBookStore?.loading || loginStore?.loading}
              onCancel={() => this.setState({ showVoucherModal: false })}
              onVoucher={this.handleVoucherBooking}
              email={emailResend}
              emailAdditional={emailAdditional}
              isAdditionalEmailAdded={isAdditionalEmailAdded}
              setEmailModal={(emailResend) => this.setState({ emailResend })}
              setEmailAdditionalModal={(emailAdditional) => this.setState({ emailAdditional })}
              setIsAdditionalEmailAddedModal={(isAdditionalEmailAdded) => this.setState({ isAdditionalEmailAdded })}
            />

            {(hasClientCash && !isLogged && !usedClientCash) && (
              <div className="condo-booking-confirmed__client-cash-login">
                <p className="condo-booking-confirmed__contact-property" style={{ marginTop: '0px', fontSize: '15px', paddingTop: '0px' }}>
                  <FontAwesomeIcon icon={faTriangleExclamation} />{' '}<FormattedMessage id="wallet.client.not_logged.thanks" values={{ clientCashPercentage, currency, reward: eligibleClientCash.toFixed(2), clientCashName: account?.walletClientCashName }} />
                </p>
                <p className="condo-booking-confirmed__contact-property" style={{ fontSize: '18px', marginTop: '10px', textAlign: 'right' }}>
                  <BlueButton onClick={this.hangleGoToLogin}><FormattedMessage id="sign.in" /></BlueButton> <FormattedMessage id="or" /> <BlueButton onClick={this.hangleGoToSignUp}><FormattedMessage id="sign.up" /></BlueButton>
                </p>
              </div>)}

            {this.isKnowBeforeYouGo() || this.isPossibleApplicableFees() ? (
              <div className="condo-booking-confirmed__know-wrapper">
                {!isEmpty(bookingBeforeYouGo.urgentInfo) && (
                  <div className="condo-booking-confirmed__know-block">
                    <h4 className="condo-booking-confirmed__know-title">
                      <InfoSvg />
                      <FormattedMessage id="know.before.you.go" />
                    </h4>
                    {!isEmpty(bookingBeforeYouGo.urgentInfo.generalUrgentInfoMessages) && (
                      <div className="condo-booking-confirmed__know-text-wrapper">
                        {bookingBeforeYouGo.urgentInfo.generalUrgentInfoMessages.map(
                          (item, index) => (
                            <p key={index} className="condo-booking-confirmed__know--text">
                              {parse(item)}
                            </p>
                          ),
                        )}
                      </div>
                    )}
                    {!isEmpty(
                      bookingBeforeYouGo.urgentInfo.generalUrgentInfoForNonMembersMessages,
                    ) && (
                        <div className="condo-booking-confirmed__know-text-wrapper">
                          {bookingBeforeYouGo.urgentInfo.generalUrgentInfoForNonMembersMessages.map(
                            (item, index) => (
                              <p key={index} className="condo-booking-confirmed__know--text">
                                {parse(item)}
                              </p>
                            ),
                          )}
                        </div>
                      )}
                    {bookingBeforeYouGo.urgentInfo.occupancyRestrictions && (
                      <div className="condo-booking-confirmed__know-text-wrapper">
                        <p className="condo-booking-confirmed__know--text">
                          {parse(bookingBeforeYouGo.urgentInfo.occupancyRestrictions)}
                        </p>
                      </div>
                    )}
                    {bookingBeforeYouGo.urgentInfo.oneInXRuleApplies && (
                      <div className="condo-booking-confirmed__know-text-wrapper">
                        <p className="condo-booking-confirmed__know--text">
                          {parse(bookingBeforeYouGo.urgentInfo.oneInXRuleApplies)}
                        </p>
                      </div>
                    )}
                    {bookingBeforeYouGo.urgentInfo.separateSwimmingPoolForGenders && (
                      <div className="condo-booking-confirmed__know-text-wrapper">
                        <p className="condo-booking-confirmed__know--text">
                          <FormattedMessage id="separate.swimming.pool.for.genders" />
                        </p>
                      </div>
                    )}
                    {bookingBeforeYouGo.urgentInfo.alcoholRestricted && (
                      <div className="condo-booking-confirmed__know-text-wrapper">
                        <p className="condo-booking-confirmed__know--text">
                          <FormattedMessage id="alcohol.restricted" />
                        </p>
                      </div>
                    )}
                    {bookingBeforeYouGo.urgentInfo.alcoholServedOnsite && (
                      <div className="condo-booking-confirmed__know-text-wrapper">
                        <p className="condo-booking-confirmed__know--text">
                          <FormattedMessage id="alcohol.served.onsite" />
                        </p>
                      </div>
                    )}
                    {bookingBeforeYouGo.urgentInfo.resortStayRestrictionsInfo && (
                      <div className="condo-booking-confirmed__know-text-wrapper">
                        <p className="condo-booking-confirmed__know--text">
                          {parse(bookingBeforeYouGo.urgentInfo.resortStayRestrictionsInfo)}
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {this.isPossibleApplicableFees() && (
                  <div className="condo-booking-confirmed__know-block">
                    <h4 className="condo-booking-confirmed__know-title">
                      <FormattedMessage id="possible.applicable.fees" />
                    </h4>
                    {!isEmpty(bookingBeforeYouGo.mandatoryFees) && (
                      <div className="condo-booking-confirmed__know-text-wrapper">
                        {bookingBeforeYouGo.mandatoryFees.map((item, index) => (
                          <div key={index} className="condo-booking-confirmed__know-text-block">
                            {item.infoMessage && (
                              <p className="condo-booking-confirmed__know--text">
                                {parse(item.infoMessage)}
                              </p>
                            )}
                            {item.additionalInfoMessage && (
                              <p className="condo-booking-confirmed__know--text">
                                {parse(item.additionalInfoMessage)}
                              </p>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    {!isEmpty(bookingBeforeYouGo.fees) && (
                      <div className="condo-booking-confirmed__know-text-wrapper">
                        {bookingBeforeYouGo.fees.map((item, index) => (
                          <div key={index} className="condo-booking-confirmed__know-text-block">
                            {item.infoMessage && (
                              <p className="condo-booking-confirmed__know--text">
                                {parse(item.infoMessage)}
                              </p>
                            )}
                            {item.additionalInfoMessage && (
                              <p className="condo-booking-confirmed__know--text">
                                {parse(item.additionalInfoMessage)}
                              </p>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    {!isEmpty(bookingBeforeYouGo.housekeepingFees) && (
                      <div className="condo-booking-confirmed__know-text-wrapper">
                        {bookingBeforeYouGo.housekeepingFees.map((item, index) => (
                          <div key={index} className="condo-booking-confirmed__know-text-block">
                            {item.feeSchedule && (
                              <p className="condo-booking-confirmed__know--text">
                                {parse(item.feeSchedule)}
                              </p>
                            )}
                            {item.infoMessage && (
                              <p className="condo-booking-confirmed__know--text">
                                {parse(item.infoMessage)}
                              </p>
                            )}
                            {item.additionalInfoMessage && (
                              <p className="condo-booking-confirmed__know--text">
                                {parse(item.additionalInfoMessage)}
                              </p>
                            )}
                            {item.mandatoryFee && (
                              <p className="condo-booking-confirmed__know--text">
                                <FormattedMessage id="mandatory.fee" />
                              </p>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {!isEmpty(bookingBeforeYouGo.constructionDetails) && (
                  <div className="condo-booking-confirmed__know-block">
                    <h4 className="condo-booking-confirmed__know-title">
                      <FormattedMessage id="resort.construction.restrictions" />
                    </h4>
                    <div className="condo-booking-confirmed__know-text-wrapper">
                      {bookingBeforeYouGo.constructionDetails.map((item, index) => (
                        <div key={index}>
                          {item.startDate && item.endDate && (
                            <p className="condo-booking-confirmed__know--text">
                              {item.startDate} - {item.endDate}
                            </p>
                          )}
                          {item.infoMessage && (
                            <p className="condo-booking-confirmed__know--text">
                              {item.infoMessage}
                            </p>
                          )}
                          {item.additionalInfoMessage && (
                            <p className="condo-booking-confirmed__know--text">
                              {item.additionalInfoMessage}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>) : null}

              <InsuranceConfirmed insurance={booking?.insurance} />

          </div>

          <div className="condo-booking-confirmed__btn-wrapper" style={{ justifyContent: (!disableDownloadVoucherCheckout ? 'space-between' : 'flex-end') }}>
            {!disableDownloadVoucherCheckout ? (
              <BlueButton onClick={this.handleDownloadVoucher}>
                <FormattedMessage id="download.voucher" />
              </BlueButton>) : null}
            <Link to={`/${account?.name}${Routes.CondoSearch}${location.search}`}>
              <BlueButton>
                <FormattedMessage id="find.another.condo" />
              </BlueButton>
            </Link>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoReviewBookStore: state.condoReviewBookStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = { 
  setLoginRedirect,
  voucherBooking,
  downloadVoucher
};

export const BookingConfirmed = connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingConfirmedComponent));
