
import TextArea from 'antd/lib/input/TextArea';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox } from 'antd';

import { get, isEmpty } from 'lodash';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { FunctionalRootState, getKeyCancellationFromPath } from '@share/utils';

import { getCancelReservation, IReservationCancellationState } from '@share/store/slices';
import { RedButton } from '@components';

import { ReservationCancellationPolicies } from '../reservation-cancellation-policies';

import './style.scss';

export function ReservationCancellation() {

  const [isAccept, setIsAccept] = useState(false);
  const [note, setNote] = useState('');

  const { reservationCancellationDetails, loadingCancelReservation }: IReservationCancellationState = useSelector((state: FunctionalRootState) => state.reservationCancellationStore);
  
  const dispatch = useDispatch();

  const intl = useIntl();

  const handleCancelReservation = (): void => {    
    const tempKey = getKeyCancellationFromPath();

    dispatch(getCancelReservation(tempKey, note, () => setNote('')));
  };

  const onChangeAccept = (e: { target: CheckboxChangeEventTarget }): void => {
    setIsAccept(e.target.checked);
  };

  const orderId = get(reservationCancellationDetails?.reservationDetails, 'orderId');
  const supportPhone = get(reservationCancellationDetails?.reservationDetails, 'supportPhone');
  const message = intl.formatMessage({ id: 'please.enter.comment' });
  return (
    <div className="reservation-cancellation">
      <p className="reservation-cancellation__title-text">
        <FormattedMessage id="cancel.booking.number" />:{' '}
        <span className="reservation-cancellation__number">{orderId}</span>
      </p>
      <ReservationCancellationPolicies />
      <p className="reservation-cancellation__refund-text">
        <FormattedMessage id="refund.can.take.up.to" />
      </p>
      <p className="reservation-cancellation__contact-property">
        <FormattedMessage id="support" />:{' '}
        <span className="reservation-cancellation__contact-property-phone">{supportPhone}</span>
      </p>
      <div className="reservation-cancellation__footer" style={{ display: 'flex', flexDirection: 'column' }}>
        <TextArea
          value={note}
          onChange={e => setNote(e.target.value)}
          placeholder={message}
          rows={4}
        >
        </TextArea>

        {isEmpty(note) && (<label style={{ color: 'red', marginTop: '5px' }}>{message}</label>)}
      </div>
      <div className="reservation-cancellation__footer">
        <div className="reservation-cancellation__footer-text">
          <Checkbox onChange={onChangeAccept} />
          <div className="reservation-cancellation__wrapper-accept-text">
            <span className="reservation-cancellation__accept-text">
              <FormattedMessage id="by.clicking.box.you.acknowledge" />
            </span>
            <span className="reservation-cancellation__accept-text bold">
              <FormattedMessage id="cancellation.policy.booking.remarks" />
            </span>
          </div>
        </div>
        <div className="reservation-cancellation__btn-wrapper">
          <RedButton
            onClick={handleCancelReservation}
            disabled={!isAccept || isEmpty(note)}
            loading={loadingCancelReservation}
          >
            <FormattedMessage id="cancel.reservation" />
          </RedButton>
        </div>
      </div>
    </div>
  );
}
