import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ICondoReviewBookState, IInsuranceState, InsuranceSelection, setSelectedCondoReviewClientCashStr, setSelectedCondoReviewClientCash } from '@store/slices';
import { ICondosState, ILoginState, IMenuState, condosActions } from '@share/store/slices';
import { ClockSvg,InfoSvg } from '@assets';
import { RootState, formatMoneyCurrency, getSelectedCurrency, renderNumber } from '@share/utils';
import { Currency, PriceSkeleton, UnitMainInfo, WalletInput, WalletMessage } from '@components';
import { CondoDiscountCode } from '../condo-discount-code';

import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { USD_CURRENCY } from '@constants';
import { get, isEmpty } from 'lodash';

import './style.scss';
import { IAccount, IClientCash, IUserWallet } from '@share/common-types';
import { WalletPriceType, getWalletPrices } from '@utils';

interface IMapStateToProps {
  condosStore: ICondosState;
  condoReviewBookStore: ICondoReviewBookState;
  navigationMenuStore: IMenuState;
  loginStore: ILoginState;
  insuranceStore: IInsuranceState;
}

interface IMapDispatchToProps {
  setSelectedCondoReviewClientCash: (clientCash: number) => void;
  setSelectedCondoReviewClientCashStr: (clientCash: string) => void;
  setSelectedCondoSearchClientCash: (clientCash: IClientCash) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {}

const ZERO = 0;
const ONE = 1;

class CondoUnitInfoComponent extends React.Component<IProps> {

  handleOnSelectClientCash = (clientCash: string) => {
    const { condosStore } = this.props;
    const { selectedCondoSearchClientCash } = condosStore;

    this.props.setSelectedCondoReviewClientCash(Number(clientCash));
    this.props.setSelectedCondoReviewClientCashStr(clientCash ? clientCash.toString() : '');
    this.props.setSelectedCondoSearchClientCash({ ...selectedCondoSearchClientCash, selectedPropertyReviewClientCash: Number(clientCash) });
  }

  render(): React.ReactNode {
    const { condosStore, loginStore, navigationMenuStore, condoReviewBookStore, insuranceStore, intl } = this.props;
    const { condoBookingSummary, isUpdatePrice, loadingBooking, bookingComplete, selectedCondoReviewClientCash, selectedCondoReviewClientCashStr, booking } = condoReviewBookStore;
    const { items } = navigationMenuStore;
    const { rating, supplierIdentifier, refundability } = condoBookingSummary;
    const { account, userWallet, userWalletData } = loginStore;
    const { selection, insurance, baseRequest } = insuranceStore;
    const {
      name,
      fullAddress,
      titleImage,
      unitName,
      unitDescription,
      nightsCount,
      totalGuests,
      totalChildren,
      totalBedrooms,
      totalBathrooms,
      checkIn,
      checkOut,
      bookingPrice,
      maxOccupancy,
      balance,
      currency,
    } = condoBookingSummary.bookingCard;
    const { maxWalletClientCash, publicPrice, savings, payNowPrice, totalTaxes, totalLeisureCredits } = bookingPrice;
    const { selectedCondoSearchClientCash } = condosStore;
    const isNotification = loadingBooking;

    const currencyStore = getSelectedCurrency(account as IAccount);
    const currencySelected = currency ? currency : !isEmpty(currencyStore) ? currencyStore : USD_CURRENCY;

    const factor = Math.pow(10, 0);
    const savingsPercent = !!savings && !!publicPrice ? Math.floor(((savings / publicPrice) * 100) * factor) / factor : 0;

    const hasClientCash = account?.hasClientCash;
    const clientCashPercentage = account?.clientCashPercentage;
    const isLogged = !!userWallet;

    const isMLM = items?.isMLM;
    const payNowPriceInt = payNowPrice;

    const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;

    const usedClientCashAmount = (bookingComplete && booking) ? get(booking, 'clientCash', 0) : 0;
    const clientCashCalculated = bookingComplete ? usedClientCashAmount : (selectedCondoReviewClientCash ? selectedCondoReviewClientCash : 0);
    const clientCash = clientCashCalculated * convertionRate;
    const finalBalanceIntermediate = payNowPriceInt - clientCash;
    const finalBalanceCalculated = Math.floor(finalBalanceIntermediate * 100) / 100;
    const finalBalance = finalBalanceCalculated > 0 ? finalBalanceCalculated : 0;
    const eligibleClientCash = finalBalance > 0 ? finalBalance * ((clientCashPercentage && !isEmpty(clientCashPercentage)) ? parseInt(clientCashPercentage) : 0) / 100 : 0;
    const usedClientCashInt = Math.round(usedClientCashAmount / convertionRate);
    const usedClientCash = account?.walletNoDecimals ? Math.floor(usedClientCashInt) : usedClientCashInt;
    
    const maxWalletClientCashInt = maxWalletClientCash;

    const walletPrices = getWalletPrices(account as IAccount, userWalletData as IUserWallet, selectedCondoSearchClientCash, payNowPriceInt, maxWalletClientCashInt, WalletPriceType.Book);

    const maxWalletClientCashService = walletPrices?.maxClientCashAllow ? walletPrices?.maxClientCashAllow / convertionRate : 0;
    const maxWalletClientCashValue = maxWalletClientCashInt / convertionRate;
    const maxWalletClientCashFinal = (maxWalletClientCashValue && maxWalletClientCashValue <= maxWalletClientCashService) ? maxWalletClientCashValue : maxWalletClientCashService;
    const maxWalletClientCashCalculated = account?.walletNoDecimals ? Math.floor(maxWalletClientCashFinal) : maxWalletClientCashFinal;

    const displayWalletSavings = (account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && !!walletPrices?.maxClientCashAmountAllow) || isMLM;

    const insuranceBalance = (selection === InsuranceSelection.YES && !!insurance?.productDetails?.price) ? insurance?.productDetails?.price : 0;
    
    return (
      <div className="unit-info">
        <div className="unit-info__wrapper">
          <UnitMainInfo
            imageUrl={titleImage}
            hideImage={true}
            renderOnlyImage={true}
            isAllInclusive={false}
            savingsPercent={savingsPercent}
          />
        </div>
        <div className="position-sticky">
          <div className="unit-info__wrapper" style={{ borderTop: '1px solid #FFFFFF' }}>
            <UnitMainInfo
              imageUrl={titleImage}
              data={{
                title: name,
                address: fullAddress,
                unitName: unitName,
                unitDescription: unitDescription,
                adultsCount: totalGuests - totalChildren,
                childrenCount: totalChildren,
                bedroomsCount: totalBedrooms,
                bathroomsCount: totalBathrooms as number,
                maxOccupancy: maxOccupancy,
                checkIn: checkIn,
                checkOut: checkOut,
                nightsCount: nightsCount,
                price: bookingPrice?.publicPrice,
                checkOutTime: condoBookingSummary?.bookingPolicies?.checkInCheckOutInfo?.checkOutTime,
                checkInTime: condoBookingSummary?.bookingPolicies?.checkInCheckOutInfo?.checkInTime,
                isNotification: isNotification,
                bookingComplete: bookingComplete,
                rating: rating,
                supplierIdentifier: supplierIdentifier,
                walletPrices,
                refundability
              }}
              hideImage={true}
            />
          </div>
          <div className="unit-info__wrapper" style={{ marginTop: '15px' }}>
            <div className="unit-info__content border-content" style={{ paddingBottom: '20px' }}>
              <h4 className="unit-info__price-title secondary-font">
                <FormattedMessage id="price.details" />
              </h4>
              {isUpdatePrice ? <PriceSkeleton /> : null}
              {!isUpdatePrice ? (
                <>
                  <div className="unit-info__price-info">
                    <p className="unit-info__price-info-text">
                      <FormattedMessage id={publicPrice ? 'public.price.details' : 'total.to.pay'} />:{' '}
                      <FormattedMessage id="unit.count" values={{ count: ONE }} /> x{' '}
                      <FormattedMessage id="nights" values={{ count: nightsCount }} />
                    </p>
                    <p className="unit-info__price-info-text">
                      {formatMoneyCurrency(currencySelected).format(publicPrice ? publicPrice : payNowPriceInt)}
                    </p>
                  </div>

                  {(savings && (!displayWalletSavings || account?.isMLM)) ? (
                    <div className="unit-info__price-info">
                      <p className="unit-info__price-savings-text">
                        <FormattedMessage id={items?.isPromoSite ? 'points' : items?.promo ? 'savings' : 'member.savings.label'} />
                      </p>
                      <p className="unit-info__price-savings-text">
                        -{formatMoneyCurrency(currencySelected).format(savings)}
                      </p>
                    </div>) : null}

                  {(displayWalletSavings) ? (
                    <div className="unit-info__price-info">
                      <p className="unit-info__price-savings-text">
                        <FormattedMessage id="wallet.save.up.to" values={{ clientCashName: account?.walletClientCashName }} />
                      </p>
                      <p className="unit-info__price-savings-text">
                        -<Currency currency={currency as string} />{renderNumber(walletPrices?.clientCashAmountApplied, 2)}
                      </p>
                    </div>) : null}

                  {(selection === InsuranceSelection.YES && !!insurance) ? (
                    <div className="unit-info__price-info">
                      <p className="unit-info__price-info-text">
                        <FormattedMessage id="insurance.booking.protection" />
                      </p>
                      <p className="unit-info__price-info-text">
                        <Currency currency={baseRequest?.totalPrice?.isoCurrencyCode ? baseRequest?.totalPrice?.isoCurrencyCode : USD_CURRENCY} />{renderNumber(insurance?.productDetails?.price, 2)}
                      </p>
                    </div>) : null}

                  {!!totalLeisureCredits && items?.pointsSettings?.condo?.isRemainingBalanceEnabled && !account?.isMLM ? (
                    <div className="unit-info__price-credits">
                      <p className="unit-info__price-credits-text">
                        <FormattedMessage id="leisure.credits.balance" /> {totalLeisureCredits}
                      </p>
                    </div>) : null}

                  {hasClientCash ? (
                    <>
                      {(!account?.walletIsExternal && !items?.isMLM) ? (
                        <div className="unit-info__price-info">
                          <p className="unit-info__price-client-cash-text">
                            <FormattedMessage id="wallet.client.cash.eligible" values={{ value: clientCashPercentage, clientCashName: account?.walletClientCashName }} />
                            <Tooltip title={intl.formatMessage({ id: 'wallet.client.cash.eligible.tooltip' })} placement="topLeft">
                              <FontAwesomeIcon icon={faInfoCircle} size="1x" style={{ marginLeft: '5px' }} />
                            </Tooltip>
                          </p>
                          <p className="unit-info__price-client-cash-text">
                            <Currency currency={currencySelected} />{renderNumber(eligibleClientCash, 2)}
                          </p>
                        </div>) : null}

                      {(bookingComplete) && (
                        <>
                          <div className="unit-info__price-info">
                            <p className="unit-info__price-client-cash-text">
                              <FormattedMessage id="wallet.client.cash.applied" values={{ clientCash: usedClientCash, clientCashName: account?.walletClientCashName }} />
                            </p>
                            <p className="unit-info__price-client-cash-text">
                              <Currency currency={currency as string} />{renderNumber(usedClientCashAmount, 2)}
                            </p>
                          </div>
                        </>)}
                    </>) : null}
                </>
              ) : null}
            </div>

            <WalletInput
              display={((hasClientCash && isLogged && !bookingComplete) || (isMLM && !bookingComplete)) as boolean}
              bottomBorder={true}
              currency={currency as string}
              maxWalletClientCash={maxWalletClientCashCalculated}
              price={payNowPriceInt}
              selectedClientCash={selectedCondoReviewClientCash as number}
              selectedClientCashStr={selectedCondoReviewClientCashStr as string}
              onSelectClientCash={this.handleOnSelectClientCash}
            />

            {!isUpdatePrice && (
              <>
                {!items?.promo && (
                  <div className="unit-info__content border-content">
                    <CondoDiscountCode />
                  </div>)}

                {!account?.isMLM ? (
                  <WalletMessage
                    price={payNowPriceInt}
                    currency={currency as string}
                    maxClientCash={maxWalletClientCashCalculated}
                    type={WalletPriceType.Book}
                    displayOnlyRate={bookingComplete}
                    isOneLine
                    isCondo
                    style={{ paddingLeft: '15px', paddingRight: '15px', marginBottom: '15px' }}
                  />) : null}

                {(account?.walletDisplayGetMoreClientCash && walletPrices?.maxClientCashAllow > walletPrices?.maxClientCashPermited) ? (
                  <div className="unit-info__content pb-15" style={{ paddingBottom: '0px', color: 'red', fontWeight: 'bold' }}>
                    <FormattedMessage id="wallet.client.get_more" values={{ diff:  walletPrices?.maxClientCashAllow - walletPrices?.maxClientCashPermited, clientCashName: account?.walletClientCashName }} />
                  </div>) : null}

                <div className="unit-info__content pb-15">
                  <div className="unit-info__total-price-info-wrapper">
                    <div className="unit-info__total-price-info">
                      <p className="unit-info__price-info-text-total">
                        <FormattedMessage id={bookingComplete ? 'total.paid' : 'total.pay'} />:
                      </p>
                    </div>
                    <div className="unit-info__price-info-amount-wrapper">
                      {(!bookingComplete && publicPrice) ? (
                        <p className="unit-info__public-price-amount">
                          {formatMoneyCurrency(currencySelected).format(publicPrice)}
                        </p>) : null}
                      <p className="unit-info__pay-now-rice-amount">
                        {formatMoneyCurrency(currencySelected).format(finalBalance + insuranceBalance)}
                      </p>
                    </div>
                  </div>
                  {totalTaxes !== ZERO ? (
                    <p className="unit-info__note">
                      <FormattedMessage id="including.taxes.fees" />{' '}
                      {formatMoneyCurrency(currencySelected).format(totalTaxes)}
                    </p>
                  ) : (
                    <p className="unit-info__note">
                      <FormattedMessage id="zero.charge.for.taxes.fees" />
                    </p>
                  )}
                </div> 
              </>
            )}
            <div className="unit-info__label">
              <p className="unit-info__label-text bold">
                <InfoSvg />
                <FormattedMessage id="additional.charges.may.apply.upon" />
              </p>
              <p className="unit-info__label-text">
                <FormattedMessage id="refer.to.know.before.you.go" />
              </p>
            </div>
          </div>
          <div className="unit-info__label-footer">
            <ClockSvg />
            <p className="unit-info__label-text notification-text">
              <FormattedMessage id="act.fast" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condosStore: state.condosStore,
    condoReviewBookStore: state.condoReviewBookStore,
    navigationMenuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    insuranceStore: state.insuranceStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedCondoReviewClientCash,
  setSelectedCondoReviewClientCashStr,
  setSelectedCondoSearchClientCash: condosActions.setSelectedCondoSearchClientCash,
};

export const CondoUnitInfo = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CondoUnitInfoComponent));
