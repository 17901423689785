

import './style.scss';

interface IProps {
  hide?: boolean;
}

export function Loading(props: IProps) {
  const { hide } = props;

  if (hide) {
    return null;
  }

  return (
    <div className="w-100 h-100 preloader">
      <div id="loader" className="loader">
        <div className="loader-container">
          <div className="loader-icon text-center">
            <div className="imagecarousel">
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
