import React from 'react';
import { FormattedMessage } from 'react-intl';
import GetawayBackground from '@assets/images/getaways/getaway-img-header.jpg';
import './style.scss';

export class GetawayHeader extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="getaway__header" style={{ backgroundImage: `url(${GetawayBackground})` }}>
        <div className="getaway__container">
          <h1 className="getaway__header_title secondary-font">
            <FormattedMessage id="getaway.title" />
          </h1>
          <p className="getaway__header_subtitle">
            <FormattedMessage id="getaway.subtitle" />
          </p>
        </div>
      </div>
    );
  }
}
