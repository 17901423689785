import { AxiosPromise } from 'axios';
import { Toaster, axiosInstance, getHeaders } from '@share/utils';
import { Urls } from '@share/constants';
import { isEmpty } from 'lodash';

export const QuotesAPI = {
  get(id: string): AxiosPromise<any> {
    return axiosInstance.get(`${Urls.Quotes}/${id}`, { ...getHeaders() });
  },
};

export enum DownLoadShareType {
  CONDO,
  HOTEL,
  WEEK,
  CAR
}

export const HandleDownload = (
    type: DownLoadShareType,
    loading: boolean,
    data: any,
    intl: any,
    onFinish: () => void,
    onComplete: () => void,
    onFailed: (message: string[] | undefined) => void) => {
  if (!loading) {
    const url = type === DownLoadShareType.WEEK ? Urls.QuotesWeeksDownload : type === DownLoadShareType.CONDO ? Urls.QuotesCondosDownload : Urls.QuotesHontelsDownload;
    axiosInstance.post(url, data, { ...getHeaders(), responseType: 'blob' })
      .then((response) => {
        const headerLine = response.headers['content-disposition'];
        let filename;
        if (headerLine) {
          const startFileNameIndex = headerLine.indexOf('"') + 1
          const endFileNameIndex = headerLine.lastIndexOf('"');
          filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        } else {
          filename = `quote_${new Date().getTime()}_download.pdf`;
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        onFinish();
        onComplete();
        link.click();
      })
      .catch(async (e) => {
       let errorObject = null;
       try {
          const error = e.response?.data;
          const errorBlobText = JSON.parse(await error.text());
          errorObject = JSON.parse(errorBlobText);
        } catch(e) {
          console.error(e);
        }

        const msgCode = errorObject?.code;
        const messages = errorObject?.errors;

        if (msgCode === 'room_error' && onFailed) {
          onFailed(messages?.filter((m: any) => !isEmpty(m.message)).map((m: any) => m.message.substring(0, m.message.indexOf(': It was not possible to obtain'))));
        } else {
          onFinish();
          onComplete();

          if (!!messages?.length) {
            messages.forEach((m: any) => {
              Toaster.error(m?.message);
            });
          } else {
            const msg = errorObject?.reason ? 
                            `Quote send failed: ${errorObject.reason}` : 
                            intl.formatMessage({ id: 'error.code.generic.general' });
            Toaster.error(msg);
          }
        }

      });
  }
}

export const HandleShare = (
    type: DownLoadShareType,
    loading: boolean,
    data: any,
    intl: any,
    onFinish: () => void,
    onComplete: () => void,
    onFailed: (message: string[] | undefined) => void) => {
  if (!loading) {
    const url = type === DownLoadShareType.WEEK ? Urls.QuotesWeeksShare : type === DownLoadShareType.CONDO ? Urls.QuotesCondosShare : Urls.QuotesHotelsShare;
    axiosInstance.post(url, data, { ...getHeaders() })
      .then((response) => {
        onFinish();
        onComplete();

        if (response.data.members?.length) {
          Toaster.success(`Success. Quote shared to: ${response.data.members.map((x:string) => (x))}`);
        }

        if (response.data.memberFailed?.length) {
          Toaster.error(`Quote failed for: ${response.data.memberFailed.map((x:string) => (x))}`);
        }
      })
      .catch((e) => {
        const error = e.response?.data;

        let errorObject = null;
        try {
          errorObject = JSON.parse(error);
        } catch(e) {
          console.error(e);
        }

        const msgCode = errorObject?.code;
        const messages = errorObject?.errors;

        if (msgCode === 'room_error' && onFailed) {
          onFailed(messages?.filter((m: any) => !isEmpty(m.message)).map((m: any) => m.message.substring(0, m.message.indexOf(': It was not possible to obtain'))));
        } else {
          onFinish();
          onComplete();

          if (!!messages?.length) {
            messages.forEach((m: any) => {
              Toaster.error(m?.message);
            });
          } else {
            const msg = errorObject?.reason ? 
                            `Quote send failed: ${errorObject.reason}` : 
                            intl.formatMessage({ id: 'error.code.generic.general' });
            Toaster.error(msg);
          }
        }
      });
  }
}
