
import React from 'react';

import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';

import GeneralError from '@share/assets/images/general-error.png';
import NoHotelResults from '@share/assets/images/no_hotel_results.png';
import NoCarsResults from '@share/assets/images/no_cars_results.png';

import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { BookingErrorsEnum } from '../../common-types/condos';
import { getAccountUsernameFromPath, RootState } from '@share/utils';
import { connect } from 'react-redux';
import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {
  message?: string;
  displayExclamationIcon?: boolean;

  isCars?: boolean;
}

const SODL_OUT = 'SOLDOUT';

const MESSAGE_EXCLUDE = [SODL_OUT, 'SESSIONEXPIRED', 'UNAUTHORIZED', 'WIDGET_ERROR', 'INVALIDSESSION', 'cars.search.error'];
const BUTTON_INCLUDE = [SODL_OUT, BookingErrorsEnum.CarUnavailable];
const BUTTON_QUOTE_INCLUDE = ['QUOTE_ERROR'];

class CustomErrorPageComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const state: any = this.props.history.location.state;

    let message = this.props.message;
    if (isEmpty(message)) {
      if (state?.data) {
        message = state.data;
      }
    }

    const displayMessages = message && !MESSAGE_EXCLUDE.includes(message);
    const displayGoBackSearchButton = message && BUTTON_INCLUDE.includes(message);
    const displayGoBackQuoteButton = message && BUTTON_QUOTE_INCLUDE.includes(message);

    const image = message === SODL_OUT ? GeneralError : this.props.isCars ? NoCarsResults : NoHotelResults;

    const accountNamePath = getAccountUsernameFromPath(this.props.history);

    const accountNameStore = this.props.loginStore?.account;

    const accountName = !isEmpty(accountNamePath) ? 
                            accountNamePath :
                            accountNameStore ? 
                              accountNameStore?.name :
                              null;

    return (
      <div className="error-page">
        {this.props.displayExclamationIcon ? (
          <label style={{ color: 'red' }}><FontAwesomeIcon icon={faTriangleExclamation} size="4x" /></label>) : (
          <img className="error-page__image" src={image} />)}
        {isEmpty(message) ? (<label className="error-page__title"><FormattedMessage id={'error.custom.error.title'} /></label>) : null}
        {!isEmpty(message) ? (<label className="error-page__external-message"><FormattedMessage id={`error.code.${message}`} /></label>) : null}
        {displayMessages ? (<label className="error-page__message"><FormattedMessage id={'error.custom.error.retry'} /></label>) : null}
        {displayMessages ? (<label className="error-page__message-retry"><FormattedMessage id={'error.custom.error.contact_support'} /></label>) : null}
        {displayGoBackSearchButton ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', maxWidth: '1020px', paddingRight: '20px', paddingBottom: '20px' }}>
            <BlueButton style={{ padding: '8px 30px' }} onClick={() => this.props.history.push(`/${accountName}${this.props.isCars ? Routes.CarsSearch : Routes.Search}${location.search}`)}>
              <FormattedMessage id={'go.back.search'} />
            </BlueButton>
          </div>) : null}
        {displayGoBackQuoteButton ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', maxWidth: '1020px', paddingRight: '20px', paddingBottom: '20px' }}>
            <BlueButton style={{ padding: '8px 30px' }} onClick={() => this.props.history.push(`/${accountName}${Routes.RsiQuotes}`)}>
              <FormattedMessage id={'go.back.quote'} />
            </BlueButton>
          </div>) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const CustomErrorPageRouter = withRouter(CustomErrorPageComponent);


export const CustomErrorPage = connect(mapStateToProps)(CustomErrorPageRouter);

