
import { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { axiosInstance, UrlUtils } from '@share/utils';
import { Routes, Urls } from '@share/constants';
import { Loading } from '@share/components';
import { ICondoWeeksFilters } from '@common-types';
import { APP_SELECTOR } from '@constants';

import { CondoWeeksCard } from '../condo-week-card';
import { CondoWeeksEmpty } from '../condo-weeks-empty';

import CondoWeeks from '@assets/images/condo-weeks.jpg';

import { CondoWeeksFilters } from '../condo-week-filters';
import { INITIAL_FITLERS, ProcessFilterParams } from './utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import { FormattedMessage } from 'react-intl';

const QUERY_PARAM = 'queryHash';
const REDIRECT_QUERY_PARAM = 'redirect';
const PAGE = 10;
const debounceTime = 300;
const scrollPixels = 450;

interface IProps extends RouteComponentProps {
  isWeeks?: boolean;
}

function CondoWeeksWrapperComponent(props: IProps) {

  const [loading, setLoading ] = useState(false);
  const [error, setError ] = useState(false);
  const [results, setResults ] = useState([]);
  const [filters, setFilters ] = useState({ ...INITIAL_FITLERS });
  const [currentPage, setCurrentPage ] = useState(1);
  const [totalOrderCount, setTotalOrderCount ] = useState(0);

  const currentPageRef = useRef(0);
  const totalOrderCountRef = useRef(0);
  const resultsRef = useRef([]);
  const filtersRef = useRef(filters);

  const { isWeeks, history } = props;

  const root = document.querySelector(APP_SELECTOR) as HTMLDivElement;

  const queryHash = useMemo(() => UrlUtils.getSearchParam(QUERY_PARAM), []);
  const redirect = useMemo(() => UrlUtils.getSearchParam(REDIRECT_QUERY_PARAM), []);

  useEffect(() => {
    
    if (!isWeeks && isEmpty(queryHash)) {
      history.push(Routes.NotAuthorized);
    }

    setLoading(true);
    setResults([]);
    setCurrentPage(1);

    handleCall(filters);

    window.addEventListener('scroll', onScroll); 

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, []);

  useEffect(() => { currentPageRef.current = currentPage; }, [currentPage]);
  useEffect(() => { resultsRef.current = results; }, [results]);
  useEffect(() => { totalOrderCountRef.current = totalOrderCount; }, [totalOrderCount]);
  useEffect(() => { filtersRef.current = filters; }, [filters]);

  const onScroll = debounce(() => {
    const rootHeight = root.scrollHeight ? root.scrollHeight : root.offsetHeight;
    if (root && window.innerHeight + window.scrollY + scrollPixels >= rootHeight) {
      if (resultsRef?.current?.length < totalOrderCountRef?.current) {
        const newPage = currentPageRef.current + 1;

        setCurrentPage(newPage);

        handleCall(filtersRef.current, newPage);
      }
    }
  }, debounceTime);

  const handleFilterChange = (filters: ICondoWeeksFilters) => {
    handleCall(filters);
  }

  const handleClearFilter = () => {
    setResults([]);
    setCurrentPage(1);

    handleCall({ ...INITIAL_FITLERS });
  }

  const handleCall = (filters?: ICondoWeeksFilters, currentPage = 1) => {
    setLoading(true);
    setError(false);

    const filterParams = ProcessFilterParams(filters as ICondoWeeksFilters);
    const url = `${Urls.MarketingWeeks}?page=${currentPage}&results=${PAGE}${isWeeks ? '' : `&${QUERY_PARAM}=${queryHash}`}${filterParams.params}`;
    axiosInstance.get(url)
      .then((resp) => {
        const results = resp.data?.items?.length ? resp.data.items : [];

        setResults(results);
        setTotalOrderCount(resp.data?.totalOrderCount);
        setFilters({ ...filters, applied: filterParams.filterApplied } as ICondoWeeksFilters);
      }).catch((err) => {
        console.error(err);

        setError(true);

        if (!isWeeks) {
          props.history.push(Routes.BasicError);
        }

        setResults([]);
        setCurrentPage(1);    
      }).finally(() => {
        setLoading(false);
      });
  }

  const filtersApplied = filters?.applied;

  if (isWeeks && !loading && (error || (!results?.length && !filtersApplied))) {
    return null;
  }

  return (
    <div className="condo-weeks-page">
      
      {!isWeeks && (
        <div className="py-2 container-fluid dark-overlay" style={{ backgroundImage: `url(${CondoWeeks})`, backgroundPosition: 'center left', backgroundSize: 'cover' }}>
          <div className="row">
            <div className="col-md-6 mx-auto text-center pt-6 pb-4">
              <h1 className="text-white"><FormattedMessage id="marketing.weeks.title" /></h1>
              <p className="lead text-white mb-0"><FormattedMessage id="marketing.weeks.subtitle" /></p>
            </div>
          </div>
        </div>)}

      <div className="condo-weeks-page__content">
        <div className="container-fluid">
          {(loading) ? (
            <div className="loading-container">
              <Loading />
            </div>) : null}

          {!loading ? (
            <>
              <div className="row mb-6 mt-4">
                <div className="col-12 text-center">
                  <h3 className="mb-0" style={{ paddingBottom: '10px' }}><FormattedMessage id="marketing.weeks.title.2" /></h3>
                  <p><FormattedMessage id="marketing.weeks.subtitle.2" /></p>
                </div>
              </div>

              {(results?.length || filtersApplied) ? (
                <div className="row">
                  <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
                    <CondoWeeksFilters filters={filters} onFiltersChange={handleFilterChange} />
                  </div>

                  {filtersApplied ? (
                    <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
                      <label style={{ cursor: 'pointer', color: 'red' }} onClick={handleClearFilter}><FormattedMessage id="marketing.weeks.clear_filters" /> <FontAwesomeIcon icon={faTimesCircle} /> </label>
                    </div>) : null}
                </div>) : null}

              <div className="row">
                {(!isWeeks && (results?.length || filtersApplied)) ? (
                  <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
                    <FormattedMessage id={isWeeks ? 'marketing.weeks.displaying.weeks' : 'marketing.weeks.displaying.condos'} values={{ count: results?.length, total: totalOrderCount }} />
                  </div>) : null}

                {results?.map((c, i) => (
                  <div className="col-lg-6" key={`index_card_${i}`} style={{ display: 'flex', justifyContent: 'center' }}>
                    <CondoWeeksCard isWeeks={isWeeks as boolean} condo={c} redirect={redirect as string} />
                  </div>))}

                {!results?.length ? (
                  <CondoWeeksEmpty filtersApplied={filtersApplied} />) : null}
              </div>

            </>) : null}
        </div>
      </div>
    </div>
  );
}

const ApplicationComponentWithRouter = withRouter(CondoWeeksWrapperComponent);

export const CondoWeeksWrapper = connect()(ApplicationComponentWithRouter);
