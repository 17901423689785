
import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Action } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from '@share/utils';
import { IMyProfileState, deleteUserCard } from '@share/store/slices';
import { ModalConfirmation } from '@components';
import { IUserCard } from '@share/common-types';
import { GetCreditCardImage } from '@utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IMapStateToProps {
  myProfileStore: IMyProfileState;
}

interface IMapDispatchToProps {
  deleteUserCard: (userCard: IUserCard, sucessCallBack: () => void) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  card: IUserCard;
  hideDelete?: boolean;
}

function CreditCardItemComponent(props: IProps) {

  const { card, myProfileStore, hideDelete, deleteUserCard } = props;
  const { loadingCards } = myProfileStore;

  const [ showConfirmation, setShowConfirmation ] = useState(false);
  const [ selectedCard, setSelectedCard ] = useState(null);

  const handleConfirmDelete = () => {
    deleteUserCard(selectedCard, () => {
      setSelectedCard(null);
    });
    setShowConfirmation(false);
  }

  const year = card.expireDate?.substring(card.expireDate.length - 2);
  const month = card.expireDate?.substring(0, 2);

  return (
    <>
        <div className="credit-cards-list__credit-card-item">
          <div className="input-card" style={{ marginRight: '20px', width: '41px', height: '28px' }}>
            {GetCreditCardImage(card.cardType, true)}
          </div>

          <label>**** **** **** {card.cardNumber?.substring(card.cardNumber.length - 4)}</label>
          <label style={{ marginLeft: '20px' }}>(EXP: {month}/{year})</label>

          {!hideDelete ? (
            <div className="credit-cards-list__credit-card-delete" onClick={() => {
              setShowConfirmation(true);
              setSelectedCard(card);
            }}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>) : null}
        </div>

        <ModalConfirmation
          message="confirm.delete.credit.card"
          title="delete.credit.card"
          yesMessage="confirm"
          noMessage="cancel"
          visible={showConfirmation}
          disabled={loadingCards}
          onCancel={() => {
            if (!loadingCards) {
              setShowConfirmation(false);
              setSelectedCard(null);
            }
          }}
          onConfirm={handleConfirmDelete}
        />
    </>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    myProfileStore: state.myProfileStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  deleteUserCard: (userCard: IUserCard, sucessCallBack: () => void) => {
    dispatch(deleteUserCard(userCard, sucessCallBack));
  }
});

const ComponentIntl = injectIntl(CreditCardItemComponent);

export const CreditCardItem = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
