import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { CondoDestination } from '@share/common-types';
import { AppThunk, axiosInstance, getHeaders } from '@share/utils';
import { MEXICO, Urls, USA, VICINITY } from '@share/constants';
import { ICountriesCode, SupplierType } from '@share/common-types';

export interface ICondosStaticState {
  allDestinations: CondoDestination[];
  allDestinationCountries: string[];
  allDestinationCountryStates: any | null | undefined;
  condosCountriesCode: ICountriesCode | null | undefined;
  suppliers: string[];
}

const initialState: ICondosStaticState = {
  allDestinations: [],
  allDestinationCountries: [],
  allDestinationCountryStates: null,
  condosCountriesCode: null,
  suppliers: [
    SupplierType.Rci,
    SupplierType.Intervals,
    SupplierType.CondoWarehouse,
    SupplierType.CapitalVactions,
    SupplierType.Guesty,
    SupplierType.HolidaySystems
  ]
};

const condosStaticSlice = createSlice({
  name: 'condos',
  initialState,
  reducers: {
    setAllDestinations: (state: ICondosStaticState, { payload }: PayloadAction<CondoDestination[]>) => {
      state.allDestinations = payload;
    },
    setAllDestinationCountries: (state: ICondosStaticState, { payload }: PayloadAction<string[]>) => {
      state.allDestinationCountries = payload;
    },
    setAllDestinationCountryStates: (state: ICondosStaticState, { payload }: PayloadAction<any>) => {
      state.allDestinationCountryStates = payload;
    },
    setCondosCountriesCode: (state: ICondosStaticState, { payload }: PayloadAction<ICountriesCode>) => {
      state.condosCountriesCode = payload;
    },
    setCondosSuppliers: (state: ICondosStaticState, { payload }: PayloadAction<string[]>) => {
      state.suppliers = payload;
    },
  },
});

export const condosStaticActions = condosStaticSlice.actions;

export const condosStaticReducer = condosStaticSlice.reducer;

export const getCountries = (): AppThunk => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.post(
        Urls.CondoCountries,
        {},
        { ...getHeaders() },
      );

      const countries: ICountriesCode = { ...res.data };
      if (countries?.intervalCountries?.length) {
        const intervalCountries = [...countries?.intervalCountries];
        const index = intervalCountries.findIndex(c => c.code === 'USA');
        if (index !== -1) {
          intervalCountries.unshift(intervalCountries.splice(index, 1)[0]);
        }
        countries.intervalCountries = intervalCountries;
      }

      if (countries?.rciCountries?.length) {
        const rciCountries = [...countries?.rciCountries];
        const index = rciCountries.findIndex(c => c.name === 'USA');
        if (index !== -1) {
          rciCountries.unshift(rciCountries.splice(index, 1)[0]);
        }
        countries.rciCountries = rciCountries;
      }

      dispatch(condosStaticActions.setCondosCountriesCode(countries));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAllCondoDestinations = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(Urls.CondoAllDestinations, {
        ...getHeaders(),
      });

      const destinations = [...response.data.map((dest: CondoDestination) => {
        return { ...dest, country: !isEmpty(dest.country) ? dest.country : 'Rest of the world'};
      })];

      let destinationCountries = Array.from(new Set(destinations.map((dest: CondoDestination) => dest.country)).values());

      const destinationsFiltered = destinations.map(d => d.country.includes(VICINITY) ? { ...d, country: d.country.replace(VICINITY,'').trim(), name: `${d.name} ${VICINITY}` } : d);

      const destinationsUS = destinationsFiltered.filter((dest: CondoDestination) => dest.country === USA);

      destinationsUS.sort((a: CondoDestination, b: CondoDestination) => a.state?.localeCompare(b.state));

      destinationCountries = destinationCountries.filter(d => !d.includes(VICINITY) && d !== USA && d !== MEXICO);
      destinationCountries.sort();
      destinationCountries = [ USA, MEXICO, ...destinationCountries ];

      const destinationsStatesUSGrouped = Array.from(new Set(destinationsUS.map(d => d.state)).values());;

      destinationsStatesUSGrouped.sort();
      
      const destinationsGrouped = [...destinationsFiltered];


      dispatch(condosStaticActions.setAllDestinations(destinationsGrouped));
      dispatch(condosStaticActions.setAllDestinationCountries(destinationCountries));
      dispatch(condosStaticActions.setAllDestinationCountryStates({
        [USA]: [...destinationsStatesUSGrouped?.filter(s => !isEmpty(s))],
      }));
    } catch (error) {
      console.error(error);
    }
  };
};
