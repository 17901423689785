import React from 'react';
import { Pagination, Select, Table, TablePaginationConfig } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { IBookingManualState } from '@share/store/slices';
import { ManualBookingData } from '../component';
import { Loading } from '@share/components';

interface IProps {
  bookingManualStore: IBookingManualState;
  pagination:any;
  setPagination:any;
  columns: ColumnsType<ManualBookingData>;
  setSort: any;
}

const ManualBookingTable: React.FC<IProps> = (props: IProps) => {
  const { bookingManualStore, pagination, setPagination, columns, setSort } = props;

 const handleTableChange = (pagination: TablePaginationConfig,
  _filters: Record<string, React.ReactText[] | null>,
  sorter: any = {}) => {
   const newPage = pagination.current || 1;

   if (newPage <= (bookingManualStore?.bookingManual?.totalPages || 1)) {
     setPagination((prev: any) => ({
       ...prev,
       current: newPage,
     }));
   }

   if(sorter.order && sorter.columnKey) {
    setSort({sortOrder: sorter?.columnKey, orderBy: sorter.order === 'ascend' ? 'asc' : 'desc'})
   }
   else {
    setSort({sortOrder: null, orderBy: null})
   }
 };

 const handlePageSizeChange = (newPageSize: number) => {
   setPagination((prevState: any) => ({
     ...prevState,
     pageSize: newPageSize,
     current: 1,
   }));
 };

 const start = (pagination.current! - 1) * pagination.pageSize! + 1;
 const end = Math.min(pagination.current! * pagination.pageSize!, pagination.total!);

 return (
   <section className="mt-2 mb-5">
     <Table
       loading={{
         spinning: bookingManualStore.loadingBookingManual,
         indicator: <Loading />,
       }}
       columns={columns}
       dataSource={bookingManualStore?.bookingManual?.items || []}
       pagination={{
         ...pagination,
         position: [],
       }}
       onChange={handleTableChange}
       scroll={{ x: 'max-content' }}
     />
     <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
       <div style={{ display: 'flex', alignItems: 'center' }}>
         <span>Entries per page: </span>
         <Select
           value={pagination?.pageSize}
           onChange={handlePageSizeChange}
           options={[
             { value: 10, label: '10' },
             { value: 20, label: '20' },
             { value: 40, label: '40' },
           ]}
           style={{ marginLeft: 8 }}
         />
         <span style={{ marginLeft: 16 }}>
           Showing {start} to {end} of {pagination.total} entries
         </span>
       </div>
       <Pagination
         current={pagination.current}
         pageSize={pagination.pageSize}
         total={pagination.total}
         onChange={(page, pageSize) => {
           handleTableChange({ current: page, pageSize });
         }}
       />
     </div>
   </section>
 );
};

export default ManualBookingTable;
