import React, { Component } from 'react';

import axios from 'axios';

import { get, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Switch } from 'antd';

import { Cluster, Renderer } from '@googlemaps/markerclusterer';

import { Map } from '@utils';
import { Responsive } from '@share/utils';
import { Loading } from '@share/components';

import { CondoCard } from './condo-card';

import GeneralError from '@assets/images/general-error.png';
import CondoMarker from '@assets/images/condo-marker.png';
import CondoCluster from '@assets/images/condo-cluster.png';

import './style.scss';

const DEFAULT_MEDIA_POINT = 450;

class ClusterRenderer implements Renderer {
  render(cluster: Cluster): google.maps.Marker {
    return new google.maps.Marker({
      position: cluster.position,
      icon: {
        url: CondoCluster,
      },
      label: {
        text: String(cluster.count),
        color: 'rgba(0, 0, 0)',
        fontSize: '12px',
      },
      title: `Cluster of ${cluster.count} Units`,
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,
    });
  }
}

class ClusterWeeksRenderer implements Renderer {
  render(cluster: Cluster): google.maps.Marker {
    const weeksCount = cluster.markers?.map((m: any) => Number(m.availability)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return new google.maps.Marker({
      position: cluster.position,
      icon: {
        url: CondoCluster,
      },
      label: {
        text: String(weeksCount),
        color: 'rgba(0, 0, 0)',
        fontSize: '12px',
      },
      title: `Cluster of ${weeksCount} Weeks`,
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,
    });
  }
}

const zoom = 4;

const condoMarketIcon = {
  url: CondoMarker,
};

const condoMarketWeeksIcon = {
  url: CondoCluster,
};

export interface ICondoUnitMap {
  ID: number;
  ImageURL: string;
  Latitude: number;
  Longitude: number;
  Resort: string;
  ResortID: string;
  StateCode: string;
  UnitsAvailable: number;
  address: string;
  city: string;
  country: string;
}

interface IProps {
  counterOnParent?: boolean;
}

interface IState {
  selectedCondo: ICondoUnitMap | null;
  selectedMarker: google.maps.Marker | null | undefined;
  condos: ICondoUnitMap[] | null;
  visibleCondos: ICondoUnitMap[] | null | undefined;
  loading: boolean;
  isWeeks: boolean;
  errorMessage: string | null;
  totalUnits: number | null;
  totalWeeks: number | null;
}

export class CondoWorldMap extends Component<IProps, IState> {

  mapRef: React.RefObject<HTMLDivElement> = React.createRef();
  map: google.maps.Map | undefined = undefined;

  state: IState = { selectedCondo: null, condos: null, visibleCondos: null, selectedMarker: null, errorMessage: null, loading: true, totalUnits: null, totalWeeks: null, isWeeks: false };

  componentDidMount(): void {
    const center = { lat: 0, lng:0 };

    const params = new URLSearchParams()
    params.append('Username', import.meta.env.VITE_CONDO_MAP_USERNAME);
    params.append('Password', import.meta.env.VITE_CONDO_MAP_PASSWORD);
    params.append('grant_type', import.meta.env.VITE_CONDO_MAP_GRANT_TYPE);

    const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };

    this.map = Map.initializeMap(this.mapRef?.current, center, { 
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: true,
      maxZoom: 17,
      restriction: {
        latLngBounds: {north: 89, south: -89, west: -180, east: 180},
        strictBounds: true
      }
    }, 2);

    //this.map.addListener('center_changed', this.onMapChange);
    this.map.addListener('drag', this.onDrag);
    this.map.addListener('zoom_changed', this.onMapChange);
    this.map.addListener('dragend', this.onMapChange);
    this.map?.addListener('click', () => {
      if (this.state.selectedCondo) {
        this.setState({ selectedCondo: null, selectedMarker: null, errorMessage: null });
      }
    });

    axios.post(import.meta.env.VITE_CONDO_MAP_TOKEN_URL, params, config)
      .then((result) => {
        const token = result?.data?.access_token;
        if (!isEmpty(token)) {
          const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': `Bearer ${token}`
            }
          }
      
          axios.get(import.meta.env.VITE_CONDO_MAP_GET_LIST_URL, config)
            .then((result) => {
              const condos = result?.data;
              this.setState({
                loading: false,
                condos,
                visibleCondos: condos,
                totalUnits: condos?.length || 0,
                totalWeeks: condos?.map((condo: any) => condo.UnitsAvailable).reduce((p: number, c: number) => p + c, 0) || 0,
                selectedCondo: null,
                selectedMarker: null,
                errorMessage: null
              }, () => {

                if (this.mapRef && this.mapRef.current) {
                  this.setMarkers();
                }
              });
            }).catch((err) => {
              this.setState({ loading: false, selectedCondo: null, selectedMarker: null, errorMessage: err.toString() });
            });
        }
      }).catch((err) => {
        this.setState({ loading: false, selectedCondo: null, selectedMarker: null, errorMessage: err.toString() });
      });
  }

  setMarkers = (): void => {
    const { visibleCondos, selectedCondo, isWeeks } = this.state;

    Map.removeAllMarkers();
    Map.addMarkersParallel(this.map, visibleCondos?.map(condo => ({
      location: {
        latitude: condo.Latitude,
        longitude: condo.Longitude      
      },
      label: {
        text: String(condo.UnitsAvailable),
        color: '#006579',
        fontSize: '12px',
      },
      id: condo.ID,
      availability: String(condo.UnitsAvailable)
    })), !isWeeks ? condoMarketIcon : condoMarketWeeksIcon, null, selectedCondo?.ID, this.selectCondo);
    Map.addCluster(this.map, !isWeeks ? new ClusterRenderer() : new ClusterWeeksRenderer());
  };

  selectCondo = (condosId: number, selectedMarker?: google.maps.Marker): void => {
    const { condos = [] } = this.state;
    const selectedCondo = condos?.find((condo: ICondoUnitMap) => condo.ID === condosId);

    this.setState({ selectedMarker });

    if (selectedCondo) {
      this.setState({ selectedCondo });

      const offSetFromBottom = 50;
      const divHeightOfTheMap = this.mapRef?.current?.clientHeight;
      const position: google.maps.LatLngLiteral = {
        lat: selectedMarker ? get(selectedMarker, 'getPosition().lat()', 0) : selectedCondo.Latitude,
        lng: selectedMarker ? get(selectedMarker, 'getPosition().lng()', 0) : selectedCondo.Longitude,
      };
      const currentZoom = this.map?.getZoom();

      this.map?.panTo(position);

      if ((currentZoom ? currentZoom : 0) < zoom) {
        this.map?.setZoom(zoom);
      }

      this.map?.panBy(0, - ((divHeightOfTheMap ? divHeightOfTheMap : 0) / 2 - offSetFromBottom));
    }
  };

  onDrag = () => {
    this.setState({ selectedCondo: null });
  }

  onMapChange = () => {
    const bounds = this.map?.getBounds();
    const sw = bounds?.getSouthWest();
    const ne = bounds?.getNorthEast();
    const mapBounds = {
      northEast: {
        longitude: ne?.lng(),
        latitude: ne?.lat(),
      },
      southWest: {
        longitude: sw?.lng(),
        latitude: sw?.lat(),
      },
    };
    
    const boundsSouthWestLatitude = mapBounds.southWest?.latitude ? mapBounds.southWest?.latitude : 0;
    const boundsSouthWestLongitude = mapBounds.southWest?.longitude ? mapBounds.southWest?.longitude : 0;
    
    const boundsNorthEastLatitude = mapBounds.northEast?.latitude ? mapBounds.northEast?.latitude : 0;
    const boundsNorthEastLongitude = mapBounds.northEast?.longitude ? mapBounds.northEast?.longitude : 0;

    const currentZoom = this.map?.getZoom();
    const visibleCondos = this.state.condos?.filter(({ Latitude, Longitude }) => {
      const isSouthWestLongitudePositive = Math.sign(boundsSouthWestLongitude) >= 0;
      const isNorthEastLongitudeNegative = Math.sign(boundsNorthEastLongitude) === -1;

      const isScrollPassed = (isNorthEastLongitudeNegative && isSouthWestLongitudePositive) || (!isSouthWestLongitudePositive && isNorthEastLongitudeNegative && boundsNorthEastLongitude < boundsSouthWestLongitude);

      if (isScrollPassed) {
        return Latitude >= boundsSouthWestLatitude && Latitude <= boundsNorthEastLatitude && 
               ((Longitude >= boundsSouthWestLongitude && Longitude <= 180) ||
                (Longitude >= -180 && Longitude <= boundsNorthEastLongitude))
      }

      return Latitude >= boundsSouthWestLatitude && Latitude <= boundsNorthEastLatitude && Longitude >= boundsSouthWestLongitude && Longitude <= boundsNorthEastLongitude
    });

    this.setState({ visibleCondos: (!visibleCondos?.length && (currentZoom ? currentZoom : 0) <= 2) ? this.state.condos : visibleCondos }, () => this.setMarkers());
  }

  render(): React.ReactNode {
    const { counterOnParent } = this.props;
    const { loading, selectedCondo, condos, errorMessage, totalUnits, totalWeeks } = this.state;

    const styleCounter: any = counterOnParent ? { position: 'absolute' } : {};

    const isMobile = document.body.offsetWidth <= DEFAULT_MEDIA_POINT;

    return (
      <div className="condo-world-map">
        {loading ? (
          <div className="loading-container">
            <Loading />
          </div>) : null}

        {!isEmpty(errorMessage) ? (
          <div className="condo-world-map__error">
            <img className="error-page__image" src={GeneralError} />
            <label className="error-page__title"><FormattedMessage id="error.custom.error.title" /></label>
            <label className="error-page__message"><FormattedMessage id="error.custom.error.retry" /></label>
            <label className="error-page__message-retry"><FormattedMessage id="error.custom.error.contact_support" /></label>
          </div>
        ) : (
          <>
            <div className="condo-world-map__map">
              <div className="condo-world-map__map-instance" ref={this.mapRef} />

              {condos?.length ? (
                <div className="condo-world-map__map-counters" style={styleCounter}>
                  <label><FormattedMessage id={isMobile ? 'resorts' : 'condos.world.map.total_units_available'} />: <strong>{totalUnits}</strong></label>
                  <label><FormattedMessage id={isMobile ? 'weeks' : 'condos.world.map.total_weeks_available'} />: <strong>{totalWeeks}</strong></label>
                </div>) : null}

              {condos?.length ? (
                <div className="condo-world-map__map-toogle" style={styleCounter}>
                  <div className="toogle-title">
                    <label><FormattedMessage id="resorts" /></label>
                    <label><FormattedMessage id="weeks" /></label>
                  </div>
                  <div className="toogle-switch">
                    <Switch onChange={(checked) => this.setState({ isWeeks: checked }, this.setMarkers)}></Switch>
                  </div>
                </div>) : null}
            </div>

            {selectedCondo && (
              <div className="condo-world-map__condo-detail">
                <Responsive>
                  <CondoCard condo={selectedCondo} />
                </Responsive>
              </div>
            )}
          </>)}
      </div>
    );
  }
}
