import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { Canceler } from 'axios';

import { AllInclusiveDestination, ILocation, LocationsTypes } from '@share/common-types';
import { Urls } from '@share/constants';
import { getHeaders, axiosInstance, AppThunk } from '@share/utils';
import { isEmpty } from 'lodash';
import { UNDEFINED_VALUE } from '@constants';

export interface ILocationsState {
  locations: ILocation[];
  loading: boolean;
  error: string;
  selectedLocationLabel: string;
  selectedLocation: ILocation;
  locationLabel: string;
  location: ILocation;
  allInclusiveDestinations: AllInclusiveDestination[];
  allInclusiveDestinationCountries: string[];
  allInclusiveDestinationCountryStates: any;
}

const initialState: ILocationsState = {
  locations: [],
  loading: false,
  error: '',
  selectedLocationLabel: UNDEFINED_VALUE,
  selectedLocation: UNDEFINED_VALUE,
  locationLabel: UNDEFINED_VALUE,
  location: UNDEFINED_VALUE,
  allInclusiveDestinations: [],
  allInclusiveDestinationCountries: [],
  allInclusiveDestinationCountryStates: null
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLoading: (state: ILocationsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: ILocationsState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setLocations: (state: ILocationsState, { payload }: PayloadAction<ILocation[]>) => {
      state.locations = payload;
    },
    setSelectLocation: (state: ILocationsState, { payload }: PayloadAction<ILocation>) => {
      state.selectedLocation = payload;
    },
    setSelectLocationLabel: (state: ILocationsState, { payload }: PayloadAction<string>) => {
      state.selectedLocationLabel = payload;
    },
    applyLocation: (state: ILocationsState) => {
      state.locationLabel = state.selectedLocationLabel;
      state.location = state.selectedLocation;
    },
    resetLocation: (state: ILocationsState) => {
      state.locations = [];
      state.loading = false;
      state.error = '';
      state.selectedLocationLabel = '';
      state.selectedLocation = UNDEFINED_VALUE;
      state.locationLabel = '';
      state.location = UNDEFINED_VALUE;
    },
    resetSelectedLocation: (state: ILocationsState) => {
      state.selectedLocationLabel = state.locationLabel;
      state.selectedLocation = state.location;
    },
    setAllInclusiveDestinations: (state: ILocationsState, { payload }: PayloadAction<AllInclusiveDestination[]>) => {
      state.allInclusiveDestinations = payload;
    },
    setAllInclusiveDestinationCountries: (state: ILocationsState, { payload }: PayloadAction<string[]>) => {
      state.allInclusiveDestinationCountries = payload;
    },
    setAllInclusiveDestinationCountryStates: (state: ILocationsState, { payload }: PayloadAction<any>) => {
      state.allInclusiveDestinationCountryStates = payload;
    }
  },
});

export const locationActions = locationsSlice.actions;

export const locationsReducer = locationsSlice.reducer;

let cancelRequest: Canceler;

export const getLocations = (search: string, selectedLocation: ILocation | undefined, onlyCities: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(locationActions.setLoading(true));

    try {
      if (cancelRequest) {
        cancelRequest();
      }

      const { hotelsStore } = getState();
      const { isWidget } = hotelsStore;
      const res = await axiosInstance.get(Urls.Locations, {
        ...getHeaders(isWidget),
        params: {
          SearchString: search.trim(),
        },
        cancelToken: new axios.CancelToken((canceler: Canceler) => {
          cancelRequest = canceler;
        }),
      });
      let locations: ILocation[] = [...res.data.locations];

      if (selectedLocation && search === selectedLocation.name) {
        locations = locations.filter(({ code }) => code !== selectedLocation.code);
        locations.unshift(selectedLocation);
      }

      if (onlyCities) {
        locations = locations.filter(({ type }) => type === LocationsTypes.Destination);
      }

      dispatch(locationActions.setLoading(false));
      dispatch(locationActions.setLocations(locations));
    } catch (error) {
      if (!(error instanceof axios.Cancel)) {
        console.error(error);
        dispatch(locationActions.setLoading(false));
      }
    }
  };
};

export const USA = 'United States of America';
export const MEXICO = 'Mexico';
export const CANADA = 'Canada';
export const VICINITY = '(Vicinity)';

export const getAllInclusiveDestinations = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(Urls.AllInclusiveDestinations, {
        ...getHeaders(),
      });

      const destinations = [...response.data.map((dest: any) => {
        return { ...dest, location: {...dest.location, code: dest.location.id, realType: 'City'}, country: !isEmpty(dest.country) ? dest.country : 'Rest of the world'} as AllInclusiveDestination;
      })];

      let destinationCountries = Array.from(new Set(destinations.map((dest: AllInclusiveDestination) => dest.country)).values());

      const destinationsFiltered = destinations.map(d => d.country.includes(VICINITY) ? { ...d, country: d.country.replace(VICINITY,'').trim(), city: `${d.city} ${VICINITY}` } : d);

      const destinationsUS = destinationsFiltered.filter((dest: AllInclusiveDestination) => dest.country === USA);

      destinationsUS.sort((a: AllInclusiveDestination, b: AllInclusiveDestination) => a.state?.localeCompare(b.state));

      destinationCountries = destinationCountries.filter(d => !d.includes(VICINITY) && d !== USA && d !== MEXICO);
      destinationCountries.sort();

      const destinationsStatesUSGrouped = Array.from(new Set(destinationsUS.map(d => d.state)).values());;

      destinationsStatesUSGrouped.sort();
      
      if (destinationsStatesUSGrouped.length)
        destinationCountries = [ USA, MEXICO, ...destinationCountries ];
      else 
        destinationCountries = [ MEXICO, ...destinationCountries ];
      
        const destinationsGrouped = [...destinationsFiltered];

      dispatch(locationActions.setAllInclusiveDestinations(destinationsGrouped));
      dispatch(locationActions.setAllInclusiveDestinationCountries(destinationCountries));
      if (destinationsStatesUSGrouped.length)
        dispatch(locationActions.setAllInclusiveDestinationCountryStates({[USA]: [...destinationsStatesUSGrouped],}));
    } catch (error) {
      console.error(error);
    }
  };
};
