
import moment from 'moment';
import { CombinedState } from '@reduxjs/toolkit';

import { DEFAULT_MAP_LIMIT_CONDO, DEFAULT_PAGE_CONDO_SIZE } from '@share/constants';
import { CondoRequestTypeEnum, ICondoRequestFilters, ICondoSearchRequest, IPlace, IRange, SearchTypeEnum } from '@share/common-types';

import { getCondoRequestType } from './condo-search';
import { getSelectedCurrency } from './currency';

import { ICondosState } from '../store/slices/condo-search';
import { ICondosLocationPickerState } from '../store/slices/condo-location-picker';
import { ICondoGuestsState } from '../store/slices/condo-guests';
import { ICondoStrictDatesState } from '../store/slices/condo-strcit-date-picker';
import { ICondoFlexibleDatePickerState } from '../store/slices/condo-flexible-date-picker';
import { ICondoFiltersState } from '../store/slices/condo-filters';
import { ILoginState } from '../store/slices/login';

const STAY_DURATION = 'week';

const isoFormat = 'yyyy-MM-DD';
const startTime = 'T00:00:01.000Z';
const skipPages = 0;
const UNDEFINED = 'undefined';

export const getFakeISODate = (dateString: string): string => {
    return moment(dateString, 'DD MMM yyyy').startOf('month').format(isoFormat) + startTime;
  };
  
export const getCondoSearchBodyDetails = (
    condoRequest: any,
  ): ICondoSearchRequest => {
    const {
      location,
      adultsCount,
      bedroomsCount,
      childrenCount,
      requestType,
      checkIn,
      checkOut,
      months,
      stayFor,
      includeStudio
    } = condoRequest;
  
    const res: ICondoSearchRequest = {
      condosRequest: {
        location: {
          geoLocation: location?.geoLocation,
          id: location?.id,
          locationType: location?.locationType,
          name: location?.name,
        },
        adultsCount: adultsCount? adultsCount : 2,
        bedroomsCount: includeStudio ? 0 : bedroomsCount ? bedroomsCount : 1,
        childrenCount: childrenCount ? childrenCount : 0,
      }
    } as ICondoSearchRequest;
  
    if (res.condosRequest) {
      if (requestType === CondoRequestTypeEnum.Strict) {
        res.condosRequest.checkIn = checkIn;
        res.condosRequest.checkOut = checkOut;
      } else if (requestType === CondoRequestTypeEnum.Flexible) {
        res.condosRequest.stayDuration = stayFor ? stayFor : STAY_DURATION;
        res.condosRequest.months = months ? months.map((dateString: string) => getFakeISODate(dateString)) : [];
      }
    }
  
    return res;
  };
  
  export const getCondoSearchBody = (
    searchType: SearchTypeEnum,
    getState: () => CombinedState<{
      condosStore: ICondosState;
      condosLocationPickerStore: ICondosLocationPickerState;
      condoGuestsStore: ICondoGuestsState;
      condoStrictDatesStore: ICondoStrictDatesState;
      condoFlexibleDatePickerStore: ICondoFlexibleDatePickerState;
      condoFiltersStore: ICondoFiltersState;
      loginStore: ILoginState;
    }>,
    isReuse = false,
    pageNumber?: number,
    pageSize?: number,
  ): ICondoSearchRequest => {
  
    const {
      condosStore,
      condosLocationPickerStore,
      condoGuestsStore,
      condoStrictDatesStore,
      condoFlexibleDatePickerStore,
      condoFiltersStore,
      loginStore
    } = getState();
    const { cachedSelectedLocation } = condosStore;
    const { startDate, endDate } = condoStrictDatesStore;
    const { selectedMonths } = condoFlexibleDatePickerStore;
    const { account } = loginStore;
  
    const requestType: CondoRequestTypeEnum = getCondoRequestType(condoStrictDatesStore);
    const currency = getSelectedCurrency(account);
  
    const res: ICondoSearchRequest = {
      filter: getCondoFilters(condoFiltersStore),
      pageNumber:
        pageNumber ?
          pageNumber :
          searchType === SearchTypeEnum.Pagination
            ? condosStore.pageNumber + skipPages
            : condosStore.pageNumber,
      pageSize: pageSize ? pageSize : searchType === SearchTypeEnum.Pagination ? DEFAULT_PAGE_CONDO_SIZE : DEFAULT_MAP_LIMIT_CONDO,
      sessionKey: searchType !== SearchTypeEnum.NewSearch || isReuse ? condosStore.sessionKey : null,
      sortBy: condoFiltersStore.sortType,
      residency: 'US',
      currency: currency
    };
  
    const location = condosLocationPickerStore.selectedLocation
    ? condosLocationPickerStore.selectedLocation
    : cachedSelectedLocation;
    res.condosRequest = {
      location: {
        geoLocation: location?.geoLocation,
        id: location?.id,
        locationType: location?.locationType,
        name: location?.name,
      },
      adultsCount: condoGuestsStore.adultsCount? condoGuestsStore.adultsCount : 2,
      bedroomsCount: condoGuestsStore.includeStudio ? 0 : condoGuestsStore.bedroomsCount ? condoGuestsStore.bedroomsCount : 1,
      childrenCount: condoGuestsStore.kidsCount ? condoGuestsStore.kidsCount : 0,
      residency: 'US',
    };
  
    if (res.condosRequest) {
      if (requestType === CondoRequestTypeEnum.Strict) {
        res.condosRequest.checkIn = startDate;
        res.condosRequest.checkOut = endDate;
      } else if (requestType === CondoRequestTypeEnum.Flexible) {
        res.condosRequest.stayDuration = STAY_DURATION;
        res.condosRequest.months = selectedMonths.map((dateString: string) => {
          return getFakeISODate(dateString);
        });
      }
    }
  
    return res;
};

export const getSeparatedPlace = (num: string): IPlace => {
    const [city, state, country, title]: string[] = num.split('|');
  
    return state === UNDEFINED ? { city, country, title } : { city, state, country, title };
};

export const getSeparatedFromTo = (num: string): IRange => {
    const [from, to]: string[] = num.split('-');
  
    return { from: +from || null, to: +to || null };
};
  
export const getCondoFilters = (condoFiltersStore: ICondoFiltersState): ICondoRequestFilters => {
    return {
      propertyName: '',
      budgetRanges: condoFiltersStore.budgetRanges.map((range: string) => {
        return getSeparatedFromTo(range);
      }),
      guestRatings: condoFiltersStore.guestRatings.map((num) => {
        return getSeparatedFromTo(num);
      }),
      distanceInMiles: condoFiltersStore.distanceInMiles,
      unitAmenities: condoFiltersStore.unitAmenities,
      facilities: condoFiltersStore.facilities,
      activities: condoFiltersStore.activities,
      condoTypes: condoFiltersStore.condoTypes,
      policies: [],
      accessibilities: condoFiltersStore.accessibilities,
      suppliers: condoFiltersStore.suppliers,
      neighbourhoods: condoFiltersStore.neighbourhoods,
      places: condoFiltersStore.places.map((place) => {
        return getSeparatedPlace(place);
      }),
      bathrooms: condoFiltersStore.bathrooms,
      days: condoFiltersStore.days,
      months: condoFiltersStore.months,
      cities: condoFiltersStore.cities,
      kitchenTypes: condoFiltersStore.kitchenTypes,
      isAllInclusiveOnly: condoFiltersStore.isAllInclusiveOnly,
    };
};
    