import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Input } from 'antd';

import { ILoginState, loginActions } from '@share/store/slices';
import { BlueButton } from '@share/components';
import { ACCESS_TOKEN_LABEL, ACCESS_TOKEN_MAX_LENGTH } from '@share/constants';
import { RootState } from '@share/utils';

import { UpdateSvg } from '@share/assets';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setAccessToken: (accessToken: string) => void;
}

interface IProps extends IMapDispatchToProps, IMapStateToProps, WrappedComponentProps, RouteComponentProps {}

interface IState {
  accessToken: string;
  isFocused: boolean;
  error: string;
}

class AccessTokenComponent extends React.Component<IProps, IState> {

  state = { accessToken: '', isFocused: false, error: '' };

  onAccessCode = () => {
    const { accessToken } = this.state;
    const { loginStore } = this.props;
    const { account } = loginStore;

    let error = null;
    const accessTokenAccount = account?.accessTokenDb;
    if (accessTokenAccount === accessToken) {
      this.props.setAccessToken(accessToken);
      localStorage.setItem(ACCESS_TOKEN_LABEL, accessToken);
    } else {
      error = 'Invalid Access Token';
    }
    this.setState({ error });
  }

  handleOnChange = (e: any) => {
    const accessToken = e.target.value?.toUpperCase();
    this.setState({ accessToken, error: null });
  }

  render(): React.ReactNode {   
    const { accessToken, error, isFocused } = this.state;

    const buttonDisabled = isEmpty(accessToken);
    return (
      <div className="access-token">
        <div className="access-token__message"><FormattedMessage id={'validate.access.title'} /></div>
        <div className="access-token__container">
          <div className={`access-token__label ${isFocused? 'selected' : ''} ${!isEmpty(error)? 'error' : ''}`}>
            {isEmpty(error)? <FormattedMessage id={'validate.access.code'} /> : error}
          </div>
          <Input
            className={`access-token__input ${!isEmpty(error)? 'error' : ''}`}
            placeholder={this.props.intl.formatMessage({ id: 'validate.access.enter_valid' })}
            allowClear={true}
            maxLength={ACCESS_TOKEN_MAX_LENGTH}
            onFocus={() => this.setState({ isFocused: !isFocused })}
            value={accessToken? accessToken.toUpperCase() : ''}
            onChange={this.handleOnChange}
          />
          <BlueButton className="access-token__button" onClick={this.onAccessCode} disabled={buttonDisabled}>
            <UpdateSvg />
            <FormattedMessage id="validate.access.code" />
          </BlueButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setAccessToken: loginActions.setAccessToken,
};


export const AccessToken = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(AccessTokenComponent)));
