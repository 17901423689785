
import { useEffect, useMemo } from 'react';

import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';

import { IntlProvider } from 'react-intl';
import { get, isEmpty } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider, withCookies, ReactCookieProps } from 'react-cookie';

import { Locales, getAntLocale, getLocale, getMessages } from '@i18n';
import { ExpiredSessionModal, ApplicationHeader, ApplicationFooter, ApplicationHelmet, ApplicationHeaderMessage } from '@components';
import { Loading } from '@share/components';
import { DecodeTokenData, getPreferenceFromAccount, isCondoWorldFromPath, isThreeDSFromPath, isCondoEngineFromPath, isCondoWeeksFromPath, isReservationCancellationFromPath, LoginType, getAccountUsernameFromPath, isSignOutFromPath, isAnyReservationCancelFromPath, getCancelIdCancellationFromPath, getTempKeyCancellationFromPath, FunctionalRootState } from '@share/utils';
import { COOKIE1_TOKEN_NAME, COOKIE_TOKEN_NAME, Routes, USER_BASE_URL } from '@share/constants';
import { isErrorFromPath, isSignInFromPath, isWidgetFromPath, RootState } from '@share/utils';
import { ILoginState, logout, IMenuState, setLoginRedirect, getLoginUserAccount } from '@share/store/slices';
import { LOGOUT_FIELD_KEY } from '@share/common-types';

import { ApplicationRoutes } from '../app-routes';
import { ProcessData } from './utils';

import './style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

export interface IAccountParams {
  accountUsername: string;
  id: string;
}

interface IProps extends ReactCookieProps, IMapStateToProps, RouteComponentProps<IAccountParams> {
  isWidget?: boolean;
}

function ApplicationComponent(props: IProps) {

  const dispatch = useDispatch();
  const history = useHistory();
  
  const loginStore: ILoginState = useSelector((state: FunctionalRootState) => state.loginStore);
  const menuStore: IMenuState = useSelector((state: FunctionalRootState) => state.navigationMenuStore);

  const { account, userLanguage, isTokenExpired } = loginStore;

  const { items } = menuStore;

  const isPublicAccount = account?.isPublicAccountType;
  const isPrivateWithLoginAccount = account?.isPrivateLoginAccountType;
  const isPrivateWithTokenAccount = account?.isPrivateTokenAccountType;
  const fontFamily = account?.fontFamily;
  const secondaryFontFamily = account?.secondaryFontFamily;
  const generalBorderRadius = account?.generalBorderRadius;
  
  const pagesToExcludeAccountCheck = useMemo(() => [
    isWidgetFromPath(history),
    isCondoWeeksFromPath(history),
    isCondoWorldFromPath(history),
    isErrorFromPath(history),
    isThreeDSFromPath(history),
  ], [history]);
  
  const isWidgetPage = useMemo(() => isWidgetFromPath(history), [history]);
  const isReservationCancellation = useMemo(() => isReservationCancellationFromPath(history), [history]);
  const isCondo = useMemo(() => isCondoEngineFromPath(history), [history]);
  const isSignIn = useMemo(() => isSignInFromPath(history), [history]);
  
  const locale = isCondo ? Locales.English : getLocale(userLanguage);

  useEffect(() => {
    ProcessData(dispatch);

    if (!pagesToExcludeAccountCheck.includes(true)) {
      const pathAccountName = getAccountUsernameFromPath(history);
      if (!isSignInFromPath(history) && !isSignOutFromPath(history)) {
        const { pathname, search } = history.location;
        setLoginRedirect(`${pathname}${isEmpty(search) ? '' : search}`);
      }
      
      dispatch(getLoginUserAccount(
        pathAccountName,
        isWidgetPage,
        isCondoEngineFromPath(history),
        isAnyReservationCancelFromPath(history),
        getCancelIdCancellationFromPath(history),
        getTempKeyCancellationFromPath(history)));
    }
  }, []);

  const handleExpireRedirect = () => {
    if (isPublicAccount) {
      window.location.reload();
    } else if (isPrivateWithLoginAccount) {
      dispatch(logout());
      props.history.push(`/${account.name}${Routes.Login}`);
    } else if (isPrivateWithTokenAccount) {
      const logoutUrlMenu = menuStore?.items?.logoutUrl;
      const logoutUrlStorage = localStorage.getItem(USER_BASE_URL);
      const logoutUrlToken = get(DecodeTokenData(), 'logoutUrl', null);
      const logoutPref = getPreferenceFromAccount(account, LOGOUT_FIELD_KEY);
      const logoutUrl = !isEmpty(logoutUrlStorage) ? logoutUrlStorage : !isEmpty(logoutUrlMenu) ? logoutUrlMenu : !isEmpty(logoutUrlToken) ? logoutUrlToken : logoutPref;

      Cookies.remove(COOKIE_TOKEN_NAME);
      Cookies.remove(COOKIE1_TOKEN_NAME);

      if (!isEmpty(logoutUrl)) {
        window.location.replace(logoutUrl);
      }
    }
  }

  const renderContent = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    
    return (
      <CookiesProvider>
        <ConfigProvider locale={getAntLocale(locale)}>
          <IntlProvider
            messages={getMessages(locale)}
            locale={locale}
            defaultLocale={locale}
          >

            <div className={`app ${generalBorderRadius ? `border-radius-${generalBorderRadius}` : ''} ${isReservationCancellation ? 'cancellation' : ''}${fontFamily ? `${fontFamily}-font`: ''} ${secondaryFontFamily ? `secondary-${secondaryFontFamily}-font`: ''}`}>
              <ApplicationHelmet />

              <ApplicationHeader />

              <ApplicationHeaderMessage />
              
              <ApplicationRoutes />

              <ApplicationFooter />

              {!isReservationCancellation ? (
                <ToastContainer
                  position="top-right"
                  autoClose={6000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                ></ToastContainer>) : null}

              {(!isSignIn && isTokenExpired) ? (
                <ExpiredSessionModal visible={true} hasRedirect={!isPublicAccount} onHandleRedirect={handleExpireRedirect} />) : null}

            </div>
          </IntlProvider>
        </ConfigProvider>
      </CookiesProvider>
    );
  }

  if ((account?.type === LoginType.PrivateWithLogin && loginStore?.loading) || 
      (account?.type !== LoginType.PrivateWithLogin && !items && !pagesToExcludeAccountCheck.includes(true))) {
    return (
      <div className="loading-container">
        <Loading />
      </div>);
  } else {
    return renderContent();
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore
  };
};

const ApplicationComponentWithRouter = withRouter(ApplicationComponent);
const ApplicationComponentCookies = withCookies(ApplicationComponentWithRouter);

export const App = connect(mapStateToProps)(ApplicationComponentCookies);
