
import { useMemo } from 'react';
import moment from 'moment';

import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart } from 'recharts';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Select } from 'antd';

import { RootState } from '@share/utils';
import { IDashboardReportState } from '@store/slices';
import { Loading } from '@share/components';

import { DashboardReportEmpty } from '../dashboard-report-empty';
import { DAILY, MONTHLY } from '../component';

import './style.scss';

interface IMapStateToProps {
  dashboardReportsStore: IDashboardReportState;
}

interface IProps extends IMapStateToProps {
  period: string;

  onPeriodChange: (type: string) => void;
}

function DashboardReportBookingsTotalBarComponent(props: IProps) {
  const { period, dashboardReportsStore, onPeriodChange } = props;
  const { loading } = dashboardReportsStore;

  const bookingsTotalDays = dashboardReportsStore.dashBoardData?.bookingsTotalDays;
  const bookingsTotalMonths = dashboardReportsStore.dashBoardData?.bookingsTotalMonths;

  const isMonthlyPeriod = useMemo(() => period === MONTHLY, [period]);
  const roomsBooked = useMemo(() => period === MONTHLY ? bookingsTotalMonths : bookingsTotalDays, [period, bookingsTotalMonths, bookingsTotalDays]);

  const data = useMemo(() => roomsBooked?.map(r => ({ type: (isMonthlyPeriod ? moment(r?.date, 'yyyy-MM-DD').format('MMM') : moment(r?.date, 'yyyy-MM-DD').format('MM/DD')), savings: r?.savings, amount: r?.grossAmount })), [roomsBooked, isMonthlyPeriod]);

  const isMobile = document.body.offsetWidth <= 798;

  return (
    <div className="dasshboard-reports-bookings-total-wrapper">
      <div className="dasshboard-reports-bookings-total-wrapper__title">
        <h1><FormattedMessage id="bookings.total" /></h1>
        <Select value={period} onChange={v => onPeriodChange(v)}>
          <Select.Option value={MONTHLY}><FormattedMessage id="monthly" /></Select.Option>
          <Select.Option value={DAILY}><FormattedMessage id="daily" /></Select.Option>
        </Select>
      </div>
      <div className="dasshboard-reports-bookings-total-wrapper__content centered">
        {loading ?
          <Loading /> : 
          data?.length ?
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="type" />
                <YAxis width={isMobile ? 20 : 60} />
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey="amount" stroke="#1d2e67" fill="#1d2e67" strokeWidth={3} dot />
                <Area type="monotone" dataKey="savings" stroke="#00d062" fill="#00d062" strokeWidth={3} dot />
              </AreaChart>
            </ResponsiveContainer> :
            <DashboardReportEmpty />}

      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    dashboardReportsStore: state.dashboardReportsStore
  };
};

export const DashboardReportBookingsTotalBar = connect(mapStateToProps)(DashboardReportBookingsTotalBarComponent);
